import useDealDetailsQuery from "hooks/queries/useDealDetailsQuery";
import useDealInvestFlowQuery from "hooks/queries/useDealInvestFlowQuery";
import _ from "lodash";
import * as React from "react";
import { DivProps } from "react-html-props";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { GoKebabVertical } from "react-icons/go";
import { useNavigate, useParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";

interface IPropertyDetailsCardProps extends DivProps {}

const PropertyDetailsCard: React.FunctionComponent<
  IPropertyDetailsCardProps
> = (props) => {
  const { propertyId } = useParams();
  const navigate = useNavigate();
  const dealDetailsQuery = useDealDetailsQuery(propertyId!);
  const dealInvestFlowQuery = useDealInvestFlowQuery(propertyId!);

  return (
    <div
      className={twMerge(
        "p-8 bg-white rounded-md flex flex-col max-w-md gap-5 flex-shrink-0",
        props.className
      )}
    >
      <h1 className="font-medium whitespace-nowrap">Selected Property</h1>
      <div className="flex gap-4 border-b pb-4 border-black/10 border-dashed">
        <img
          src={dealDetailsQuery.data?.marketing.coverImage}
          className="w-1/3 rounded-md shadow"
          alt=""
        />
        <div className="flex flex-grow justify-between">
          <div className="flex flex-col gap-0.5">
            <p className="font-medium">{dealDetailsQuery.data?.name}</p>
            <p className="text-sm text-black/60">
              {_.startCase(dealDetailsQuery.data?.type)}
            </p>
            <div className="text-sm text-black/60">
              {dealDetailsQuery.data?.token.name}(
              {dealDetailsQuery.data?.token.symbol})
            </div>
          </div>
          <div>
            <GoKebabVertical className="mt-2 w-5 h-auto" />
          </div>
        </div>
      </div>
      <div className="flex justify-between text-sm border-b pb-4 border-black/10 border-dashed">
        <h3 className="font-semibold">No of tokens Selected</h3>
        <div className="flex flex-col text-right gap-1">
          <div className="font-semibold flex items-center gap-1 text-right justify-end">
            <div>{dealInvestFlowQuery.data?.noOfTokens}</div>
            <div>
              {dealDetailsQuery.data?.token.name}(
              {dealDetailsQuery.data?.token.symbol})
            </div>
          </div>
          <label className="text-xs text-black/60">
            Price/Token = ${dealDetailsQuery.data?.token.pricePerToken}
          </label>
        </div>
      </div>
      <div className="flex justify-between text-sm border-b pb-4 border-black/10 border-dashed">
        <h3 className="font-semibold">Subtotal</h3>
        <div className="flex flex-col text-right gap-1">
          <p className="font-semibold">
            $ {dealInvestFlowQuery.data?.currentValue}
          </p>
        </div>
      </div>
      <div className="flex rounded-md border border-black/10 p-4 gap-4">
        <div className="pt-1.5">
          <AiOutlineInfoCircle className="w-4 h-auto" />
        </div>
        <p>Subtotal does not include applicable taxes.</p>
      </div>
    </div>
  );
};

export default PropertyDetailsCard;

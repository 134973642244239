import { VoidSigner } from "ethers";
import path from "node:path/win32";
import * as React from "react";
import { DivProps } from "react-html-props";
import { BsArrowLeftShort, BsChevronRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";

interface IBreadcrumbsBarProps extends DivProps {
  isBackButtonEnabled?: boolean;
  rightArea?: React.ReactNode;
  path: string[];
  onBack?: () => void;
}

const BreadcrumbsBar: React.FunctionComponent<IBreadcrumbsBarProps> = ({
  isBackButtonEnabled = true,
  className,
  rightArea,
  onBack,
  path,
  ...props
}) => {
  const navigate = useNavigate();
  return (
    <div
      {...props}
      className={twMerge(
        "fixed inset-x-0 h-14 mt-14 top-0 bg-white shadow-sm flex justify-between px-4 items-center text-black/80 text-sm whitespace-nowrap overflow-x-auto",
        className
      )}
    >
      <div className="flex items-center gap-6">
        {isBackButtonEnabled && (
          <button
            className="flex gap-1 items-center"
            onClick={() => (onBack ? onBack() : navigate(-1))}
          >
            <BsArrowLeftShort className="w-5 h-auto" /> Back
          </button>
        )}
        <div
          className={twMerge(
            " flex gap-2 items-center",
            isBackButtonEnabled && "pl-6 border-l border-black/10"
          )}
        >
          {path.map((name, i) => (
            <>
              <div
                className={twMerge(
                  i === path.length - 1 && "text-foreground-primary"
                )}
              >
                {name}
              </div>
              {i !== path.length - 1 && (
                <BsChevronRight className="text-black/50" />
              )}
            </>
          ))}
        </div>
      </div>
      {rightArea}
    </div>
  );
};

export default BreadcrumbsBar;

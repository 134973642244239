import useCurrentTenantQuery from "hooks/queries/useCurrentTenantQuery";
import { BsInstagram, BsTwitter } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import { Link } from "react-router-dom";

const FooterLinks = () => {
  const currentTenantQuery = useCurrentTenantQuery();
  return (
    <div className="py-7 md:py-14 justify-items-center items-center md:justify-items-start md:px-32 text-white grid gap-y-5 sm:grid-cols-12 bg-foreground-primary ">
      <div className="grid justify-items-center md:justify-items-start row-auto gap-y-5 col-span-6 md:col-span-4">
        <div className="w-max flex items-center">
          <Link to="/home">
            <img
              src={currentTenantQuery.data?.logo.dark}
              className="max-w-[160px] h-auto"
              alt="logo"
            />
          </Link>
        </div>
        <h6 className="w-1/2 md:w-3/4">
          World’s fastest, easiest real estate NFT & tokenization solution.
        </h6>
        <div className="flex space-x-5">
          <button className="rounded-full border-2 border-white w-max h-max p-1 hover:opacity-80">
            <FaFacebookF />
          </button>
          <button className="rounded-full border-2 border-white w-max h-max p-1 hover:opacity-80">
            <BsInstagram />
          </button>
          <button className="rounded-full border-2 border-white w-max h-max p-1 hover:opacity-80">
            <BsTwitter />
          </button>
        </div>
      </div>
      <div className="col-span-6 md:col-span-8">
        <ul className="mt-2 space-y-5">
          <li className="font-bold">
            <Link to="/home">Company</Link>
          </li>
          <li className="hover:opacity-80">
            <Link to="/home">About</Link>
          </li>
          <li className="hover:opacity-80">
            <Link to="/marketplace">Marketplace</Link>
          </li>
          <li className="hover:opacity-80">
            <Link to="/home">Career</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default FooterLinks;

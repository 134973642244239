import PrimaryButton from "components/Buttons/PrimaryButton";
import Web3Action from "components/Web3Action";
import { MATIC_TESTNET } from "config/Chains";
import { useWallet } from "context/Wallet";
import useDealDetailsQuery from "hooks/queries/useDealDetailsQuery";
import useDealInvestFlowQuery from "hooks/queries/useDealInvestFlowQuery";
import { useTokenAllowanceQuery } from "hooks/queries/web3/useTokenAllowanceQuery";
import { useTokenDecimalsQuery } from "hooks/queries/web3/useTokenDecimalsQuery";
import React, { useEffect } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { Oval } from "react-loader-spinner";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import Step, { StepStatus } from "../components/Step";
import { IStepProps } from "./step.type";
import { ethers } from "ethers";
import erc20abi from "assets/abi/erc20.json";
import { useConnectWallet, useSetChain } from "@web3-onboard/react";
import { connect } from "http2";
import { TbChevronsDownLeft } from "react-icons/tb";
import axios from "axios";

const TOKEN_ADDRESS = "0x0fa8781a83e46826621b3bc094ea2a0212e71b23";

const DOMAIN = [
  { name: "name", type: "string" },
  { name: "version", type: "string" },
  { name: "verifyingContract", type: "address" },
  { name: "salt", type: "bytes32" },
];

const ApproveContractStep: React.FunctionComponent<IStepProps> = ({
  activeStepNumber,
  stepNumber,
  onNextStep,
}) => {
  const { ethersSigner, ethersProvider, address } = useWallet();
  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();

  const { propertyId } = useParams();

  const [{ connectedChain }] = useSetChain();

  const tokenContractInterface = new ethers.utils.Interface(erc20abi);

  const tokenDecimalsQuery = useTokenDecimalsQuery(
    TOKEN_ADDRESS,
    MATIC_TESTNET
  );

  const dealInvestFlowQuery = useDealInvestFlowQuery(propertyId!);
  const dealDetailsQuery = useDealDetailsQuery(propertyId!);

  const tokenAllowanceQuery = useTokenAllowanceQuery(
    TOKEN_ADDRESS,
    address,
    dealInvestFlowQuery.data?.contractAddress,
    MATIC_TESTNET
  );

  useEffect(() => {
    if (!tokenAllowanceQuery.data || !dealInvestFlowQuery.data) return;
    if (
      tokenAllowanceQuery.data.formatted >=
      dealInvestFlowQuery.data.currentValue
    )
      onNextStep();
  });

  const transactionConfirmationMutation = useMutation(
    async (transactionId: string) => {
      await (await ethersProvider!.getTransaction(transactionId)).wait();
    },
    {
      onSuccess: () => {
        tokenAllowanceQuery.refetch();
      },
    }
  );

  const setAllowanceMutation = useMutation<string, Error>(
    async () => {
      if (
        !dealInvestFlowQuery.data ||
        !tokenAllowanceQuery.data ||
        !tokenDecimalsQuery.data
      ) {
        throw new Error("Dependencies not met. Please try again.");
      }
      const tokenContract = new ethers.Contract(
        TOKEN_ADDRESS,
        erc20abi,
        ethersSigner
      );

      const nonceMethod = tokenContract.getNonce; // tokenContract.nonces;
      console.log("trying to get nonce");
      const nonce = parseInt(await nonceMethod(address));
      console.log({ nonce });

      const approvalValue = ethers.utils.parseUnits(
        dealInvestFlowQuery.data.currentValue.toString(),
        tokenDecimalsQuery.data
      );

      const functionSignature = tokenContractInterface.encodeFunctionData(
        "approve",
        [dealInvestFlowQuery.data?.contractAddress, approvalValue.toString()]
      );

      const name = await tokenContract.name();
      console.log({ name });
      const message = {
        name,
        nonce,
        functionSignature,
        from: address,
      };

      console.log({ chainId: connectedChain?.id });
      const dataToSign = JSON.stringify({
        types: {
          EIP712Domain: DOMAIN,
          MetaTransaction: [
            { name: "nonce", type: "uint256" },
            { name: "from", type: "address" },
            { name: "functionSignature", type: "bytes" },
          ],
        },
        domain: {
          name,
          version: "1",
          verifyingContract: TOKEN_ADDRESS,
          salt: "0x" + connectedChain?.id.split("0x")[1].padStart(64, "0"),
        },
        primaryType: "MetaTransaction",
        message,
      });

      console.log(dataToSign);

      // @ts-ignore
      const signResult = await wallet?.provider.send("eth_signTypedData_v3", [
        address,
        dataToSign,
      ]);
      const signedData = signResult.result;

      // console.log(signedData);
      const { v, r, s } = ethers.utils.splitSignature(signedData);
      // console.log({ v, r, s });
      // const contractWithSigner = this.contract.connect(this.library.getSigner());

      const response = await axios.post(
        "https://api.biconomy.io/api/v2/meta-tx/native",
        {
          to: TOKEN_ADDRESS,
          apiId: "88741fd8-5058-4aac-a3c2-c598cd2a13e8",
          from: address,
          params: [address, functionSignature, r, s, v],
        },
        {
          headers: {
            "x-api-key": "o9UUZlPng.68465793-d34e-4707-87df-136952cd4099",
          },
        }
      );

      console.log(response.data);
      return response.data.txHash as string;
      // const response = await tokenContract.executeMetaTransaction(
      //   address,
      //   functionSignature,
      //   r,
      //   s,
      //   v
      // );
    },
    {
      onSuccess: (transactionId) => {
        transactionConfirmationMutation.mutateAsync(transactionId);
      },
    }
  );

  return (
    <Step
      title="Approve"
      stepNumber={stepNumber}
      activeStepNumber={activeStepNumber}
    >
      {tokenAllowanceQuery.isLoading && (
        <div className="flex items-center p-5">
          <div className="flex items-center justify-center gap-2">
            <Oval
              ariaLabel="loading-indicator"
              height={20}
              width={20}
              strokeWidth={10}
              strokeWidthSecondary={0}
              color="#00000050"
              secondaryColor="#00000080"
            />
            Loading approval data...
          </div>
        </div>
      )}
      {tokenAllowanceQuery.data && dealInvestFlowQuery.data && (
        <div className="flex flex-col py-4">
          <div className="grid grid-cols-2 gap-2 max-w-md">
            {/* <div className="font-medium">Available Approval</div>
            <div className="text-right font-mono">
              {tokenAllowanceQuery.data.formatted}
            </div> */}
            <div>Required USDC Approval</div>

            <div className="text-right font-mono">
              {dealInvestFlowQuery.data?.currentValue}
            </div>
          </div>
          <div className="flex rounded-md border border-black/10 p-6 gap-4 mt-4">
            <div className="pt-0.5">
              <AiOutlineInfoCircle className="w-4 h-auto" />
            </div>
            <div className="flex-col flex gap-4">
              {/* <p className="font-medium">
                You do not have sufficient approval for this transaction.
              </p> */}
              <p>
                To proceed with this transaction, you need to allow our contract
                to spend your tokens.
              </p>
              {setAllowanceMutation.error && (
                <div className="rounded-md p-4 bg-red-100/50 border border-red-200 text-black/80 flex flex-col gap-2">
                  <h1 className="text-red-400 font-medium text-xs">Error</h1>
                  <p>{setAllowanceMutation.error.message}</p>
                  <p>Please try again.</p>
                </div>
              )}
              <div className="flex gap-4">
                <Web3Action chainId={MATIC_TESTNET.id}>
                  {setAllowanceMutation.data ? (
                    <a
                      href={`https://mumbai.polygonscan.com/tx/${setAllowanceMutation.data}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="p-4 border border-black/10 rounded-md">
                        {transactionConfirmationMutation.isLoading ? (
                          <div className="flex items-center justify-center gap-2">
                            <Oval
                              ariaLabel="loading-indicator"
                              height={20}
                              width={20}
                              strokeWidth={10}
                              strokeWidthSecondary={0}
                              color="#00000050"
                              secondaryColor="#00000080"
                            />
                            Waiting for transaction to complete
                          </div>
                        ) : (
                          <div>Transaction Sent</div>
                        )}
                      </div>
                    </a>
                  ) : (
                    <PrimaryButton
                      onClick={() => {
                        setAllowanceMutation.mutateAsync();
                      }}
                      disabled={setAllowanceMutation.isLoading}
                    >
                      {setAllowanceMutation.isLoading ? (
                        <div className="flex items-center justify-center gap-2">
                          <Oval
                            ariaLabel="loading-indicator"
                            height={20}
                            width={20}
                            strokeWidth={10}
                            strokeWidthSecondary={0}
                            color="#ffffff"
                            secondaryColor="#ffffff80"
                          />
                          Accept Approval Request In Wallet
                        </div>
                      ) : (
                        "Approve"
                      )}
                    </PrimaryButton>
                  )}
                </Web3Action>
              </div>
            </div>
          </div>
        </div>
      )}
    </Step>
  );
};

export default ApproveContractStep;

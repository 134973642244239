import { Outlet } from "react-router-dom"
import { SideNav } from "./SideNav"
import 'react-toastify/dist/ReactToastify.css';

interface ILayoutProps {}
const AccountLayout: React.FunctionComponent<ILayoutProps> = () => {
  return (
    <div className="flex w-full max-w-screen-xl mx-auto">
      <div className="hidden lg:block px-4 py-2 bg-foreground-primary sm:bg-inherit">
        <SideNav/>
      </div>
      <div className="flex flex-grow">
        <Outlet />
      </div>
    </div>
  )

    
}

export default AccountLayout



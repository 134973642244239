import TextInput from "components/Input/TextInput";
import React, { useState } from "react";
import {
  Control,
  UseFormRegister,
  UseFormRegisterReturn,
  UseFormSetValue,
} from "react-hook-form";
import { InputPropsWithoutRef } from "react-html-props";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillLinkedin,
  AiFillTwitterSquare,
} from "react-icons/ai";
import { FaPencilAlt, FaTiktok } from "react-icons/fa";
import { twMerge } from "tailwind-merge";
import { ProfileForm } from "..";

interface ISocialForm {
  register: UseFormRegister<ProfileForm>;
  errors: any;
  control: Control<ProfileForm>;
  setValue: UseFormSetValue<ProfileForm>;
}

export const SocialForm: React.FunctionComponent<ISocialForm> = ({
  register,
  errors,
  setValue,
  control,
}) => {
  const [isEditable, setIsEditable] = useState<boolean>(true);
  return (
    <div className="block bg-white rounded-md px-12 py-8">
      <div className="font-bold text-black/80 text-2xl w-full relative py-2 mb-4">
        <span>Social Accounts</span>
        {!isEditable && (
          <FaPencilAlt
            className="absolute right-8 top-2 hover:opacity-70 cursor-pointer"
            onClick={() => {
              setIsEditable(true);
            }}
          />
        )}
      </div>
      <div className="grid grid-cols-12 w-full">
        <div className="col-span-12 md:col-span-6 m-2">
          <FormTextInputWithIcon
            label="LinkedIn"
            labelClassname="font-semibold text-black/80 text-lg"
            disabled={!isEditable}
            register={() => register("social.linkedin")}
            error={errors.social?.linkedin && errors.social?.linkedin.message}
            placeholder="/james.moriarty"
            className=" disabled:bg-opacity-80 disabled:text-red"
            icon={
              <AiFillLinkedin className="absolute scale-150 top-1/3 left-4" />
            }
          />
        </div>
        <div className="col-span-12 md:col-span-6 m-2">
          <FormTextInputWithIcon
            label="Twitter"
            labelClassname="font-semibold text-black/80 text-lg"
            disabled={!isEditable}
            register={() => register("social.twitter")}
            error={errors.social?.twitter && errors.social?.twitter.message}
            placeholder="/james.moriarty"
            className=" disabled:bg-opacity-80 disabled:text-red"
            icon={
              <AiFillTwitterSquare className="absolute scale-150 top-1/3 left-4" />
            }
          />
        </div>
        <div className="col-span-12 md:col-span-6 m-2">
          <FormTextInputWithIcon
            labelClassname="font-semibold text-black/80 text-lg"
            label="Instagram"
            disabled={!isEditable}
            register={() => register("social.instagram")}
            error={errors.social?.instagram && errors.social?.instagram.message}
            placeholder="/james.moriarty"
            className=" disabled:bg-opacity-80 disabled:text-red"
            icon={
              <AiFillInstagram className="absolute scale-150 top-1/3 left-4" />
            }
          />
        </div>
        <div className="col-span-12 md:col-span-6 m-2">
          <FormTextInputWithIcon
            labelClassname="font-semibold text-black/80 text-lg"
            label="Facebook"
            disabled={!isEditable}
            register={() => register("social.facebook")}
            error={errors.social?.facebook && errors.social?.facebook?.message}
            placeholder="/james.moriarty"
            className=" disabled:bg-opacity-80 disabled:text-red"
            icon={
              <AiFillFacebook className="absolute scale-150 top-1/3 left-4" />
            }
          />
        </div>
        <div className="col-span-12 md:col-span-6 m-2">
          <FormTextInputWithIcon
            label="Tiktok"
            labelClassname="font-semibold text-black/80 text-lg"
            disabled={!isEditable}
            register={() => register("social.tiktok")}
            error={errors.social?.tiktok && errors.social?.tiktok?.message}
            placeholder="/james.moriarty"
            className=" disabled:bg-opacity-80 disabled:text-red"
            icon={<FaTiktok className="absolute scale-150 top-1/3 left-4" />}
          />
        </div>
      </div>
    </div>
  );
};

interface IFormTextInputProps extends InputPropsWithoutRef {
  register?: () => UseFormRegisterReturn;
  label?: string;
  type?: "text" | "password";
  error?: string;
  icon?: any;
  labelClassname?: string;
}

const FormTextInputWithIcon: React.FunctionComponent<IFormTextInputProps> = ({
  icon,
  register,
  label,
  error,
  type,
  labelClassname,
  ...props
}) => {
  return (
    <div className="flex flex-col gap-1" id="social">
      <label
        className={twMerge("block text-black/80 font-semibold", labelClassname)}
      >
        {label}
      </label>
      {(type === "text" || type === undefined) && (
        <div className="relative">
          {icon}
          <TextInput
            {...props}
            {...(register && register())}
            className={error ? "pl-10 py-3  border-red-300" : "pl-10 py-3"}
          />
        </div>
      )}
      {error && (
        <span className="text-red-400 font-medium text-sm">{error}</span>
      )}
    </div>
  );
};

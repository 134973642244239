import HeroBackground from "assets/images/old-1-hero-cover.jpg";
import { WhatIsNew } from "./WhatIsNew";
import { Holdings } from "./Holdings";
import { Intro } from "./Intro";
import { CompleteProfile } from "./CompleteProfile";
import useUserQuery from "hooks/queries/useUserQuery";
import { useDashboardOverviewQuery } from "hooks/queries/useDashboardOverviewQuery";

const HeroSection: React.FunctionComponent = () => {
  const userQuery = useUserQuery();
  const { data } = useDashboardOverviewQuery() || [];
  const user = userQuery.data;

  const isUserDetailsDone =
    user?.firstname !== "" && user?.lastname !== "" ? true : false;

  let currentValue = 0;
  if (data) {
    data.forEach((d: any) => {
      currentValue = currentValue + d.currentValue;
    });
  }

  return (
    <div
      style={{
        backgroundImage: `url(${HeroBackground})`,
        backgroundPosition: `top`,
        backgroundSize: `cover`,
        boxShadow: "inset 0 0 0 100vw rgba(255,255,255,0.6)",
      }}
      className="py-4 px-5 md:py-5 md:px-10 lg:py-20 lg:px-40 grid row-auto gap-y-10"
    >
      <Intro firstName={user?.firstname ?? ""} portfolioValue={currentValue} />
      <CompleteProfile
        isKycDone={user?.kycStatus === "PENDING" ? false : true}
        isAccreditationDone={
          user?.accreditationStatus === "APPROVED" ? true : false
        }
        isUserDetailsDone={isUserDetailsDone}
      />
      <Holdings portfolioValue={currentValue} />
      <WhatIsNew />
    </div>
  );
};

export default HeroSection;

import FooterLinks from "components/Footer/FooterLinks";
import HeroSection from "./components/Sections/Hero/HeroSection";
import HotDealsSection from "./components/Sections/HotDealsSection";
import NewsSection from "./components/Sections/News/NewsSection";
import SpecialDealSection from "./components/Sections/SpecialDealSection";

const Home: React.FunctionComponent = () => {
  return (
    <>
      <HeroSection />
      <SpecialDealSection />
      <HotDealsSection />
      <NewsSection />
      <FooterLinks />
    </>
  );
};

export default Home;

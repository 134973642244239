import { Timeline } from "react-twitter-widgets";

const NewsCards: React.FunctionComponent = () => {
  return (
    <>
      <Timeline
        dataSource={{
          sourceType: "profile",
          screenName: "RealtoApps",
        }}
        options={{
          tweetLimit: 3,
        }}
      />
    </>
  );
};

export default NewsCards;

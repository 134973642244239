import PrimaryButton from "components/Buttons/PrimaryButton";
import { useWallet } from "context/Wallet";
import useDealDetailsQuery from "hooks/queries/useDealDetailsQuery";
import useDealInvestFlowQuery from "hooks/queries/useDealInvestFlowQuery";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import Step, { StepStatus } from "../components/Step";
import { IStepProps } from "./step.type";
import { ethers } from "ethers";
import erc20abi from "assets/abi/erc20.json";
import { useQuery } from "react-query";
import { useTokenBalanceQuery } from "hooks/queries/web3/useTokenBalanceQuery";
import { MATIC_TESTNET } from "config/Chains";
import { Oval } from "react-loader-spinner";
import { divide } from "lodash";
import { AiOutlineInfoCircle } from "react-icons/ai";
import UniswapOverlay from "../components/UniswapOverlay";
import { RampInstantSDK } from "@ramp-network/ramp-instant-sdk";
import { useTokenDecimalsQuery } from "hooks/queries/web3/useTokenDecimalsQuery";
import axios from "axios";

import Web3Action from "components/Web3Action";
import { toast } from "react-toastify";

const TOKEN_ADDRESS = "0x0fa8781a83e46826621b3bc094ea2a0212e71b23";

const AddFundsStep: React.FunctionComponent<IStepProps> = ({
  activeStepNumber,
  stepNumber,
  onNextStep,
}) => {
  const { ethersSigner: ethersProvider, address } = useWallet();
  const { propertyId } = useParams();

  const tokenBalanceQuery = useTokenBalanceQuery(
    TOKEN_ADDRESS,
    address,
    MATIC_TESTNET
  );

  const tokenDecimalsQuery = useTokenDecimalsQuery(
    TOKEN_ADDRESS,
    MATIC_TESTNET
  );

  const dealInvestFlowQuery = useDealInvestFlowQuery(propertyId!);
  const dealDetailsQuery = useDealDetailsQuery(propertyId!);

  const [isUniswapOverlayOpen, setIsUniswapOverlayOpen] = useState(false);

  useEffect(() => {
    if (
      !tokenBalanceQuery.data ||
      !dealInvestFlowQuery.data ||
      activeStepNumber !== stepNumber
    )
      return;

    if (
      tokenBalanceQuery.data.formatted >= dealInvestFlowQuery.data.currentValue
    )
      onNextStep();
  }, [
    tokenBalanceQuery.data,
    dealInvestFlowQuery.data,
    onNextStep,
    activeStepNumber,
    stepNumber,
  ]);

  return (
    <Step
      title="Add Funds"
      stepNumber={stepNumber}
      activeStepNumber={activeStepNumber}
    >
      {(tokenBalanceQuery.isLoading || dealInvestFlowQuery.isLoading) && (
        <div className="flex items-center p-5">
          <div className="flex items-center justify-center gap-2">
            <Oval
              ariaLabel="loading-indicator"
              height={20}
              width={20}
              strokeWidth={10}
              strokeWidthSecondary={0}
              color="#00000050"
              secondaryColor="#00000080"
            />
            Loading balance...
          </div>
        </div>
      )}
      {tokenBalanceQuery.data && dealInvestFlowQuery.data && (
        <div className="flex flex-col py-4">
          <div className="grid grid-cols-2 gap-2 max-w-sm">
            <div className="font-medium">Available Balance</div>
            <div className="text-right font-mono">
              {tokenBalanceQuery.data.formatted}
            </div>
            <div>Required Balance</div>

            <div className="text-right font-mono">
              {dealInvestFlowQuery.data?.currentValue}
            </div>
            <div>Additional Balance Required</div>

            <div className="text-right font-mono">
              {dealInvestFlowQuery.data?.currentValue -
                tokenBalanceQuery.data.formatted}
            </div>
          </div>
          <div className="flex rounded-md border border-black/10 p-6 gap-4 mt-4">
            <div className="pt-0.5">
              <AiOutlineInfoCircle className="w-4 h-auto" />
            </div>
            <div className="flex-col flex gap-4">
              <p className="font-medium">
                You do not have sufficient balance for this transaction.
              </p>
              <p>
                You can use the methods below to add funds to your wallet in
                order to proceed with your transaction. Alternatively, you can
                proceed with Fiat payment.
              </p>
              <div className="flex flex-wrap gap-x-4 gap-y-2">
                <Web3Action chainId={MATIC_TESTNET.id}>
                  <PrimaryButton onClick={() => setIsUniswapOverlayOpen(true)}>
                    Swap to USDC with Uniswap
                  </PrimaryButton>
                  <PrimaryButton
                    onClick={() => {
                      new RampInstantSDK({
                        hostAppName: "Realto",
                        hostLogoUrl:
                          "https://rampnetwork.github.io/assets/misc/test-logo.png",
                        swapAmount:
                          dealInvestFlowQuery.data?.currentValue &&
                          tokenBalanceQuery.data !== undefined
                            ? ethers.utils
                                .parseUnits(
                                  (
                                    dealInvestFlowQuery.data.currentValue -
                                    tokenBalanceQuery.data.formatted
                                  ).toString(),
                                  tokenDecimalsQuery.data
                                )
                                .toString()
                            : undefined,
                        userAddress: address,
                        swapAsset: "MATIC_USDC",
                      }).show();
                    }}
                  >
                    Buy USDC with Ramp
                  </PrimaryButton>
                  <PrimaryButton
                    onClick={async () => {
                      let response;
                      try {
                        response = await axios.post(
                          "https://api.testwyre.com/v3/orders/reserve",
                          {
                            referrerAccountId: "AC_4LPCQBYBD64",
                            destAmount: dealInvestFlowQuery.data?.currentValue,
                            amountIncludeFees: false,
                            country: "US",
                            paymentMethod: "debit-card",
                            sourceCurrency: "USD",
                            destCurrency: "mUSDC",
                            dest: `matic:${address}`,
                          },
                          {
                            headers: {
                              Authorization:
                                "Bearer TEST-SK-WF63H4XP-UNF7GQUU-ARVZFHC4-BHHQHFB7",
                            },
                          }
                        );
                      } catch (e) {
                        toast.error(
                          `Error using Wyre:\n ${
                            e?.response?.data?.message || ""
                          }`
                        );
                        throw e;
                      }

                      console.log(response);

                      //@ts-ignore
                      var widget = new Wyre({
                        env: "test",
                        reservation: response.data.reservation,
                        /*A reservation id is mandatory. In order for the widget to open properly you must use a new, unexpired reservation id. Reservation ids are only valid for 15 minutes. A new reservation must also be made if a transaction fails.*/
                        operation: {
                          type: "debitcard-hosted-dialog",
                        },
                      });

                      widget.on("paymentSuccess", function (e: any) {
                        console.log("paymentSuccess", e);
                        tokenBalanceQuery.refetch();
                      });

                      widget.open();
                    }}
                  >
                    Buy USDC with Wyre
                  </PrimaryButton>
                </Web3Action>
              </div>
              {isUniswapOverlayOpen && (
                <UniswapOverlay
                  defaultOutputAmount={
                    dealInvestFlowQuery.data?.currentValue &&
                    tokenBalanceQuery.data
                      ? dealInvestFlowQuery.data?.currentValue -
                        tokenBalanceQuery.data.formatted
                      : undefined
                  }
                  outputAddress={TOKEN_ADDRESS}
                  onClose={() => setIsUniswapOverlayOpen(false)}
                  jsonRpcEndpoint={MATIC_TESTNET.rpcUrl}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </Step>
  );
};

export default AddFundsStep;

import React, { useMemo, useRef } from "react";
import PropertyGallery from "./components/PropertyGallery";
import PropertyOverview from "./components/PropertyOverview";
import PropertyHeader from "./components/PropertyHeader";

import useDealDetailsQuery from "hooks/queries/useDealDetailsQuery";
import { Navigate, useParams } from "react-router-dom";
import PrimaryButton from "components/Buttons/PrimaryButton";
import PropertyDetailsNav from "./components/PropertyDetailsNav.tsx";
import PropertyCta from "./components/PropertyCta.tsx";
import PropertyFaq from "./components/PropertyFAQ";
import PropertyDocuments from "./components/PropertyDocuments";
import { useInView } from "react-intersection-observer";
import _ from "lodash";
import useSelfAccQuery from "hooks/queries/useSelfAccQuery";

interface IPropertyProps {}

const PropertyDetails: React.FunctionComponent<IPropertyProps> = (props) => {
  const { propertyId } = useParams();
  const dealDetailsQuery = useDealDetailsQuery(propertyId!);

  const { value, minimumInvestment, pricePerToken, holdPeriodDays } =
    dealDetailsQuery.data?.token || {
      value: 0,
      minimumInvestment: 0,
      pricePerToken: 0,
      holdPeriodDays: 0,
    };
  //@ts-ignore
  const { coverImage, images } = dealDetailsQuery.data?.marketing || {
    coverImage: "",
    images: [],
  };

  const overviewInViewHookResponse = useInView();
  const overviewRef = useRef<HTMLDivElement>(null);

  const keyHighlightsInViewHookResponse = useInView();
  const keyHighlightsRef = useRef<HTMLDivElement>(null);

  const documentsInViewHookResponse = useInView();
  const documentsRef = useRef<HTMLDivElement>(null);

  const faqInViewHookResponse = useInView();
  const faqRef = useRef<HTMLDivElement>(null);

  return (
    <div className="flex gap-4 py-4 sm:px-4 max-w-screen-2xl mx-auto mt-16 mb-28 xl:mb-0 lg:mt-0">
      <PropertyDetailsNav
        sections={{
          overview: {
            inViewHookResponse: overviewInViewHookResponse,
            ref: overviewRef,
          },
          keyHighlights: {
            inViewHookResponse: keyHighlightsInViewHookResponse,
            ref: keyHighlightsRef,
          },
          documents: {
            inViewHookResponse: documentsInViewHookResponse,
            ref: documentsRef,
          },
          faq: {
            inViewHookResponse: faqInViewHookResponse,
            ref: faqRef,
          },
        }}
      />

      <div className="flex-grow flex flex-col bg-white p-8 rounded-md gap-8">
        <PropertyGallery coverImage={coverImage} images={images} />
        <PropertyHeader
          title={dealDetailsQuery.data?.name!}
          address={_.startCase(dealDetailsQuery.data?.type)}
        />
        <div className="flex gap-8">
          <div className="flex-grow flex flex-col gap-12">
            <div ref={overviewInViewHookResponse.ref}>
              <PropertyOverview
                ref={overviewRef}
                data={[
                  { value: value, label: "Value of Deal", type: "currency" },
                  {
                    value: pricePerToken,
                    label: "Price per Token",
                    type: "currency",
                  },
                  {
                    value: minimumInvestment,
                    label: "Minimum Investment",
                    type: "currency",
                  },
                  {
                    value: holdPeriodDays,
                    label: "Minimum Hold Period",
                    type: "days",
                  },
                ]}
              />
            </div>
            <div ref={keyHighlightsInViewHookResponse.ref}>
              <article className="prose" ref={keyHighlightsRef}>
                <h4>Key Highlights</h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html: dealDetailsQuery.data?.description || "",
                  }}
                />
              </article>
            </div>

            <div ref={documentsInViewHookResponse.ref}>
              <PropertyDocuments
                documents={dealDetailsQuery.data?.documents}
                ref={documentsRef}
              />
            </div>
            <div ref={faqInViewHookResponse.ref}>
              <PropertyFaq faqs={dealDetailsQuery.data?.faqs} ref={faqRef} />
            </div>
          </div>
          <PropertyCta
            status={dealDetailsQuery.data?.status}
            token={dealDetailsQuery.data?.token}
            roi={dealDetailsQuery.data?.roi}
          />
        </div>
      </div>
    </div>
  );
};

export default PropertyDetails;

import api from "api/axios";
import { useMutation, useQueryClient } from "react-query";

type InvestSetTokenQuantityResponse = {};

interface IInvestSetTokenQuantity {
  step: number;
  propertyId: string;
  quantity: number;
  to: string;
}

const useInvestSetTokenQuantitiyMutation = (dealId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: IInvestSetTokenQuantity) => {
      const forgetPWResponse = await api.post("/invest", payload);
      return forgetPWResponse.data as InvestSetTokenQuantityResponse;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["dealInvestFlow", dealId]);
      },
    }
  );
};

export default useInvestSetTokenQuantitiyMutation;

import React, { useMemo } from "react";
import { DivProps, LIProps } from "react-html-props";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { FaListUl } from "react-icons/fa";
import { HiOutlineDocumentSearch, HiOutlineDocumentText } from "react-icons/hi";
import { MdLabelImportantOutline } from "react-icons/md";
import { twMerge } from "tailwind-merge";
import {
  AvailableSectionNames,
  ConcreteNavProps,
  IPropertyDetailsNavProps,
} from ".";

const PropertySideNav: React.FunctionComponent<
  IPropertyDetailsNavProps & ConcreteNavProps
> = ({ className, availableSections, sections, activeSection }) => {
  return (
    <div className={twMerge("min-w-[240px] relative flex-shrink-0", className)}>
      <nav className="sticky top-2 bg-white rounded-md p-2 pb-8">
        <ul className="flex flex-col text-black/75">
          {availableSections.overview && (
            <PropertySideNavListItem
              active={activeSection === "overview"}
              icon={<FaListUl className="w-4 mx-0.5" />}
              label={<p>Deal Overview</p>}
              onClick={() =>
                sections.overview.ref.current?.scrollIntoView({
                  behavior: "smooth",
                })
              }
            />
          )}

          {availableSections.keyHighlights && (
            <PropertySideNavListItem
              active={activeSection === "keyHighlights"}
              icon={<MdLabelImportantOutline />}
              label={<p>Key Highlights</p>}
              onClick={() =>
                sections.keyHighlights.ref.current?.scrollIntoView({
                  behavior: "smooth",
                })
              }
            />
          )}

          {availableSections.documents && (
            <PropertySideNavListItem
              active={activeSection === "documents"}
              icon={<HiOutlineDocumentText />}
              label={<p>Deal Documents</p>}
              onClick={() =>
                sections.documents.ref.current?.scrollIntoView({
                  behavior: "smooth",
                })
              }
            />
          )}

          {availableSections.faq && (
            <PropertySideNavListItem
              active={activeSection === "faq"}
              icon={<AiOutlineQuestionCircle />}
              label={<p>FAQs</p>}
              onClick={() =>
                sections.faq.ref.current?.scrollIntoView({
                  behavior: "smooth",
                })
              }
            />
          )}
        </ul>
      </nav>
    </div>
  );
};

interface IPropertySideNavListItemProps extends LIProps {
  label: React.ReactNode;
  icon: React.ReactElement;
  active?: boolean;
}

const PropertySideNavListItem: React.FC<IPropertySideNavListItemProps> = ({
  label,
  active,
  icon,
  ...props
}) => {
  return (
    <li
      className={twMerge(
        "flex px-3 py-2 rounded-md items-center gap-3 cursor-pointer",
        active && "bg-foreground-accent/10 text-foreground-accent font-semibold"
      )}
      {...props}
    >
      {React.cloneElement(icon, {
        className: twMerge(
          "w-5 h-auto text-black/50",
          active && "text-foreground-accent",
          icon.props.className
        ),
      })}
      {label}
    </li>
  );
};

export default PropertySideNav;

import { useReadNotification } from "hooks/queries/useNotificationsQuery";
import moment from "moment";
import { useState } from "react";
import { MdClear } from "react-icons/md";
import { TailSpin } from "react-loader-spinner";
interface INotificationProps {
  id: string;
  subject: string;
  message: string;
  createdAt: string;
}
export const NavNotification = ({
  id,
  subject,
  message,
  createdAt,
}: INotificationProps) => {
  const [isCleared, setIsCleared] = useState<boolean>(false);
  const { data, isLoading } = useReadNotification(id, isCleared);

  return (
    <div className="mx-2 my-3 pb-3 border-b border-slate-200 flex cursor-pointer">
      {/* text view */}
      <div className="w-3/4">
        <p className="text-base font-medium">{subject}</p>
        <p className="text-base text-black/80">{message}</p>

        <p className="text-sm font-medium text-gray-500 mt-1">
          {moment(createdAt).format("DD MMM YYYY, hh:mm a")}
        </p>
      </div>

      <div className="w-1/4 flex justify-end items-start">
        <span
          className="rounded-full cursor-pointer"
          title="clear"
          onClick={() => setIsCleared(true)}
        >
          <MdClear className="hover:text-red-500 scale-[1.5]" />
        </span>
      </div>

      {/* image - view */}
    </div>
  );
};

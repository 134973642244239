import React, { useEffect, useRef } from "react";
import { DivProps } from "react-html-props";
import { InViewHookResponse } from "react-intersection-observer";
import { twMerge } from "tailwind-merge";
import { ConcreteNavProps, IPropertyDetailsNavProps } from ".";

const PropertyTopNav: React.FunctionComponent<
  IPropertyDetailsNavProps & ConcreteNavProps
> = ({ sections, className, availableSections, activeSection }) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={ref}
      className={twMerge(
        "fixed top-0 inset-x-0 bg-white h-12 mt-14 z-10 shadow-sm px-8 flex gap-6 items-center overflow-scroll text-sm text-black/50",
        className
      )}
    >
      {availableSections.overview && (
        <PropertyTopNavItem
          active={activeSection === "overview"}
          parentRef={ref}
        >
          Property Overview
        </PropertyTopNavItem>
      )}
      {availableSections.keyHighlights && (
        <PropertyTopNavItem
          active={activeSection === "keyHighlights"}
          parentRef={ref}
        >
          Key Highlights
        </PropertyTopNavItem>
      )}
      {availableSections.documents && (
        <PropertyTopNavItem
          active={activeSection === "documents"}
          parentRef={ref}
        >
          Deal Documents
        </PropertyTopNavItem>
      )}
      {availableSections.faq && (
        <PropertyTopNavItem active={activeSection === "faq"} parentRef={ref}>
          FAQs
        </PropertyTopNavItem>
      )}
    </div>
  );
};

interface IPropertyTopNavItemProps {
  active?: boolean;
  children: React.ReactNode;
  parentRef: React.RefObject<HTMLDivElement>;
}

const PropertyTopNavItem: React.FunctionComponent<IPropertyTopNavItemProps> = ({
  children,
  active,
  parentRef,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (active) {
      console.log("activate");
      console.log(ref.current?.offsetLeft);
      ref.current?.scrollIntoView({ inline: "start" });
    }
  }, [active, parentRef]);

  return (
    <div
      ref={ref}
      className={twMerge(
        "px-3 py-2 rounded-full border border-black/10 whitespace-nowrap",
        active && "bg-foreground-secondary text-white font-medium"
      )}
    >
      {children}
    </div>
  );
};

export default PropertyTopNav;

import PrimaryButton from "components/Buttons/PrimaryButton";
import * as React from "react";
import { DivProps } from "react-html-props";
import { BsFillShareFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { RWebShare } from "react-web-share";

interface IPropertyCardProps extends DivProps {
  propertyName: string;
  propertyId: string;
  propertyLocation: string;
  tokensSold: number;
  tokensTotalSupply: number;
  targettedIrr: number;
  avgCashYield: number;
  ctaText?: string;
  imgUrl?: string;
  status: string;
}

const PropertyCard: React.FunctionComponent<IPropertyCardProps> = ({
  propertyName,
  propertyId,
  propertyLocation,
  tokensSold,
  tokensTotalSupply,
  targettedIrr,
  avgCashYield,
  className,
  status,
  imgUrl = `${process.env.PUBLIC_URL}/images/property.jpg`,
  ctaText,
}) => {
  return (
    <div
      className={twMerge(
        "max-w-80 md:w-80 flex flex-col rounded-md overflow-hidden shadow-md",
        className
      )}
    >
      <div className="h-36 relative overflow-hidden">
        <div className="absolute px-2 py-1 bg-black/70 text-xs rounded-md text-white top-0 right-0 m-3 shadow-sm">
          {status === "upcoming" ? "Upcoming" : "Trending"}
        </div>
        <img src={imgUrl} className="inset-0" alt="" />
      </div>
      <div className="flex flex-col p-4 flex-grow gap-4">
        <div className="flex justify-between border-b pb-4 border-black/10 border-dashed items-start">
          <div className="flex flex-col gap-0.5">
            <p className="font-medium">{propertyName}</p>
            <p className="text-sm text-black/60">{propertyLocation}</p>
          </div>
          <RWebShare
            data={{
              // text: "Like humans, flamingos make friends for life",
              url: `${window.location}/${propertyId}`,
              title: `${propertyName}`,
            }}
          >
            <button>
              <BsFillShareFill className="mt-2 w-3 h-auto" />
            </button>
          </RWebShare>
        </div>
        <div className="flex flex-col rounded-md bg-background-highlight/10 flex-grow gap-3 p-4 shadow-inner shadow-background-highlight/20">
          <Link to={`/marketplace/${propertyId}`}>
            <PrimaryButton className="w-full">
              {ctaText ?? (status === "upcoming" ? "View Details" : "Invest")}
            </PrimaryButton>
          </Link>
          <div className="h-2 rounded-full bg-background-highlight/30 relative">
            <div
              className="absolute inset-y-0 left-0 bg-green-700/75 rounded-full"
              style={{ width: `${(tokensSold * 100) / tokensTotalSupply}%` }}
            ></div>
          </div>
          <p className="text-black/80 text-sm flex-grow">
            Tokens Sold: <span className="font-bold">{tokensSold}</span>/
            {tokensTotalSupply} ({`${(tokensSold * 100) / tokensTotalSupply}`}
            %)
          </p>
          <div className="grid grid-cols-2 divide-x divide-black/10 border-t border-black/10 pt-3">
            <div className="flex flex-col text-center items-center">
              <p className="font-bold">{targettedIrr}%</p>
              <label className="text-xs">Targeted IRR</label>
            </div>
            <div className="flex flex-col text-center items-center">
              <p className="font-bold">{avgCashYield}%</p>
              <label className="text-xs">Avg Cash Yield</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyCard;

import { useQuery } from "react-query";
import { ethers } from "ethers";
import { Chain } from "@web3-onboard/common";
import erc20abi from "assets/abi/erc20.json";

export function useTokenDecimalsQuery(tokenAddress?: string, chain?: Chain) {
  return useQuery(
    [chain?.id, tokenAddress, "decimals"],
    async () => {
      if (!tokenAddress || !chain?.id) return undefined;

      const readOnlyProvider = new ethers.providers.JsonRpcProvider(
        chain?.rpcUrl
      );

      const currencyContract = new ethers.Contract(
        tokenAddress,
        erc20abi,
        readOnlyProvider
      );

      console.log("waiting");
      const decimals = await currencyContract.decimals();
      console.log(decimals);
      return decimals as number;
    },
    { enabled: !!chain?.id && !!tokenAddress }
  );
}

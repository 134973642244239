import * as React from "react";
import { DivPropsWithoutRef } from "react-html-props";
import { twMerge } from "tailwind-merge";

interface IErrorTextProps extends DivPropsWithoutRef {
  children: React.ReactNode;
}

const ErrorText: React.FunctionComponent<IErrorTextProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <span
      {...props}
      className={twMerge("text-red-400 font-medium text-sm", className)}
    >
      {children}
    </span>
  );
};

export default ErrorText;

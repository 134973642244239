import * as React from "react";
import { Route, Routes } from "react-router-dom";
import Properties from "./pages/Properties";
import Property from "./pages/Property";

interface IMarketplaceProps {}

const Marketplace: React.FunctionComponent<IMarketplaceProps> = (props) => {
  return (
    <Routes>
      <Route index element={<Properties />} />
      <Route path=":propertyId/*" element={<Property />}></Route>
    </Routes>
  );
};

export default Marketplace;

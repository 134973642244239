import React, { useMemo } from "react"
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid"
import Table from "components/Table"
import _ from "lodash"
import moment from "moment"
import { useAllNotification } from "hooks/queries/useNotificationsQuery"

const columns: GridColDef[] = [
  {
    field: "subject",
    headerName: "Subject",
    // width: 280,
    flex:1,
    minWidth:250,
    align: "left",
    headerClassName: "sample",
  },
  {
    field: "message",
    headerName: "Message",
    // width: 380,
    minWidth:250,
    flex:1,
    align: "left",
    headerClassName: "sample",
  },
  {
    field: "createdAt",
    headerName: "Date",
    minWidth:180,
    flex:1,
    align: "left",
    renderCell(params) {
      return (
        <div>{moment(params.row.createdAt).format("DD MMM YYYY, hh:mm a")}</div>
      )
    },
  },
]

const NotificaionsTable: React.FunctionComponent = () => {
  const AllNotifications = useAllNotification();

  const rows = useMemo(() => {
    if (!AllNotifications.data) return undefined

    return AllNotifications.data.map((row) => {
      return {
        id:row._id,
        subject: row.subject,
        message: row.message,
        createdAt: row.createdAt,
      }
    })
  }, [AllNotifications.data])

  return (
    <div>
      <h2 className="font-bold mb-6 ml-6 mt-6 text-xl">
        Readed All Notifications
      </h2>
      <div className="m-6" style={{ height: "400px" }}>
        <Table
          rows={rows || []}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
        />
      </div>
    </div>
  )
}
export default NotificaionsTable

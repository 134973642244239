import GlobalLoader from "components/GlobalLoader";
import useUserQuery from "hooks/queries/useUserQuery";
import { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { PrivateRoutes, PublicRoutes } from "routes";

import { IntlProvider } from "react-intl";
import { initLocalizedMsgs } from "./utils/localeHelper";

import { GoogleOAuthProvider } from "@react-oauth/google";
import { GOOGLE_CLIENT_ID } from "utils/constants";

function App() {
  const userQuery = useUserQuery();
  const usrLocale = "en";
  const messages = initLocalizedMsgs(usrLocale);

  return (
    <GlobalLoader>
      <BrowserRouter>
        <IntlProvider locale={usrLocale} messages={messages} defaultLocale="en">
          <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
            {userQuery.data ? <PrivateRoutes /> : <PublicRoutes />}
          </GoogleOAuthProvider>
        </IntlProvider>
      </BrowserRouter>
    </GlobalLoader>
  );
}

export default App;

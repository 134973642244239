import api from "api/axios";
import { useMutation, useQueryClient } from "react-query";

type InvestAddTransactionHashResponse = {};

interface IInvestAddTransactionHash {
  step: number;
  propertyId: string;
  transactionHash: string;
  quantity: number;
}

const useInvestAddTransactionHashMutation = (dealId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: IInvestAddTransactionHash) => {
      const response = await api.post("/invest", {
        step: payload.step,
        propertyId: payload.propertyId,
        txHash: payload.transactionHash,
        quantity: payload.quantity,
      });
      return response.data as InvestAddTransactionHashResponse;
    },
    {
      onSuccess: () => {
        // queryClient.invalidateQueries(["dealInvestFlow", dealId]);
      },
    }
  );
};

export default useInvestAddTransactionHashMutation;

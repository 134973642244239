import api from "api/axios";
import { useMutation, useQueryClient } from "react-query";

type InvestSetDisclosuresTermsAgreementResponse = {};

interface IInvestSetDisclosuresTermsAgreement {
  step: number;
  propertyId: string;
  disclosureId: string;
  accepted: boolean;
  quantity: number;
}

const useInvestSetDisclosuresTermsAgreementMutation = (dealId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: IInvestSetDisclosuresTermsAgreement) => {
      const response = await api.post("/invest", {
        step: payload.step,
        propertyId: payload.propertyId,
        disclosuresAndTerms: {
          id: payload.disclosureId,
          accepted: payload.accepted,
        },
        quantity: payload.quantity,
      });
      return response.data as InvestSetDisclosuresTermsAgreementResponse;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["dealInvestFlow", dealId]);
      },
    }
  );
};

export default useInvestSetDisclosuresTermsAgreementMutation;

import * as React from "react";
import { DivProps } from "react-html-props";
import { Link, useLocation } from "react-router-dom";
interface INavbarLink extends DivProps {
  to: string;
  disableActive?: boolean;
}

const NavbarLink: React.FunctionComponent<INavbarLink> = (props) => {
  const location = useLocation();
  const isActive = location.pathname === props.to;

  return (
    <Link to={props.to}>
      <div
        className={`py-2 px-2 text-white text-sm ${
          isActive && !props.disableActive ? "font-bold" : "font-normal"
        }`}
      >
        {props.children}
      </div>
    </Link>
  );
};

export default NavbarLink;

import Lightbox from "components/Lightbox";
import React, { useRef, useState } from "react";

import { AiOutlineRight, AiOutlineLeft } from "react-icons/ai";

interface IPropertyGalleryProps {
  coverImage: string;
  images: [string];
}

const PropertyGallery: React.FunctionComponent<IPropertyGalleryProps> = ({
  coverImage,
  images,
}) => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const [isLightBoxOpen, setIsLightBoxOpen] = useState(false);

  return (
    <>
      <div className="relative">
        <div className="absolute inset-y-0 left-0 grid place-items-center m-4">
          <div className="bg-white rounded-full relative p-5">
            <button
              className="absolute inset-0 w-full grid place-items-center"
              onClick={() => {
                if (sliderRef?.current?.scrollLeft === undefined) return;
                sliderRef.current.scrollLeft -= 600;
              }}
            >
              <AiOutlineLeft />
            </button>
          </div>
        </div>
        <div className="absolute inset-y-0 right-0 grid place-items-center m-4">
          <div className="bg-white rounded-full relative p-5">
            <button
              className="absolute inset-0 w-full grid place-items-center"
              onClick={() => {
                if (sliderRef?.current?.scrollLeft === undefined) return;
                sliderRef.current.scrollLeft += 600;
              }}
            >
              <AiOutlineRight />
            </button>
          </div>
        </div>
        <div
          className="overflow-x-auto flex h-60 gap-2 scrollbar-hide z-10 pb-3 scroll-smooth"
          ref={sliderRef}
        >
          <div
            className="w-full sm:w-1/2 flex-shrink-0 shadow-md rounded-md cursor-pointer"
            onClick={() => setIsLightBoxOpen(true)}
            style={{
              background: `url(${coverImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
          {images.map((imgUrl) => (
            <img
              onClick={() => setIsLightBoxOpen(true)}
              src={`${imgUrl}`}
              className="h-full w-auto rounded-md shadow-md cursor-pointer"
              alt=""
            />
          ))}
        </div>
      </div>
      <Lightbox
        images={[coverImage, ...images]}
        isOpen={isLightBoxOpen}
        onClose={() => setIsLightBoxOpen(false)}      
      />
    </>
  );
};

export default PropertyGallery;

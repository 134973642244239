import Tooltip from "components/Tooltip";
import React from "react";
import { DivProps } from "react-html-props";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { twMerge } from "tailwind-merge";
import { v4 as uuidv4 } from "uuid";

interface IChipProps extends DivProps {
  variant: keyof typeof variants;
  text: string;
  tooltip?: string;
}

export type AvailableVariants = "completed" | "pending";

type Variants = {
  [key in AvailableVariants]: {
    color: string;
    bg: string;
  };
};

const variants: Variants = {
  completed: {
    color: "#24663B",
    bg: "#C3E6CD",
  },
  pending: {
    color: "#8F4700",
    bg: "#F5D9A8",
  },
};

const Chip: React.FunctionComponent<IChipProps> = ({
  text,
  variant,
  className,
  tooltip,
  ...props
}) => {
  const id = uuidv4();

  return (
    <>
      {tooltip && <Tooltip id={id}>{tooltip}</Tooltip>}
      <div
        {...props}
        className={twMerge(
          "rounded-full px-3 py-2 text-sm flex items-center gap-2",
          tooltip && "pr-4",
          className
        )}
        style={{
          backgroundColor: variants[variant].bg,
          color: variants[variant].color,
        }}
        data-tip
        data-for={id}
      >
        {tooltip && <AiOutlineInfoCircle />}
        {text}
      </div>
    </>
  );
};

export default Chip;

import * as React from "react";
import { Outlet } from "react-router-dom";

interface IInvestLayoutProps {}

const InvestLayout: React.FunctionComponent<IInvestLayoutProps> = (props) => {
  return (
    <div className="max-w-screen-xl mx-auto">
      <Outlet />
    </div>
  );
};

export default InvestLayout;

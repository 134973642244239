import { FcGoogle } from "react-icons/fc";
import logo from "assets/images/logos/logo.jpg";
import { NavLink } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import useGoogleAuthMutation from "hooks/mutations/auth/useGoogleAuthMutaion";

import RealtoWordmark from "assets/images/logos/realto-wordmark.svg";

export const VideoBg = () => {
  const videoUrl =
    "https://martelinvest.s3.amazonaws.com/2022/5/8/1654685904622/MartelInvestCover_Smaller.mp4";
  const posterUrl =
    "https://realtopublic.s3.amazonaws.com/2021/10/6/1636227345145/bg.png";

  return (
    <video
      id="bgvid"
      preload="metadata"
      playsInline={true}
      className="h-screen"
      autoPlay={true}
      muted={true}
      loop={true}
      width="100%"
      height="100%"
      poster={posterUrl}
      style={{ objectFit: "cover" }}
    >
      <source src={videoUrl} type="video/mp4" />
    </video>
  );
};

export const HorizontalLineWithText = ({ text }: { text: String }) => {
  return (
    <div className="flex justify-center items-center">
      <div className="flex-grow h-0.5 bg-black"></div>
      <div className="px-4">{text}</div>
      <div className="flex-grow h-0.5 bg-black"></div>
    </div>
  );
};

export const GoogleSignIn = () => {
  const GAuthMutaion = useGoogleAuthMutation();
  const loginGoogle = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      GAuthMutaion.mutate({ token: tokenResponse?.code });
    },
    flow: "auth-code",
  });

  return (
    <div>
      <button
        className="block w-full border-2 rounded-lg p-2 my-2 hover:bg-gray-200"
        onClick={loginGoogle}
      >
        <span className="text-sm sm:text-base">
          <FcGoogle className="inline mx-4 scale-150" />
          Sign in With Google
        </span>
      </button>
    </div>
  );
};

export const Header = ({
  title = "Login",
  subTitle = "Welcome Back! Please enter your details.",
}) => {
  return (
    <>
      <div className="flex flex-col mt-2 mb-4 gap-2">
        <h2 className="text-3xl text-black">{title}</h2>
        <span className="text-slate-700 my-1">{subTitle}</span>
      </div>
    </>
  );
};

export const Footer = () => {
  return (
    <div className="mt-auto flex justify-center py-2">
      <a
        className="inline-flex justify-center p-2 items-center gap-2 hover:bg-black/5 rounded-lg transition-color"
        href="https://realtoapps.com/"
        target="_blank"
        rel="noreferrer"
      >
        <span className="text-sm">Technology Powered By</span>
        <img src={RealtoWordmark} alt="logo" className="h-6 w-auto" />
      </a>
    </div>
  );
};

import api from "api/axios";
import { useMutation, useQueryClient } from "react-query";

type InvestSignDocumentResponse = {};

interface IInvestSignDocument {
  documentId: string;
  firstName: string;
  lastName: string;
  investmentId: string;
}

const useInvestSignDocumentMutation = (dealId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: IInvestSignDocument) => {
      const forgetPWResponse = await api.post(
        `/investment/document/sign?_id=${payload.investmentId}&docId=${payload.documentId}`,
        { firstname: payload.firstName, lastname: payload.lastName }
      );
      return forgetPWResponse.data as InvestSignDocumentResponse;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["dealInvestFlow", dealId]);
      },
    }
  );
};

export default useInvestSignDocumentMutation;

export function compactObject(data: any): any {
  if (typeof data !== "object") {
    return data;
  }

  return Object.keys(data).reduce(function (accumulator, key) {
    const isObject = typeof data[key] === "object";
    const value = isObject ? compactObject(data[key]) : data[key];
    const isEmptyObject = isObject && !Object.keys(value).length;
    if (value === undefined || isEmptyObject || value === "") {
      return accumulator;
    }

    return Object.assign(accumulator, { [key]: value });
  }, {});
}

import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import PrimaryButton from "components/Buttons/PrimaryButton";
import Chip, { AvailableVariants } from "components/Chip";
import Table from "components/Table";
import useDealDetailsQuery from "hooks/queries/useDealDetailsQuery";
import useDealInvestFlowQuery, {
  Document,
} from "hooks/queries/useDealInvestFlowQuery";
import React, { useMemo } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import DocumentSigning from "components/DocumentSigning";
import { useDocumentSigning } from "components/DocumentSigning/DocumentSigningProvider";
import PropertyDetailsBottomBar from "../../components/PropertyDetailsBottomBar";
import PropertyDetailsCard from "../../components/PropertyDetailsCard";
import PropertyInvestBreadcrumbsBar from "../../components/PropertyInvestBreadcrumbsBar";
import Stepper from "../../components/Stepper";

interface IDocumentsProps {}

const SignButton: React.FunctionComponent<
  GridRenderCellParams<any, Document>
> = (props) => {
  const { openDocumentSigner } = useDocumentSigning();
  const { propertyId } = useParams();

  return (
    <PrimaryButton
      className="bg-white text-background-highlight border-background-highlight border"
      onClick={() => {
        console.log(props.row);
        openDocumentSigner(props.row);
      }}
    >
      {props.row.signRequired && props.row.status === "pending"
        ? "Sign"
        : "View"}
    </PrimaryButton>
  );
};

const columns: GridColDef<Document>[] = [
  {
    field: "title",
    minWidth: 100,
    headerName: "Document Title",
    flex: 1,
    align: "left",
  },
  {
    field: "status",
    minWidth: 100,
    headerName: "Status",
    align: "left",
    flex: 1,
    renderCell(params) {
      return (
        <Chip
          text={params.row.status}
          variant={
            {
              pending: "pending",
              signed: "completed",
              view: "pending",
              viewed: "completed",
            }[params.row.status] as AvailableVariants
          }
          className="capitalize"
        />
      );
    },
  },
  {
    field: "action",
    minWidth: 200,
    headerName: "Action",
    renderCell: SignButton,
  },
];

const Documents: React.FunctionComponent<IDocumentsProps> = (props) => {
  const { propertyId } = useParams();

  const dealInvestFlowQuery = useDealInvestFlowQuery(propertyId!);
  const dealDetailsQuery = useDealDetailsQuery(propertyId!);
  const navigate = useNavigate();

  const areDocumentsSigned = useMemo(() => {
    if (!dealInvestFlowQuery.data) return false;
    return dealInvestFlowQuery.data.documents.every(
      (document) => document.status !== "pending"
    );
  }, [dealInvestFlowQuery.data]);

  return (
    <>
      <PropertyInvestBreadcrumbsBar />
      <DocumentSigning />
      <div className="flex flex-col flex-grow py-4 gap-8 pt-20 pb-20 lg:pb-4 sm:px-4 md:px-8">
        <Stepper
          steps={["Select Tokens", "Disclosures", "Documents", "Checkout"]}
          activeStep={2}
        />

        <div className="flex gap-8">
          <div className="p-8 bg-white rounded-md flex-grow flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <h1 className="text-xl font-semibold">Documents</h1>
              <p className="text-sm text-black/80">
                Please read the documents carefully, You may need to sign the
                documents digitally to proceed futher.
              </p>
            </div>
            <div style={{ height: "400px" }}>
              <Table
                disableSelectionOnClick
                rows={dealInvestFlowQuery.data?.documents || []}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                loading={
                  dealInvestFlowQuery.isLoading ||
                  dealInvestFlowQuery.isFetching
                }
              />
            </div>
            <div className="flex gap-4 pt-8">
              <Link to="../disclosures">
                <PrimaryButton className="bg-black/10 text-black/40 px-8">
                  Back
                </PrimaryButton>
              </Link>

              {areDocumentsSigned ? (
                <Link to="../checkout">
                  <PrimaryButton className="px-8">Continue</PrimaryButton>
                </Link>
              ) : (
                <PrimaryButton className="px-8" disabled>
                  Sign/View All Documents Before Proceeding
                </PrimaryButton>
              )}
            </div>
          </div>
          <PropertyDetailsCard className="hidden lg:flex" />
        </div>
        <PropertyDetailsBottomBar moreDetails={<PropertyDetailsCard />} />
      </div>
    </>
  );
};

export default Documents;

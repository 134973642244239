import * as React from "react";
import CurrencyText from "../../../../../../../components/CurrencyText";

type Datum = {
  value: number;
  label: string;
  type: string;
};

interface IPropertyOverviewProps {
  data: Datum[];
}

const PropertyOverview = React.forwardRef<any, IPropertyOverviewProps>(
  ({ data }, ref) => {
    return (
      <div
        className="grid grid-cols-1 sm:grid-cols-2 gap-y-4 gap-x-4 divide-black/5 my-4"
        ref={ref}
      >
        {data.map((datum) => (
          <div className="flex flex-col items-center text-center gap-2 border-black/5 border rounded-sm p-5">
            <h3 className="text-foreground-primary font-semibold text-4xl">
              {datum.type === "currency" ? (
                <CurrencyText
                  value={datum?.value}
                  currency={"USD"}
                  minimumFractionDigits={0}
                  hideCurrency={false}
                />
              ) : (
                <span>
                  {datum.value} {"Days"}
                </span>
              )}
            </h3>
            <label htmlFor="" className="font-medium text-black/60">
              {datum.label}
            </label>
          </div>
        ))}
      </div>
    );
  }
);

export default PropertyOverview;

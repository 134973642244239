import { useQuery, useQueryClient } from "react-query"
import api from "api/axios"
import axios from "axios"

interface INotification {
  _id: string
  read: boolean
  userId: string
  communicationType: string
  type: string
  subject: string
  message: string
  createdAt: string
  updatedAt: string
}

export function useNotification() {
  return useQuery("notification", async () => {
    const notifications = await api.get(`/notifications`)
    return notifications.data as Array<INotification>
  })
}

export function useAllNotification() {
  return useQuery("notificationAll", async () => {
    const notifications = await api.get(`/notifications/old`)
    return notifications.data as Array<INotification>;
  })
}

export function useReadAllNotification(readAll:boolean) {
    const queryClient = useQueryClient();
  return useQuery("notificationReadAll", async () => {
    const notifications = await api.get(`/notifications/read`)
    return queryClient.invalidateQueries("notification")
  },{
    
    enabled:readAll,
    staleTime:Infinity

  })
}

export function useReadNotification(id: string, markAsRead: boolean) {
  const queryClient = useQueryClient()

  return useQuery(["notificationRead", id], async () => {
    const notifications = await api.get(`/notifications/read/${id}`)
    return queryClient.invalidateQueries("notification")    
  },{
      enabled:markAsRead,
      staleTime:Infinity
  })
}

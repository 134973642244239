import React, { useMemo, useState } from "react";
import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import Table from "components/Table";
import copyIcon from "assets/images/logos/copy-icon.jpeg";
import { useDocVaultQuery } from "hooks/queries/useDashboardQuery";
import _ from "lodash";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Chip, { AvailableVariants } from "components/Chip";
import BreadcrumbsBar from "components/BreadcrumbsBar";
import PrimaryButton from "components/Buttons/PrimaryButton";
import { useDocumentSigning } from "components/DocumentSigning/DocumentSigningProvider";
import DocumentSigning from "components/DocumentSigning";

const DocVault: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { openDocumentSigner } = useDocumentSigning();
  const [docType, setDocType] = useState("all");
  const docVaultQuery = useDocVaultQuery(docType, 1, 10) || [];

  const columns: GridColDef[] = [
    {
      field: "createdAt",
      headerName: "Investment Date",
      flex: 1,
      minWidth: 200,
      align: "left",
      renderCell(params) {
        return (
          <div>{moment(params.row.value).format("DD MMM YYYY, hh:mm a")}</div>
        );
      },
    },
    // {
    //   field: "title",
    //   headerName: "Deal name",
    //   flex: 1,
    //   minWidth: 100,
    //   align: "left",
    //   headerClassName: "sample",
    // },
    {
      field: "type",
      headerName: "Document Type",
      flex: 1,
      minWidth: 100,
      align: "left",
    },
    {
      field: "title",
      headerName: "Document Title",
      flex: 1,
      minWidth: 300,
      align: "left",
    },
    {
      field: "status",
      headerName: "Status",
      align: "left",
      flex: 1,
      minWidth: 100,
      renderCell(params: GridRenderCellParams<Document[]>) {
        return (
          <Chip
            text={params.row.status}
            variant={
              {
                signed: "completed",
                pending: "pending",
                viewed: "completed",
                view: "pending",
              }[
                params.row.status as "signed" | "pending" | "viewed"
              ] as AvailableVariants
            }
          />
        );
      },
    },
    {
      field: "viewUrl",
      headerName: "Sign/View",
      align: "left",
      flex: 1,
      minWidth: 100,
      renderCell(params) {
        return (
          <div className="px-3 py-2 text-sm">
            <div>
              {/* <a
                  href={params.row.viewUrl}
                  target="_blank"
                  className="downloadText"
                  rel="noreferrer"
                > */}
              <PrimaryButton
                className="px-4 bg-white border-foreground-accent text-foreground-accent border"
                onClick={() => openDocumentSigner(params.row)}
              >
                View
              </PrimaryButton>
              {/* </a> */}
            </div>

            {/* {params.row.status === "pending" && (
              <div onClick={() => navigate("/invest/" + params.row.propertyId)}>
                <label className="downloadText">Sign</label>
              </div>
            )} */}
          </div>
        );
      },
    },
  ];

  const rows = useMemo(() => {
    if (!docVaultQuery.data) return [];
    return docVaultQuery.data.contracts.map((contract) => ({
      ...contract,
      id: contract._id,
    }));
  }, [docVaultQuery.data]);
  return (
    <>
      <BreadcrumbsBar path={["Dashboard", "Doc Vault"]} />
      <div className="mt-20 bg-white rounded-md max-w-screen-xl mx-auto py-12 sm:px-12 xs:px-6 px-4">
        <h2 className="font-medium text-black/80 mb-6 text-xl">Doc Vault</h2>
        <div className="my-6">
          <Table
            autoHeight={true}
            rows={rows}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
          />
        </div>
      </div>
      <DocumentSigning/>
    </>
  );
};

export default DocVault;

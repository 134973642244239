import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { Footer, GoogleSignIn, Header, VideoBg } from "../components";
import TenantLogoHeader from "../components/TenantLogoHeader";

const AuthLayout: React.FunctionComponent = () => {
  return (
    <div className="flex flex-row w-full">
      <div className="flex-grow sm:min-w-[500px] lg:min-w-[600px] xl:min-w-[700px]">
        <Form />
      </div>
      <div className="hidden sm:block bg-yellow-200">
        <VideoBg />
      </div>
    </div>
  );
};

const Form = () => {
  return (
    <div className="col-span-12 sm:col-span-6 lg:col-span-5 overflow-y-auto h-full flex flex-col">
      <div className="w-full flex flex-col px-4 xs:px-8 sm:px-12">
        <div className="mx-auto pt-8 pb-12 px-6">
          <TenantLogoHeader />
        </div>
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};
export default AuthLayout;

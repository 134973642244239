import PrimaryButton from "components/Buttons/PrimaryButton";
import React, { useEffect, useMemo, useState } from "react";
import { GoKebabVertical } from "react-icons/go";
import Stepper from "../../components/Stepper";
import { BsArrowLeftShort, BsChevronRight } from "react-icons/bs";
import PropertyDetailsCard from "./components/PropertyDetailsCard";
import PropertyDetailsBottomBar from "../../components/PropertyDetailsBottomBar";
import PropertyInvestBreadcrumbsBar from "../../components/PropertyInvestBreadcrumbsBar";
import { Link, useNavigate, useParams } from "react-router-dom";
import useDealInvestFlowQuery from "hooks/queries/useDealInvestFlowQuery";
import useDealDetailsQuery from "hooks/queries/useDealDetailsQuery";
import useInvestSetTokenQuantitiyMutation from "hooks/mutations/invest/useInvestSetTokenQuantityMutation";
import { useWallet } from "context/Wallet";

interface ISelectTokensProps {}

const SelectTokens: React.FunctionComponent<ISelectTokensProps> = (props) => {
  const { propertyId } = useParams();
  const dealInvestFlowQuery = useDealInvestFlowQuery(propertyId!);
  const dealDetailsQuery = useDealDetailsQuery(propertyId!);
  const navigate = useNavigate();
  const { address } = useWallet();

  const investSetTokenQuantityMutation = useInvestSetTokenQuantitiyMutation(
    propertyId!
  );

  const [localSelectedTokenCount, setLocalSelectedTokenCount] = useState<
    number | undefined
  >(undefined);

  useEffect(() => {
    dealInvestFlowQuery.data?.quantity &&
      setLocalSelectedTokenCount(dealInvestFlowQuery.data?.quantity);
  }, [dealInvestFlowQuery.data?.quantity]);

  const subtotal = useMemo(() => {
    if (!localSelectedTokenCount) return "N/A";
    if (!dealDetailsQuery.data?.token.pricePerToken) return "...";

    return localSelectedTokenCount * dealDetailsQuery.data.token.pricePerToken;
  }, [localSelectedTokenCount, dealDetailsQuery]);

  const onNextStep = async () => {
    if (!localSelectedTokenCount) return;
    if (dealInvestFlowQuery.data?.quantity !== localSelectedTokenCount) {
      await investSetTokenQuantityMutation.mutateAsync({
        propertyId: propertyId!,
        quantity: localSelectedTokenCount,
        step: 1,
        to: address!,
      });
    }
    navigate("../disclosures");
  };

  return (
    <div className="flex flex-col flex-grow py-4 gap-8 pt-20 pb-20 lg:pb-4 sm:px-4 md:px-8">
      <PropertyInvestBreadcrumbsBar />
      <Stepper
        steps={["Select Tokens", "Disclosures", "Documents", "Checkout"]}
        activeStep={0}
      />
      <div className="flex gap-8">
        <div className="w-full bg-white p-8 rounded-md flex-col flex gap-6 flex-grow">
          <div className="flex flex-col gap-2">
            <h1 className="text-xl font-semibold">No Of Tokens</h1>
            <p className="text-sm text-black/80">
              Type or select the number of tokens
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <div className="flex gap-2">
              <button
                className="rounded-md border border-black/50 w-10 h-10 relative flex-shrink-0"
                onClick={() =>
                  setLocalSelectedTokenCount((count) => (count || 0) - 1)
                }
              >
                <div className="absolute inset-0 grid place-items-center text-2xl">
                  -
                </div>
              </button>
              <input
                value={localSelectedTokenCount}
                onChange={(e) =>
                  setLocalSelectedTokenCount(parseInt(e.target.value))
                }
                type="number"
                className="flex-grow max-w-[400px] border-black/50 border rounded-md px-4 min-w-0"
              />
              <button
                className="rounded-md border border-black/50 w-10 h-10 relative flex-shrink-0"
                onClick={() =>
                  setLocalSelectedTokenCount((count) => (count || 0) + 1)
                }
              >
                <div className="absolute inset-0 grid place-items-center text-2xl">
                  +
                </div>
              </button>
              <div className="bg-orange-200 text-orange-700 py-2 px-3 rounded-full text-sm font-medium h-min my-auto whitespace-nowrap">
                {dealDetailsQuery.data &&
                  Intl.NumberFormat("en-GB", {
                    notation: "compact",
                    compactDisplay: "short",
                  }).format(
                    dealDetailsQuery.data?.token.totalTokens -
                      (dealDetailsQuery.data?.token.soldTokens || 0)
                  )}{" "}
                Available
              </div>
            </div>
            <div className="flex gap-2">
              <button
                className="rounded-md border border-black/30 text-black/60 py-2 px-4 flex-grow"
                onClick={() =>
                  setLocalSelectedTokenCount((count) => (count || 0) + 10)
                }
              >
                + 10
              </button>
              <button
                className="rounded-md border border-black/30 text-black/60 py-2 px-4  flex-grow"
                onClick={() =>
                  setLocalSelectedTokenCount((count) => (count || 0) + 50)
                }
              >
                + 50
              </button>
              <button
                className="rounded-md border border-black/30 text-black/60 py-2 px-4  flex-grow"
                onClick={() =>
                  setLocalSelectedTokenCount((count) => (count || 0) + 100)
                }
              >
                + 100
              </button>
              <button
                className="rounded-md border border-black/30 text-black/60 py-2 px-4  flex-grow"
                onClick={() =>
                  setLocalSelectedTokenCount((count) => (count || 0) + 500)
                }
              >
                + 500
              </button>
            </div>
          </div>
          <div className="grid grid-cols-2 w-max gap-x-16 gap-y-1 pt-8">
            <div className=" font-semibold text-black/80">Price Per Token</div>
            <div className="text-black/80">
              $ {dealDetailsQuery.data?.token?.pricePerToken || "N/A"}
            </div>
            <div className="font-semibold text-black/80">Total</div>
            <div>$ {subtotal}</div>
          </div>
          {/* <Link to="../disclosures"> */}
          <PrimaryButton
            onClick={onNextStep}
            className="w-max px-6 mt-8"
            disabled={investSetTokenQuantityMutation.isLoading}
          >
            {investSetTokenQuantityMutation.isLoading
              ? "Loading..."
              : "Continue to Disclosures"}
          </PrimaryButton>
          {/* </Link> */}
        </div>
        <PropertyDetailsCard className="hidden lg:flex" />
      </div>
      <PropertyDetailsBottomBar
        moreDetails={<PropertyDetailsCard className="shadow-lg" />}
      />
    </div>
  );
};

export default SelectTokens;

import api from "api/axios";
import { useMutation, useQueryClient } from "react-query";
import useLogoutMutation from "../auth/useLogoutMutation";

type UpdatePwResponse = {};

interface IUpdatePw {
  otp: number;
  otpMethod: "email" | "sms";
  oldPassword: string;
  newPassword: string;
}

const useUpdatePasswordMutation = () => {
  return useMutation(async (payload: IUpdatePw) => {
    const updatePwResponse = await api.put("/users/password", payload);
    return updatePwResponse.data as UpdatePwResponse;
  });
};

export default useUpdatePasswordMutation;

import usePropertiesQuery from "hooks/queries/usePropertiesQuery";
import LargestPropertyCard from "pages/Marketplace/components/LargestPropertyCard";
import PropertyCard from "pages/Marketplace/components/PropertyCard";

const SpecialDealSection: React.FunctionComponent = () => {
  const propertiesQuery = usePropertiesQuery();
  const singlePage = propertiesQuery.data?.pages[0];
  const property = singlePage?.properties[0];
  return (
    <>
      <div className="py-4 px-5 md:py-5 md:px-10 lg:py-20 lg:px-40 bg-background-primary">
        <h1 className="text-2xl md:text-3xl font-bold text-black/80 mb-5">
          Special Deal
        </h1>
        <div className="hidden md:block">
          <LargestPropertyCard
            propertyName={property?.name ?? ""}
            propertyId={property?._id ?? ""}
            propertyLocation={property?.owner.name ?? ""}
            targettedIrr={property?.roi?.IRR ?? 0}
            avgCashYield={property?.roi?.averageCashYield ?? 0}
            tokensSold={property?.token?.soldTokens ?? 0}
            tokensTotalSupply={property?.token?.totalTokens ?? 0}
          />
        </div>
        <div className="md:hidden">
          <PropertyCard
            status={property?.status || ""}
            propertyName={property?.name ?? ""}
            propertyId={property?._id ?? ""}
            propertyLocation={property?.owner.name ?? ""}
            targettedIrr={property?.roi?.IRR ?? 0}
            avgCashYield={property?.roi?.averageCashYield ?? 0}
            tokensSold={property?.token.soldTokens ?? 0}
            tokensTotalSupply={property?.token?.totalTokens ?? 0}
          />
        </div>
      </div>
    </>
  );
};

export default SpecialDealSection;

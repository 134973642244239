import { useQuery } from "react-query";
import api from "api/axios";
import { IInvestmentSummary } from "./useInvestmentSummaryQuery";

type InvestmentsSummary = IInvestmentSummary[]

export default function useInvestmentsSummaryQuery() {
  return useQuery(["investmentsSummary"], async () => {
    const investmentOverview = await api.get(
      `/investment/summary?per_page=500`
    );
    return investmentOverview.data as InvestmentsSummary;
  });
}

import { useQuery } from "react-query";
import api from "api/axios";

type Deal = {
  name: string;
  status: "listed" | "upcoming";
  token: {
    value: number;
    minimumInvestment: number;
    pricePerToken: number;
    holdPeriodDays: number;
    totalTokens: number;
    soldTokens: number;
    availableTokens: number;
    name: string;
    symbol: string;
  };
  type: "realEstateFund" | "asset" | "debtFund" | "others";
  owner: {
    name: string;
    type: string;
    id: string;
  };
  roi: {
    IRR: number;
    averageCashYield: number;
  };
  address: {};
  marketing: {
    coverImage: string;
  };
  description: string;
  faqs: Faq[];
  documents: Document[];
  disclosuresAndTerms: {
    id: string;
  };
  disclosure?: Disclosure[];
  terms?: string[];
};

type Disclosure = {
  id: string;
  title?: string;
  description?: string;
};

type Faq = {
  question: string;
  answer: string;
};

export type Document = {
  title: string;
  file: {
    Location: string;
  };
};

export default function useDealDetailsQuery(dealId: string) {
  return useQuery(["dealDetails", dealId], async () => {
    const dealDetails = await api.get(`/deal/${dealId}`);
    return dealDetails.data as Deal;
  });
}

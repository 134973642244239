import React, { useMemo, useState } from "react";
import {
  GridColDef,
  GridRowParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import Table from "components/Table";
import copyIcon from "assets/images/logos/copy-icon.jpeg";
import {
  Investment,
  useInvestmentsQuery,
} from "hooks/queries/useDashboardQuery";
import _ from "lodash";
import { format } from "date-fns";
import { copyToClipboard } from "utils/copy-to-clipboard";
import { truncText } from "utils/truncate-text";
import Chip, { AvailableVariants } from "components/Chip";
import { IoCopyOutline } from "react-icons/io5";
import { HiExternalLink } from "react-icons/hi";
import BreadcrumbsBar from "components/BreadcrumbsBar";
import { useNavigate } from "react-router-dom";
import Tooltip from "components/Tooltip";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { v4 as uuidv4 } from "uuid";

type TransactionsRow = {
  id: string;
} & Investment;

const columns: GridColDef<TransactionsRow>[] = [
  {
    field: "propertyName",
    headerName: "Deal name",
    flex: 1,
    minWidth: 200,
    align: "left",
    headerClassName: "sample",
  },
  {
    field: "updatedAt",
    headerName: "Investment Date",
    minWidth: 280,
    flex: 1,
    align: "left",
    renderCell(params) {
      return (
        <div>
          {format(new Date(params.row.updatedAt), "dd MMM yyyy, hh:mm a")}
        </div>
      );
    },
  },
  {
    field: "noOfTokens",
    headerName: "Tokens",
    minWidth: 50,
    flex: 0.5,
    align: "left",
  },
  {
    field: "amount",
    headerName: "Amount",
    minWidth: 100,
    flex: 0.9,
    align: "left",
  },
  {
    field: "status",
    headerName: "Status",
    align: "left",
    flex: 1,
    minWidth: 190,
    renderCell(params) {
      return params.row.status ? (
        <Chip
          text={
            {
              success: "Successful",
              pending: "Incomplete",
              pendingApproval: "Pending",
            }[params.row.status]
          }
          variant={
            {
              success: "completed",
              pending: "pending",
              pendingApproval: "pending",
            }[params.row.status] as AvailableVariants
          }
          tooltip={
            params.row.status === "pendingApproval"
              ? "Awaiting approval from Admin"
              : undefined
          }
        />
      ) : (
        <></>
      );
    },
  },
  {
    field: "txHash",
    headerName: "Transaction Hash",
    align: "left",
    minWidth: 200,
    flex: 1,
    renderCell(params) {
      if (params.row.status === "pending") {
        return <div className="text-center font-medium text-lg">-</div>;
      } else if (params.row.status === "pendingApproval") {
        const id = uuidv4();

        return (
          <>
            <Tooltip id={id}>
              <div className="max-w-[100px] whitespace-normal">
                Transaction Hash will be generated after transaction is approved
              </div>
            </Tooltip>

            <div
              className="text-center flex items-center gap-2 font-mono"
              data-tip
              data-for={id}
            >
              <AiOutlineInfoCircle />
              Pending
            </div>
          </>
        );
      } else {
        return (
          <div className="flex items-center font-mono gap-2">
            <a
              className="flex items-center gap-2"
              href={`https://mumbai.polygonscan.com/tx/${params.row.txHash}`}
              target="_blank"
              rel="noreferrer"
            >
              {truncText(params.row.txHash)}
              <HiExternalLink className="h-4 w-auto" />
            </a>
            <button onClick={() => copyToClipboard(params.row.txHash)}>
              <IoCopyOutline className="h-4 w-auto" />
            </button>
          </div>
        );
      }
    },
  },
];

const Transactions: React.FunctionComponent = () => {
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const investmentQuery = useInvestmentsQuery(currentPage + 1);
  const navigate = useNavigate();

  const rows = useMemo(() => {
    if (!investmentQuery.data?.data) return [];

    return investmentQuery.data.data.map((row) => {
      console.log(row, row.payment);
      return {
        ...row,
        id: row._id,
        amount: row.payment?.total || "N/A",
      };
    });
  }, [investmentQuery.data]);

  return (
    <>
      <BreadcrumbsBar path={["Dashboard", "My Transactions"]} />
      <div className="mt-20 bg-white rounded-md max-w-screen-xl mx-auto py-12 sm:px-12 xs:px-6 px-4">
        <h2 className="font-medium text-black/80 mb-6 text-xl">
          Transaction Details
        </h2>
        <div className="my-6">
          <Table
            rows={rows}
            columns={columns}
            rowCount={
              investmentQuery.data ? investmentQuery.data.pages * pageSize : 0
            }
            loading={investmentQuery.isLoading || investmentQuery.isFetching}
            paginationMode="server"
            pageSize={10}
            autoHeight
            onPageChange={(i) => setCurrentPage(i)}
            onRowClick={(params: GridRowParams<TransactionsRow>) => {
              if (params.row.status === "pending") {
                navigate(`/marketplace/${params.row.propertyId}/buy`);
              } else if (
                params.row.status === "pendingApproval" ||
                params.row.status === "success"
              ) {
                navigate(
                  `/marketplace/${params.row.propertyId}/buy/completion/${params.row._id}`
                );
              }
            }}
          />
        </div>
      </div>
    </>
  );
};
export default Transactions;

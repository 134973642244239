import { useConnectWallet, useSetChain } from "@web3-onboard/react";
import * as React from "react";
import { ButtonProps } from "react-html-props";
import PrimaryButton from "./Buttons/PrimaryButton";

interface IWeb3ActionProps extends ButtonProps {
  chainId?: string;
  switchChainText?: string;
  connectWalletText?: string;
}

const Web3Action: React.FunctionComponent<IWeb3ActionProps> = ({
  chainId,
  className,
  switchChainText,
  connectWalletText,
  ...props
}) => {
  const [
    {
      wallet, // the wallet that has been connected or null if not yet connected
      connecting, // boolean indicating if connection is in progress
    },
    connect, // function to call to initiate user to connect wallet
    disconnect, // function to call to with wallet<DisconnectOptions> to disconnect wallet
  ] = useConnectWallet();

  const [
    {
      chains, // the list of chains that web3-onboard was initialized with
      connectedChain, // the current chain the user's wallet is connected to
      settingChain, // boolean indicating if the chain is in the process of being set
    },
    setChain, // function to call to initiate user to switch chains in their wallet
  ] = useSetChain();

  if (!wallet || !connectedChain)
    return (
      //@ts-ignore
      <PrimaryButton onClick={() => connect()} className={className}>
        {connectWalletText || "Connect Wallet to Continue"}
      </PrimaryButton>
    );

  if (chainId && (!connectedChain || connectedChain.id !== chainId))
    return (
      <PrimaryButton
        onClick={() => setChain({ chainId })}
        className={className}
      >
        {switchChainText || "Switch Chain to Continue"}
      </PrimaryButton>
    );

  return <>{props.children}</>;
};

export default Web3Action;

import api from "api/axios";
import { useMutation, useQueryClient } from "react-query";

type SignupResponse = {
  hash: string;
  message: string;
};

export interface ISignUp {
  email: string;
  password: string;
  contact: string;
  countryCode: string;
  postCode: string;
  state: string;
  country: string;
  city: string;
}

const useSignupMutation = () => {
  return useMutation(
    async ({
      email,
      password,
      contact,
      countryCode,
      postCode,
      state,
      country,
      city,
    }: ISignUp) => {
      const payload = {
        email,
        firstname: " ",
        lastname: " ",
        contact,
        password,
        countryCode,
        address: {
          postCode: postCode,
          state: state,
          country: country,
          city: city,
        },
        role: ["buyer"],
      };
      const signupResponse = await api.post("/users/register", payload);
      return signupResponse.data as SignupResponse;
    }
  );
};

export default useSignupMutation;

import { RadioGroup } from "@headlessui/react";
import PrimaryButton from "components/Buttons/PrimaryButton";
import { AccreditationValues, AccreditationPoints } from "..";

export interface SelfAccFormUS {
  selectedOption: "" | AccreditationValues;
  setSelectedOption: React.Dispatch<
    React.SetStateAction<"" | AccreditationValues>
  >;
  attest: boolean | undefined;
  setAttest: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  onSubmit: (isNonUSA: boolean) => void;
  isSubmitting: boolean;
}
export const SelfAccFormUSA: React.FunctionComponent<SelfAccFormUS> = ({
  selectedOption,
  setSelectedOption,
  attest,
  setAttest,
  onSubmit,
  isSubmitting,
}) => {
  return (
    <>
      <div className="text-slate-600 my-4">
        I understand I must meet either of the following income (section A), net
        worth (section B) or sophisticated investor (section C) requirements
        below, and have significant investment, business and financial
        experience.
      </div>
      <RadioGroup value={selectedOption} onChange={setSelectedOption}>
        <RadioGroup.Label className="text-slate-700 mt-2 mb-4">
          Select one option *
        </RadioGroup.Label>
        <RadioGroup.Option value={AccreditationPoints.sectionA}>
          {({ checked }) => (
            <div className="flex items-start">
              <input type="radio" checked={checked} className="mt-1 mx-4" />
              <span>{AccreditationPoints.sectionA}</span>
            </div>
          )}
        </RadioGroup.Option>
        <RadioGroup.Option value={AccreditationPoints.sectionB}>
          {({ checked }) => (
            <div className="flex items-start">
              <input type="radio" checked={checked} className="mt-1 mx-4" />
              <span>{AccreditationPoints.sectionB}</span>
            </div>
          )}
        </RadioGroup.Option>
        <RadioGroup.Option value={AccreditationPoints.sectionC}>
          {({ checked }) => (
            <div className="flex items-start">
              <input type="radio" checked={checked} className="mt-1 mx-4" />
              <span>{AccreditationPoints.sectionC}</span>
            </div>
          )}
        </RadioGroup.Option>
      </RadioGroup>
      <div className="flex items-start mt-6">
        <input
          type="checkbox"
          checked={attest}
          onChange={(e) => setAttest(e.target.checked)}
          className="mx-4 mt-1"
        />
        <label className="font-medium">I attest the above is true</label>
      </div>
      <PrimaryButton
        className="mx-4 my-8 disabled:bg-opacity-70 px-6"
        disabled={isSubmitting || !selectedOption || !attest}
        onClick={() => {
          onSubmit(false);
        }}
      >
        {isSubmitting ? "Submitting..." : "Submit"}
      </PrimaryButton>
    </>
  );
};

import Select from "components/Input/Select";
import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const DistributionChart: React.FunctionComponent<any> = ({
  dealNames,
  setDealName,
  years,
  dealName,
  setYear,
  chartData,
  year,
}) => {
  return (
    <div className="mt-9 bg-white rounded-md shadow-sm p-8 sm:p-12">
      <div className="flex flex-col sm:flex-row gap-4 items-center justify-between">
        <h1 className="text-2xl text-black/80 font-medium sm:text-xl">
          Income Distribution
        </h1>
        <div className="flex gap-3">
          <Select
            options={dealNames}
            onChange={(dealName) => setDealName(dealName)}
            value={dealName}
          />
          <Select
            options={years}
            onChange={(year) => setYear(year)}
            value={year}
          />
        </div>
      </div>
      <div className="mt-12">
        {chartData && chartData.length > 0 && (
          <ResponsiveContainer height={400}>
            <LineChart data={chartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip />
              <XAxis dataKey="name" axisLine={false} />
              <YAxis axisLine={false} tickLine={false} />
              <Line
                type="monotone"
                dataKey="distribution"
                stroke={"#F56B0A"}
                strokeWidth={3}
              />
            </LineChart>
          </ResponsiveContainer>
        )}
        {!chartData ||
          (chartData && chartData.length === 0 && (
            <h2>No Data found for given selection</h2>
          ))}
      </div>
    </div>
  );
};

export default DistributionChart;

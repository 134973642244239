import PrimaryButton from "components/Buttons/PrimaryButton";
import { MATIC_TESTNET } from "config/Chains";
import { useWallet } from "context/Wallet";
import useDealDetailsQuery from "hooks/queries/useDealDetailsQuery";
import useDealInvestFlowQuery from "hooks/queries/useDealInvestFlowQuery";
import { useTokenAllowanceQuery } from "hooks/queries/web3/useTokenAllowanceQuery";
import { useTokenDecimalsQuery } from "hooks/queries/web3/useTokenDecimalsQuery";
import * as React from "react";
import { useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import Step, { StepStatus } from "../components/Step";
import { IStepProps } from "./step.type";
import { Oval } from "react-loader-spinner";
import { AiOutlineInfoCircle } from "react-icons/ai";
import Web3Action from "components/Web3Action";

import dealTokenAbi from "assets/abi/dealToken.json";
import { ethers } from "ethers";
import useInvestAddTransactionHashMutation from "hooks/mutations/invest/useInvestAddTransactionHashMutation";

const MintStep: React.FunctionComponent<IStepProps> = ({
  activeStepNumber,
  stepNumber,
  onNextStep,
}) => {
  const { ethersSigner: ethersProvider, address } = useWallet();
  const { propertyId } = useParams();

  const dealInvestFlowQuery = useDealInvestFlowQuery(propertyId!);
  const dealDetailsQuery = useDealDetailsQuery(propertyId!);
  const investAddTransactionHashMutation = useInvestAddTransactionHashMutation(
    propertyId!
  );

  const navigate = useNavigate();

  const transactionConfirmationMutation = useMutation(
    async ({ transaction }: { transaction: ethers.ContractTransaction }) => {
      await transaction.wait();
      return transaction;
    },
    {
      onSuccess: async (transaction) => {
        await investAddTransactionHashMutation.mutateAsync({
          propertyId: propertyId!,
          transactionHash: transaction.hash,
          step: 4,
          quantity: dealInvestFlowQuery.data?.noOfTokens!,
        });
        navigate(`../completion/${dealInvestFlowQuery.data?._id}`);
      },
    }
  );

  const buyTokensMutation = useMutation<ethers.ContractTransaction, Error>(
    async () => {
      if (!dealInvestFlowQuery.data) {
        throw new Error("Dependencies not met. Please try again.");
      }

      const tokenContract = new ethers.Contract(
        dealInvestFlowQuery.data.contractAddress,
        dealTokenAbi,
        ethersProvider
      );

      return await tokenContract.buyWithUsdc(
        ethers.utils.parseUnits(`${dealInvestFlowQuery.data.noOfTokens}`, 18)
      );
    },
    {
      onSuccess: (transaction) => {
        transactionConfirmationMutation.mutateAsync({ transaction });
      },
    }
  );

  return (
    <Step
      title="Checkout"
      stepNumber={stepNumber}
      activeStepNumber={activeStepNumber}
    >
      <div className="flex flex-col py-4">
        <div className="flex rounded-md border border-black/10 p-6 gap-4 mt-4">
          <div className="pt-0.5">
            <AiOutlineInfoCircle className="w-4 h-auto" />
          </div>
          <div className="flex-col flex gap-4">
            <p className="font-medium">Proceed to buy the deal token</p>
            <p>You can now purchase the deal tokens.</p>
            {buyTokensMutation.error && (
              <div className="rounded-md p-4 bg-red-100/50 border border-red-200 text-black/80 flex flex-col gap-2">
                <h1 className="text-red-400 font-medium text-xs">Error</h1>
                <p>{buyTokensMutation.error.message}</p>
                <p>Please try again.</p>
              </div>
            )}
            <div className="flex gap-4">
              <Web3Action chainId={MATIC_TESTNET.id}>
                {buyTokensMutation.data ? (
                  <a
                    href={`https://mumbai.polygonscan.com/tx/${buyTokensMutation.data.hash}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="p-4 border border-black/10 rounded-md">
                      {transactionConfirmationMutation.isLoading ? (
                        <div className="flex items-center justify-center gap-2">
                          <Oval
                            ariaLabel="loading-indicator"
                            height={20}
                            width={20}
                            strokeWidth={10}
                            strokeWidthSecondary={0}
                            color="#00000050"
                            secondaryColor="#00000080"
                          />
                          Waiting for transaction to complete
                        </div>
                      ) : (
                        <div>
                          Congragulations! Your buy transaction was successful.
                          Click here to view transaction details.
                        </div>
                      )}
                    </div>
                  </a>
                ) : (
                  <PrimaryButton
                    onClick={() => {
                      buyTokensMutation.mutateAsync();
                    }}
                    disabled={buyTokensMutation.isLoading}
                  >
                    {buyTokensMutation.isLoading ? (
                      <div className="flex items-center justify-center gap-2">
                        <Oval
                          ariaLabel="loading-indicator"
                          height={20}
                          width={20}
                          strokeWidth={10}
                          strokeWidthSecondary={0}
                          color="#ffffff"
                          secondaryColor="#ffffff80"
                        />
                        Accept Transaction Request In Wallet
                      </div>
                    ) : (
                      "Buy Tokens"
                    )}
                  </PrimaryButton>
                )}
              </Web3Action>
            </div>
          </div>
        </div>
      </div>
    </Step>
  );
};

export default MintStep;

import Dropdown from "components/Dropdown"
import { useNotification,useReadAllNotification } from "hooks/queries/useNotificationsQuery"
import { useEffect, useState } from "react"
import { FiBell } from "react-icons/fi"
import { TailSpin } from "react-loader-spinner"
import { Link } from "react-router-dom"
import { NavNotification } from "./NavNotification"

export const NotificationDropDown = () => {
  const { data, isLoading,isError } = useNotification();
  const [clearAll, setClearAll] = useState<boolean>(false)
  const ReadAllNotification=useReadAllNotification(clearAll);
  useEffect(() => {
     if(data&&data.length>0){
        setClearAll(false)
     }
   }, [data])
   


  return (
    <Dropdown
      button={<div className="relative w-full h-16 flex items-center outline-none"><FiBell className="text-white h-5 w-auto" />
           {data && data.length > 0&&<span className="rounded-full bg-red-600 w-2 h-2 absolute right-0 top-4"></span>}
      </div>}
      body={<div className="bg-white px-4 py-4  mt-3 mr-60  flex flex-col w-96 rounded shadow-md max-h-80 overflow-y-scroll">
          <div className="text-black font-semibold text-lg">Notificaions</div>
          <div className="flex flex-grow justify-between my-2">
            <div className="hover:text-foreground-primary hover:underline">
              <Link to="/notifications">View All</Link>
            </div>
            {data && data.length > 0 ? (
              <div className="hover:text-red-500 cursor-pointer" onClick={()=>setClearAll(true)}>Clear All</div>
            ) : (
              <div className="opacity-60">Clear All</div>
            )}
          </div>
          
            
          {isLoading && (
            <TailSpin
              ariaLabel="loading-indicator"
              color="red"
              wrapperClass="flex justify-center"
            />
          )}
          {data && data.length === 0 && (
            <p className="text-center font-semibold">No New Notification</p>
          )}

          {data?.map((val) => {
            return (
              <NavNotification
                key={val._id}
                createdAt={val.createdAt}
                subject={val.subject}
                message={val.message}
                id={val._id}
              />
            )
          })}
            
            {
              isError&&(  <p className="text-center text-red-600">
                Something Went Wrong
              </p>)
            }

        </div>
      }
    />
  )
}

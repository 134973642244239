import { DocumentSigningProvider } from "components/DocumentSigning/DocumentSigningProvider";
import React from "react";
import { WalletProvider } from "./Wallet";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, Zoom } from "react-toastify";

export const AppProviders: React.FunctionComponent<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <DocumentSigningProvider>
      <WalletProvider>{children}</WalletProvider>
      <ToastContainer
        position={"bottom-center"}
        autoClose={2000}
        hideProgressBar={true}
        closeOnClick={true}
        pauseOnHover={true}
        transition={Zoom}
      ></ToastContainer>
    </DocumentSigningProvider>
  );
};

export default AppProviders;

import * as React from "react";
import { Route, Routes } from "react-router-dom";
import PropertyDetails from "./pages/PropertyDetails";
import PropertyInvest from "./pages/PropertyInvest";
interface IPropertyProps {}

const Property: React.FunctionComponent<IPropertyProps> = (props) => {
  return (
    <Routes>
      <Route index element={<PropertyDetails />} />
      <Route path="buy/*" element={<PropertyInvest />} />
    </Routes>
  );
};

export default Property;

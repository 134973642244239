import useDealDetailsQuery from "hooks/queries/useDealDetailsQuery";
import useDealInvestFlowQuery from "hooks/queries/useDealInvestFlowQuery";
import useInvestmentSummaryQuery from "hooks/queries/useInvestmentSummaryQuery";
import _ from "lodash";
import * as React from "react";
import { DivProps } from "react-html-props";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { GoKebabVertical } from "react-icons/go";
import { useNavigate, useParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { format } from "date-fns";
import TrackTokenButton from "components/Buttons/TrackTokenButton";
import { MATIC_TESTNET } from "config/Chains";

interface IPropertyDetailsCardProps extends DivProps {}

const InvestmentDetailsCard: React.FunctionComponent<
  IPropertyDetailsCardProps
> = (props) => {
  const { propertyId, investmentId } = useParams();
  const dealDetailsQuery = useDealDetailsQuery(propertyId!);
  const investmentSummaryQuery = useInvestmentSummaryQuery(investmentId!);

  return (
    <div
      className={twMerge(
        "p-8 bg-white rounded-md flex flex-col max-w-md gap-5",
        props.className
      )}
    >
      <h1 className="font-medium whitespace-nowrap">Order Summary</h1>
      <div className="flex gap-4 border-b pb-4 border-black/10 border-dashed">
        <img
          src={dealDetailsQuery.data?.marketing.coverImage}
          className="w-1/3 rounded-md shadow"
          alt=""
        />
        <div className="flex flex-grow justify-between">
          <div className="flex flex-col gap-0.5">
            <p className="font-medium">{dealDetailsQuery.data?.name}</p>
            <p className="text-sm text-black/60">
              {_.startCase(dealDetailsQuery.data?.type)}
            </p>
            <div className="text-sm text-black/60">
              {dealDetailsQuery.data?.token.name}(
              {dealDetailsQuery.data?.token.symbol})
            </div>
          </div>
          <div>
            <GoKebabVertical className="mt-2 w-5 h-auto" />
          </div>
        </div>
      </div>
      <div className="flex justify-between text-sm border-b pb-4 border-black/10 border-dashed">
        <h3 className="font-semibold">Date of Purchase</h3>
        <div className="flex flex-col text-right gap-1">
          <p className="font-semibold">
            {investmentSummaryQuery.data &&
              format(
                new Date(investmentSummaryQuery.data?.investmentDate!),
                "dd MMMM yyyy"
              )}
          </p>
        </div>
      </div>
      <div className="flex justify-between text-sm border-b pb-4 border-black/10 border-dashed">
        <h3 className="font-semibold">No of tokens purchased</h3>
        <div className="flex flex-col text-right gap-1">
          <div className="font-semibold flex items-center gap-1 text-right justify-end">
            <div>{investmentSummaryQuery.data?.noOfTokens}</div>
            <div>
              {dealDetailsQuery.data?.token.name}(
              {dealDetailsQuery.data?.token.symbol})
            </div>
          </div>
          <label className="text-xs text-black/60">
            Price/Token = ${dealDetailsQuery.data?.token.pricePerToken}
          </label>
        </div>
      </div>
      <div className="flex justify-between text-sm pb-2 border-black/10 border-dashed">
        <h3 className="font-semibold">Value</h3>
        <div className="flex flex-col text-right gap-1">
          <p className="font-semibold">
            $ {investmentSummaryQuery.data?.investments}
          </p>
        </div>
      </div>
      {investmentSummaryQuery.data && dealDetailsQuery.data && (
        <TrackTokenButton
          chain={MATIC_TESTNET}
          tokenAddress={investmentSummaryQuery.data?.contractAddress}
          symbol={dealDetailsQuery.data?.token.symbol}
        />
      )}
    </div>
  );
};

export default InvestmentDetailsCard;

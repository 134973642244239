import Layout from "./components/Layout";
import * as React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Profile from "./pages/Profile";
import SelfAcc from "./pages/SelfAcc";
import UpdatePassword from "./pages/UpdatePassword";
import Kyc from "./pages/Kyc";

interface IAccountProps {}

const Account: React.FunctionComponent<IAccountProps> = (props) => {
  return (
    <Routes>
      <Route path="*" element={<Layout />}>
        <Route path="profile" element={<Profile />} />
        <Route path="password" element={<UpdatePassword />} />
        <Route path="verify" element={<SelfAcc />} />
        <Route path="kyc" element={<Kyc />} />
        <Route path="*" element={<Navigate to="/account/profile" replace />} />
      </Route>
    </Routes>
  );
};

export default Account;

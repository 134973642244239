import { useConnectWallet } from "@web3-onboard/react";
import WalletModal from "components/Modals/WalletModal";
import * as React from "react";
import { ButtonProps } from "react-html-props";
import { IoWalletOutline } from "react-icons/io5";

interface IWalletButtonProps extends ButtonProps {}

const WalletButton: React.FunctionComponent<IWalletButtonProps> = (props) => {
  const [
    {
      wallet, // the wallet that has been connected or null if not yet connected
      connecting, // boolean indicating if connection is in progress
    },
    connect, // function to call to initiate user to connect wallet
    disconnect, // function to call to with wallet<DisconnectOptions> to disconnect wallet
  ] = useConnectWallet();

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  return wallet?.accounts?.[0].address ? (
    <>
      <button
        onClick={(e) => {
          props.onClick?.(e);
          // @ts-ignore
          setIsModalOpen(true);
        }}
        className="bg-white rounded-full px-3 py-1 text-sm flex items-center font-mono gap-3 text-foreground-primary"
      >
        <IoWalletOutline className="h-6 w-auto" />
        <div className="pt-0.5">
          {wallet.accounts[0].address.slice(0, 5)}...
          {wallet.accounts[0].address.slice(37)}
        </div>
      </button>
      <WalletModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </>
  ) : (
    <button
      onClick={async (e) => {
        props.onClick?.(e);
        // @ts-ignore
        console.log(await connect());
      }}
      className="text-sm flex items-center text-white"
    >
      <IoWalletOutline className="h-6 w-auto" />
    </button>
  );
};

export default WalletButton;

import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import "react-phone-input-2/lib/style.css";

import { SignUpStep1 } from "./pages/FormStep1";
import { SignUpStep2 } from "./pages/FormStep2";

import create from "zustand";

import { Navigate, Route, Routes } from "react-router-dom";
import UserVerifyStep from "./pages/UserVerifyStep";

interface ISignupForm {}

export interface ISignupDataStep1 {
  email: string;
  acceptTerms: boolean;
  contact: string;
  countryCode: string;
  password: string;
}

export interface ISignupDataStep2 {
  country: string;
  state: string;
  city: string;
  postCode: string;
}

interface ISignupFormDataStore {
  data: Partial<ISignupDataStep1 & ISignupDataStep2>;
  isStepOneCompleted?: boolean;
  isStepTwoCompleted?: boolean;
  updateData: (data: Partial<ISignupDataStep1 & ISignupDataStep2>) => void;
  setStepOneData: (
    data: ISignupDataStep1
  ) => Partial<ISignupDataStep1 & ISignupDataStep2>;
  setStepTwoData: (
    data: ISignupDataStep2
  ) => Partial<ISignupDataStep1 & ISignupDataStep2>;
}

export const useSignupFormDataStore = create<ISignupFormDataStore>()(
  (set, get) => ({
    data: {},
    updateData: (data) =>
      set((state) => ({ ...state, data: { ...state.data, ...data } })),
    setStepOneData: (data) => {
      set((state) => ({ ...state, isStepOneCompleted: true }));
      get().updateData(data);
      return get().data;
    },
    setStepTwoData: (data) => {
      set((state) => ({ ...state, isStepTwoCompleted: true }));
      get().updateData(data);
      return get().data;
    },
  })
);

const SignUp: React.FunctionComponent<ISignupForm> = () => {
  return (
    <>
      <Routes>
        <Route index element={<SignUpStep1 />} />
        <Route path="step-2" element={<SignUpStep2 />} />
        <Route path="verify" element={<UserVerifyStep />} />
      </Routes>
      {/* {step === 2 && (
          <SignUpStep2
            formState={formState}
            watch={watch}
            control={control}
            setValue={setValue}
            register={register}
            errors={errors}
            setStep={setStep}
            isSubmitting={signupMutation.isLoading}
          />
        )} */}

      {/* { && (
        <UserVerifyDialog
          onClose={() => {
            setIsFormSubmitted(false);
          }}
          email={email}
        />
      )} */}
    </>
  );
};

export default SignUp;

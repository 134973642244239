import React from 'react'
import { FormattedNumber } from 'react-intl';
import { convertCurrencyToMillions, oneMillion } from '../../utils/user-utils';

interface ICurrencyTextProps {
  currency: string;
  value: number;
  minimumFractionDigits: number;
  hideCurrency: boolean;
}

const CurrencyText: React.FunctionComponent<ICurrencyTextProps> = ({ currency='USD', value, minimumFractionDigits=2, hideCurrency }) => {
  return (
    <>
        <FormattedNumber
            value={value}
            style="currency"
            minimumFractionDigits={minimumFractionDigits ?? 2}
            maximumFractionDigits={2}
            currency={currency}
            notation="compact"
        />
        {currency && !hideCurrency ? <span> {currency}</span> : ''}
    </>
  );
};

export default CurrencyText;

import React, { useEffect, useMemo } from "react";
import { InvestmentCard } from "./components/InvestmentCard";
import PropertyCard from "./components/PropertyCard";
import { useFetchDistributions } from "hooks/queries/useFetchDistributions";
import BreadcrumbsBar from "components/BreadcrumbsBar";
import api from "api/axios";
import useInvestmentsQuery from "hooks/queries/useInvestmentsQuery";
import useInvestmentsSummaryQuery from "hooks/queries/useInvestmentsSummary";
import { IInvestmentSummary } from "hooks/queries/useInvestmentSummaryQuery";
import { GridColDef, GridValueFormatterParams } from "@mui/x-data-grid";
import { format } from "date-fns";
import Table from "components/Table";
import _ from "lodash";
import PrimaryButton from "components/Buttons/PrimaryButton";

interface IMyPortfolioProps {}

var formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

type PortfolioRow = IInvestmentSummary & {
  id: string;
};

const columns: GridColDef<PortfolioRow>[] = [
  {
    field: "propertyName",
    headerName: "Deal Name",
    flex: 1,
    minWidth: 200,
    align: "left",
  },
  {
    field: "assetClass",
    headerName: "Asset Class",
    flex: 1,
    minWidth: 150,
    align: "left",
    valueFormatter: (params: GridValueFormatterParams<string>) => {
      if (params.value == null) {
        return "";
      }
      return _.startCase(params.value);
    },
  },
  {
    field: "investmentDate",
    headerName: "Investment Date",
    minWidth: 200,
    flex: 1,
    type: "date",
    align: "left",
    valueFormatter: (params: GridValueFormatterParams<number>) => {
      if (params.value == null) {
        return "";
      }
      return format(params.value, "dd MMM yyyy, hh:mm a");
    },
  },
  {
    field: "noOfTokens",
    headerName: "Tokens",
    minWidth: 100,
    flex: 1,
    align: "left",
  },
  {
    field: "investments",
    headerName: "Purchase Value",
    minWidth: 150,
    flex: 1,
    align: "left",
  },
  {
    field: "currentValue",
    headerName: "Current Value",
    minWidth: 150,
    flex: 1,
    align: "left",
  },
  {
    field: "roi",
    headerName: "ROI",
    align: "left",
    flex: 1,
    minWidth: 100,
  },
  {
    field: "actions",
    headerName: "Actions",
    align: "left",
    flex: 1,
    minWidth: 200,
    renderCell(params) {
      return (
        <PrimaryButton className="bg-white text-foreground-primary border-foreground-primary text-sm border">
          Track In Wallet
        </PrimaryButton>
      );
    },
  },
];

const DetailedPortfolio: React.FunctionComponent<IMyPortfolioProps> = () => {
  const investmentsSummaryQuery = useInvestmentsSummaryQuery();

  const rows = useMemo(() => {
    if (!investmentsSummaryQuery.data) return [];
    return investmentsSummaryQuery.data
      ?.filter((investment) => investment.investments > 0)
      .map((investment) => ({
        ...investment,
        id: investment._id,
        investments: formatter.format(investment.investments),
        investmentDate: new Date(investment.investmentDate),
        currentValue: formatter.format(investment.currentValue),
        roi:
          (
            ((investment.currentValue - investment.investments) /
              investment.investments) *
            100
          ).toLocaleString("en-US", { maximumFractionDigits: 2 }) + "%",
      }));
  }, [investmentsSummaryQuery.data]);

  return (
    <>
      <BreadcrumbsBar path={["Dashboard", "Detailed Portfolio"]} />
      <div className="mt-20 bg-white rounded-md max-w-screen-xl mx-auto py-12 sm:px-12 xs:px-6 px-4">
        <h2 className="font-medium text-black/80 mb-6 text-xl">
          Detailed Portfolio
        </h2>
        <div className="my-6">
          {/* <div className="flex flex-row flex-wrap justify-center gap-x-12 gap-y-12 max-w-full">
        {investmentsSummaryQuery.data
          ?.sort(
            (a, b) =>
              new Date(a.investmentDate).getTime() -
              new Date(b.investmentDate).getTime()
          )
          .map((investment) => (
            <PropertyCard
              propertyName={investment.propertyName[0]}
              propertyId={investment._id}
              propertyLocation={investment.seller[0].name}
              noOfTokens={investment.noOfTokens}
              purchaseValue={formatter.format(investment.investments)}
              currentValue={formatter.format(investment.currentValue)}
              roi={
                (
                  (investment.currentValue - investment.investments) /
                  100
                ).toLocaleString("en-US", { maximumFractionDigits: 2 }) + "%"
              }
              // investments={inve}
              // distributions={deal.distributions}
              // roi={investment.}
              // sellerName={investment.}
              // ctaText="Withdraw"
            />
          ))}
      </div> */}
          <Table
            rows={rows}
            columns={columns}
            // rowCount={
            //   investmentsSummaryQuery.data ? investmentsSummaryQuery.data.pages * pageSize : 0
            // }
            loading={investmentsSummaryQuery.isLoading}
            pageSize={20}
            autoHeight
            initialState={{
              sorting: {
                sortModel: [{ field: "investmentDate", sort: "desc" }],
              },
            }}
            // onRowClick={(params: GridRowParams<TransactionsRow>) => {
            //   if (params.row.status === "pending") {
            //     navigate(`/marketplace/${params.row.propertyId}/buy`);
            //   } else if (
            //     params.row.status === "pendingApproval" ||
            //     params.row.status === "success"
            //   ) {
            //     navigate(
            //       `/marketplace/${params.row.propertyId}/buy/completion/${params.row._id}`
            //     );
            //   }
            // }}
          />
        </div>
      </div>
    </>
  );
};

export default DetailedPortfolio;

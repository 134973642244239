import * as React from "react";
import PropertyCtaCard from "./PropertyCtaCard";
import PropertyCtaBar from "./PropertyCtaBar";

interface IPropertyCtaProps {
  token: any;
  roi: any;
  status?: "upcoming" | "listed";
}

const PropertyCta: React.FunctionComponent<IPropertyCtaProps> = (props) => {
  const { token, roi, status } = props;
  return (
    <>
      <div className="min-w-[400px] relative hidden xl:block">
        <PropertyCtaCard token={token} roi={roi} className="" status={status} />
      </div>
      <PropertyCtaBar className="xl:invisible" />
    </>
  );
};

export default PropertyCta;

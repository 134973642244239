import CurrencyText from "components/CurrencyText";
import { FiDollarSign } from "react-icons/fi";

interface Props {
  portfolioValue: number;
}

export const Holdings = ({ portfolioValue }: Props) => {
  return (
    <div className="py-7 px-6 md:px-12 rounded-3xl bg-background-whiteglass backdrop-blur-2xl drop-shadow-xl">
      <h1 className="text-2xl md:text-3xl font-bold text-black/80">
        My Holdings
      </h1>
      <div className="grid row-auto md:grid-cols-2 xl:grid-cols-3 gap-x-5 gap-y-6 my-7">
        <div className="rounded-2xl bg-white  md:h-[250px] grid grid-rows-2">
          <h4 className="text-lg md:text-xl p-5 md:p-9 font-medium">
            <FiDollarSign size="1.2em" className="inline" /> Current Portfolio
            Value
          </h4>
          <h1 className="text-xl flex justify-end p-5 md:p-9 bg-background-primary sm:text-2xl md:text-3xl font-bold text-black/80 rounded-b-2xl">
            <CurrencyText
              value={portfolioValue}
              currency="USD"
              minimumFractionDigits={0}
              hideCurrency={true}
            />
          </h1>
        </div>
        <div className="rounded-2xl bg-white  md:h-[250px] grid grid-rows-2">
          <h4 className="text-lg md:text-xl p-5 md:p-9 font-medium">
            <FiDollarSign size="1.2em" className="inline" /> My Dividends
          </h4>
          <h1 className="text-xl flex justify-end p-5 md:p-9 bg-background-primary sm:text-2xl md:text-3xl font-bold text-black/80 rounded-b-2xl">
            $20k
          </h1>
        </div>
      </div>
    </div>
  );
};

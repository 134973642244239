import Layout from "components/Layout";
import Dashboard from "pages/Dashboard";
import Marketplace from "pages/Marketplace";

import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Notificaions from "pages/Notifications";
import Auth from "pages/Auth";
import Account from "pages/Account";
import Home from "pages/Home";

export const PublicRoutes: React.FC = (props) => {
  return (
    <Routes>
      <Route path="*" element={<Auth />} />
    </Routes>
  );
};

export const PrivateRoutes: React.FC = (props) => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Navigate to="/home" replace />} />
        <Route path="home/*" element={<Home />} />
        <Route path="dashboard/*" element={<Dashboard />} />
        <Route path="marketplace/*" element={<Marketplace />} />
        {/* <Route path="overview/*" element={<MyOverview />} />
        <Route path="portfolio/*" element={<MyPortfolio />} />
        <Route path="transactions/*" element={<MyTransactions />} /> */}
        {/* <Route path="my-documents/*" element={<DocVault />} /> */}
        <Route path="notifications/*" element={<Notificaions />} />
        <Route path="account/*" element={<Account />} />

        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
    </Routes>
  );
};

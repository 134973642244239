import * as React from "react";
import ReactTooltip, { TooltipProps } from "react-tooltip";

interface ITooltipProps extends TooltipProps {}

const Tooltip: React.FunctionComponent<ITooltipProps> = (props) => {
  return <ReactTooltip place="top" type="dark" effect="solid" {...props} />;
};

export default Tooltip;

import useDealDetailsQuery from "hooks/queries/useDealDetailsQuery";
import React, { useMemo } from "react";
import { DivProps } from "react-html-props";
import { InViewHookResponse } from "react-intersection-observer";
import { useParams } from "react-router-dom";
import PropertySideNav from "./PropertySideNav";
import PropertyTopNav from "./PropertyTopNav";

type SectionRefData = {
  inViewHookResponse: InViewHookResponse;
  ref: React.RefObject<HTMLDivElement>;
};
export interface IPropertyDetailsNavProps extends DivProps {
  sections: {
    [sectionName in AvailableSectionNames]: SectionRefData;
  };
}

export type AvailableSectionNames =
  | "overview"
  | "keyHighlights"
  | "documents"
  | "faq";

export type ConcreteNavProps = {
  availableSections: {
    [availableSections in AvailableSectionNames]?: boolean;
  };
  activeSection?: AvailableSectionNames;
};

const PropertyDetailsNav: React.FunctionComponent<IPropertyDetailsNavProps> = (
  props
) => {
  const availableSections = useMemo(() => {
    return Object.entries(props.sections)
      .filter(([sectionName, sectionRefData]) => sectionRefData.ref.current)
      .reduce(
        (acc, [sectionName]) => ({ ...acc, [sectionName]: true }),
        {}
      ) as { [availableSection in AvailableSectionNames]?: boolean };
  }, [props.sections]);

  const activeSection = useMemo(() => {
    const activeSection = Object.entries(props.sections).find(
      ([value, sectionRefData]) => sectionRefData.inViewHookResponse.inView
    );

    return activeSection && (activeSection[0] as AvailableSectionNames);
  }, [props.sections]);

  return (
    <>
      <PropertySideNav
        className="hidden lg:block"
        {...props}
        availableSections={availableSections}
        activeSection={activeSection}
      />
      <PropertyTopNav
        className="lg:hidden"
        {...props}
        availableSections={availableSections}
        activeSection={activeSection}
      />
    </>
  );
};

export default PropertyDetailsNav;

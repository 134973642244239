import { Dialog, Transition } from "@headlessui/react";
import { useConnectWallet } from "@web3-onboard/react";
import { useWallet } from "context/Wallet";
import React, { Fragment, useEffect, useState } from "react";
import { AiOutlineCopy } from "react-icons/ai";
import SVG from "react-inlinesvg";
import { toast } from "react-toastify";
import USDCIcon from "assets/images/icons/usdc.svg";
import { BiLinkExternal } from "react-icons/bi";
import { useTokenBalanceQuery } from "hooks/queries/web3/useTokenBalanceQuery";
import { MATIC_MAINNET, MATIC_TESTNET } from "config/Chains";
import { IoClose } from "react-icons/io5";
import PrimaryButton from "components/Buttons/PrimaryButton";

interface IModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const Tokens = [
  {
    name: "USDC",
    icon: USDCIcon,
    address: "0x0fa8781a83e46826621b3bc094ea2a0212e71b23",
    link: "https://mumbai.polygonscan.com/address/0x0fa8781a83e46826621b3bc094ea2a0212e71b23",
  },
];

const WalletModal: React.FunctionComponent<IModalProps> = ({
  isOpen,
  onClose,
}) => {
  const [{ wallet }, connect] = useConnectWallet();
  const { address } = useWallet();

  return (
    <Transition appear show={isOpen} as={Fragment} unmount>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-[2px]" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all relative">
                <button
                  className="p-2 absolute top-2 right-2 rounded-full hover:bg-black/5 transition-colors"
                  onClick={onClose}
                >
                  <IoClose className="h-5 w-auto" />
                </button>
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900 mb-4"
                >
                  Wallet Info
                </Dialog.Title>
                <div className="flex flex-col w-full gap-6">
                  <div className="p-4 border border-foreground-primary/10 rounded-lg flex items-center">
                    <div
                      className="flex items-center gap-2 cursor-pointer"
                      onClick={() =>
                        address &&
                        navigator.clipboard
                          .writeText(address)
                          .then(() =>
                            toast.success("Copied address to clipboard")
                          )
                      }
                    >
                      <div className="font-mono text-black/80 pt-0.5">
                        {address?.slice(0, 8)}...{address?.slice(39)}
                      </div>
                      <AiOutlineCopy className="h-4 w-auto" />
                    </div>

                    <div className="flex items-center gap-2 ml-auto bg-white rounded-full px-6 py-1 text-black/80 shadow-sm border-black/5 border">
                      {wallet?.icon && (
                        <SVG src={wallet?.icon} className="h-8 w-auto" />
                      )}
                      {wallet?.label}
                    </div>
                  </div>
                  <div>
                    <h1 className="text-sm font-medium mb-2">Assets</h1>
                    <div className="flex flex-col">
                      {Tokens.map((token) => (
                        <TokenBalance {...token} />
                      ))}
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <PrimaryButton
                      className="bg-white border-foreground-primary border text-foreground-primary"
                      onClick={() => {
                        // @ts-ignore
                        connect();
                        onClose();
                      }}
                    >
                      Change Wallet
                    </PrimaryButton>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

interface ITokenBalanceProps {
  name: string;
  address: string;
  icon: string;
  link: string;
}

const TokenBalance: React.FunctionComponent<ITokenBalanceProps> = ({
  name,
  address: tokenAddress,
  icon,
  link,
}) => {
  const { address } = useWallet();
  const tokenBalanceQuery = useTokenBalanceQuery(
    tokenAddress,
    address,
    MATIC_TESTNET
  );

  return (
    <div className="flex p-4 bg-blue-50/50 border-blue-100 border rounded-md font-mono text-black/80">
      <div className="text-lg flex items-center gap-3">
        <img src={icon} className="h-5 w-auto" alt="" />
        <div>{name}</div>
        <a href={link} target="_blank" rel="noreferrer">
          <BiLinkExternal className="h-3 w-auto" />
        </a>
      </div>
      <div className="ml-auto text-lg">
        {tokenBalanceQuery.data ? tokenBalanceQuery.data.formatted : "..."}
      </div>
    </div>
  );
};

export default WalletModal;

import CurrencyText from "components/CurrencyText";
import React from "react";
import { BsChevronRight } from "react-icons/bs";
import { Link } from "react-router-dom";

interface Props {
  firstName: string;
  portfolioValue: number;
}

export const Intro = ({ firstName, portfolioValue }: Props) => {
  return (
    <div className="grid gap-y-5 gap-x-3 row-auto md:grid-cols-12 py-7 px-6 md:py-14 md:px-12 items-start bg-background-whiteglass backdrop-blur-2xl rounded-3xl drop-shadow-xl">
      <div className="md:col-span-6 lg:col-span-6 col-span-12">
        <h1 className="text-2xl md:text-3xl font-bold text-black/80">
          Hi, {firstName}
        </h1>
        <h6 className="text-sm text-justify mt-5 md:w-3/4">
          Hope you are doing great! Welcome to the world’s fastest, easiest real
          estate NFT & tokenization solution.
        </h6>
      </div>
      <div className="col-span-12 md:col-span-6 lg:col-span-6 flex rounded-2xl backdrop-blur-2xl bg-white drop-shadow-xl h-full">
        <div className="w-7/12 p-5 sm:py-9 sm:px-10 flex flex-col items-start justify-center">
          <h3 className="text-lg md:text-xl font-medium">
            Current Portfolio Value
          </h3>
          <Link to="/dashboard/portfolio-overview">
            <button className="text-sm font-medium text-foreground-accent flex items-center mt-2 whitespace-nowrap hover:opacity-80">
              View more{" "}
              <BsChevronRight size="1.2em" className="inline stroke-1 mx-2" />
            </button>
          </Link>
        </div>
        <div className="w-5/12 flex items-center justify-center bg-background-primary rounded-r-2xl">
          <h1 className="text-xl sm:text-2xl md:text-3xl font-bold text-black/80">
            <CurrencyText
              value={portfolioValue}
              currency="USD"
              minimumFractionDigits={0}
              hideCurrency={true}
            />
          </h1>
        </div>
      </div>
    </div>
  );
};

import PrimaryButton from "components/Buttons/PrimaryButton";
import useDealInvestFlowQuery from "hooks/queries/useDealInvestFlowQuery";
import React, { useState } from "react";
import {
  AiFillApple,
  AiFillBank,
  AiFillCreditCard,
  AiOutlineInfoCircle,
} from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Oval } from "react-loader-spinner";
import { twMerge } from "tailwind-merge";
import useInvestFiatMutation from "hooks/mutations/invest/useInvestFiatMutation";
import Web3Action from "components/Web3Action";
import Modal from "components/Modals/ConfirmationModal";
import useDealDetailsQuery from "hooks/queries/useDealDetailsQuery";
import { toast } from "react-toastify";

interface IFiatPaymentProps {}

const FiatPayment: React.FunctionComponent<IFiatPaymentProps> = (props) => {
  const { propertyId } = useParams();
  const dealInvestFlowQuery = useDealInvestFlowQuery(propertyId!);
  const dealDetailsQuery = useDealDetailsQuery(propertyId!);
  const [paymentSuccessDetails, setPaymentSuccessDetails] = useState<{
    orderId: string;
  }>();

  const investFiatMutation = useInvestFiatMutation(propertyId!);
  const navigate = useNavigate();

  const payWithWyre = async (config: {
    dest?: string;
    destCurrency: "USDC" | "ETH" | "mUSDC";
    paymentMethod?: "debit-card" | "apple-pay" | "ach-transfer";
  }) => {
    const response = await axios
      .post(
        "https://api.testwyre.com/v3/orders/reserve",
        {
          referrerAccountId: "AC_4LPCQBYBD64",
          destAmount: dealInvestFlowQuery.data?.currentValue,
          amountIncludeFees: false,
          country: "US",
          paymentMethod: "debit-card",
          sourceCurrency: "USD",
          // destCurrency: "mUSDC",
          dest: "ethereum:0x72251596E9B011838F93A9691383F3e32DC03C18",
          ...config,
        },
        {
          headers: {
            Authorization: "Bearer TEST-SK-WF63H4XP-UNF7GQUU-ARVZFHC4-BHHQHFB7",
          },
        }
      )
      .catch((e) => {
        toast.error(`Error using Wyre:\n ${e?.response?.data?.message || ""}`);
        throw e;
      });

    console.log(response);

    //@ts-ignore
    var widget = new Wyre({
      env: "test",
      reservation: response.data.reservation,
      /*A reservation id is mandatory. In order for the widget to open properly you must use a new, unexpired reservation id. Reservation ids are only valid for 15 minutes. A new reservation must also be made if a transaction fails.*/
      operation: {
        type: "debitcard-hosted-dialog",
      },
    });

    widget.on("paymentSuccess", function (e: any) {
      console.log("paymentSuccess", e);
      // @todo: total is not really total. Dest Amount has added fees, not currently being reflected

      const charges = Object.entries(JSON.parse(e.data.data.fees)).map(
        ([type, amount]) => ({ type: `wyre_${type}`, amount: amount as number })
      );

      investFiatMutation
        .mutateAsync({
          currency: config.destCurrency,
          total: e.data.data.destAmount,
          method: "direct_transfer",
          transactionId: e.data.data.orderId,
          gateway: "wyre",
          charges: charges,
          investmentId: dealInvestFlowQuery.data?._id!, // todo : error handling and null check
        })
        .then(() => {
          setPaymentSuccessDetails(e.data.data);
          navigate(`../completion/${dealInvestFlowQuery.data?._id}`);
        });
    });

    widget.open();
  };

  const paymentConfigs = {
    cc: () =>
      payWithWyre({
        dest: `matic:${dealInvestFlowQuery.data?.contractAddress}`,
        destCurrency: "mUSDC",
        paymentMethod: "debit-card",
      }),
    ach: () =>
      payWithWyre({
        dest: `matic:${dealInvestFlowQuery.data?.contractAddress}`,
        destCurrency: "mUSDC",
        paymentMethod: "ach-transfer",
      }),
    applePay: () =>
      payWithWyre({
        dest: `matic:${dealInvestFlowQuery.data?.contractAddress}`,
        destCurrency: "mUSDC",
        paymentMethod: "apple-pay",
      }),
  };

  const [isCcModalOpen, setIsCcModalOpen] = useState<
    keyof typeof paymentConfigs | false
  >(false);

  return (
    <div className="py-6 flex flex-col gap-4">
      <div
        className={twMerge(
          "flex rounded-md border border-black/10 p-6 gap-4 mt-4 text-sm",
          paymentSuccessDetails && "bg-green-100 border-green-300"
        )}
      >
        <div className="pt-0.5">
          <AiOutlineInfoCircle className="w-4 h-auto" />
        </div>
        {paymentSuccessDetails ? (
          <>
            {!paymentSuccessDetails ? (
              <div className="flex items-center justify-center gap-2">
                <Oval
                  ariaLabel="loading-indicator"
                  height={20}
                  width={20}
                  strokeWidth={10}
                  strokeWidthSecondary={0}
                  color="#00000050"
                  secondaryColor="#00000080"
                />
                Waiting for transaction to complete
              </div>
            ) : (
              <div className="flex-col flex gap-4">
                <p className="font-medium">
                  Congratulations! Your transaction was successful.
                </p>
                <p>
                  Your token will be deposited to your wallet within 24 hours.
                  We will notify you to let you know when this happens.
                </p>
                <p>
                  Your order ID is:{" "}
                  <span className="font-mono font-semibold">
                    {paymentSuccessDetails.orderId}
                  </span>
                </p>
              </div>
            )}
          </>
        ) : (
          <div className="flex-col flex gap-4 w-full">
            <p className="font-medium">
              You can pay for this transaction directly through fiat
            </p>
            <p>Select from one of the options below</p>
            <Web3Action className="my-8 justify-self-center mx-auto px-6">
              <div className="p-8 my-4 rounded-md border border-black/10 w-full flex flex-col gap-6">
                <h1 className="font">Pay With Wyre</h1>
                <div className="flex flex-wrap gap-4 whitespace-nowrap">
                  <PrimaryButton
                    onClick={() => setIsCcModalOpen("applePay")}
                    className="flex items-center gap-2 text-black/80 bg-white border border-black/80"
                  >
                    <AiFillApple className="h-4 w-auto" />
                    Apple Pay
                  </PrimaryButton>
                  <PrimaryButton
                    className="flex items-center gap-2 text-black/80 bg-white border border-black/80"
                    onClick={() => setIsCcModalOpen("cc")}
                  >
                    <AiFillCreditCard />
                    Credit Card
                  </PrimaryButton>
                  <PrimaryButton
                    className="flex items-center gap-2 text-black/80 bg-white border border-black/80"
                    onClick={() => setIsCcModalOpen("ach")}
                  >
                    <AiFillBank />
                    ACH
                  </PrimaryButton>
                </div>
              </div>
            </Web3Action>
          </div>
        )}
      </div>

      <Modal
        isOpen={!!isCcModalOpen}
        onClose={() => setIsCcModalOpen(false)}
        title="Complete Payment on Wyre Popup"
        onContinue={() =>
          !!isCcModalOpen &&
          paymentConfigs[isCcModalOpen]().finally(() => {
            setIsCcModalOpen(false);
          })
        }
        buttonBody="Confirm"
        body={
          <div className="py-4">
            You'll be redirected to our payment gateway Wyre, to purchase{" "}
            <span className="font-medium text-foreground-primary">
              {dealInvestFlowQuery.data?.noOfTokens}{" "}
              {dealDetailsQuery.data?.token.name} (
              {dealDetailsQuery.data?.token.symbol}) token
              {(dealInvestFlowQuery.data?.noOfTokens ?? 0) > 1 ? "s" : ""}.
            </span>
            <br />
            Subtotal amount is{" "}
            <span className="font-medium text-foreground-primary">
              ${dealInvestFlowQuery.data?.currentValue} USD.
            </span>
          </div>
        }
      ></Modal>
    </div>
  );
};

export default FiatPayment;

import PrimaryButton from "components/Buttons/PrimaryButton";
import { useState } from "react";
import { Header } from "../../components";
import logo from "assets/images/logos/logo.jpg";
import PasswordInput from "components/Input/PasswordInput";

export const ResetForm = () => {
  const [password, setPassword] = useState("");
  const isValid: () => boolean = () => {
    if (password.length < 4) {
      return false;
    }
    return true;
  };

  const submitHandler = () => {};
  return (
    <div className="relative col-span-12 sm:col-span-6 lg:col-span-5 overflow-y-auto">
      <div className="w-full flex flex-col p-8 md:px-12 lg:px-20 ">
        <Header title="Reset Your Password" subTitle="" />
        <div className="my-2">
          <label className="block text-black/80 font-semibold">
            New Password
          </label>
          <PasswordInput
            placeholder="jhon.doe@realto.com"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <PrimaryButton
          type="submit"
          disabled={!isValid()}
          className="disabled:bg-opacity-70"
          onClick={submitHandler}
        >
          Reset Password
        </PrimaryButton>
      </div>
      <a
        className="flex justify-center w-full py-2 absolute bottom-0 "
        href="https://realtoapps.com/"
        target="_blank"
        rel="noreferrer"
      >
        <span className="hover:underline">Technology Powered By</span>
        <img src={logo} alt="logo" className="shadow rounded-lg h-8" />
        <span className="font-bold">REALTO</span>
      </a>
    </div>
  );
};

import PrimaryButton from "components/Buttons/PrimaryButton";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { AiOutlineUpload } from "react-icons/ai";
import { useProfilePicMutation } from "../../../../../hooks/mutations/account/useProfileSettings";
import { ProfileForm } from "..";
import {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";

import { FileUploader } from "react-drag-drop-files";
import { profile } from "console";

const fileTypes = ["JPG", "PNG", "GIF"];

interface IChangeProfilePic {
  register: UseFormRegister<ProfileForm>;
  errors: any;
  picUrl: string;
  setValue: UseFormSetValue<ProfileForm>;
  getValues: UseFormGetValues<ProfileForm>;
}

export const ChangeProfilePic: React.FunctionComponent<IChangeProfilePic> = ({
  register,
  errors,
  setValue,
  getValues,
  picUrl,
}) => {
  const values = getValues();
  const profilePicMutation = useProfilePicMutation();
  const [file, setFile] = useState<File | null>(null);
  const fileRef = useRef<HTMLInputElement>(null);

  const handleChange = (file: File) => {
    console.log(file);
    if (file) {
      //@ts-ignore
      profilePicMutation.mutateAsync(file).then((url) => {
        setValue("profileUrl", url);
      });
    }
  };

  return (
    <FileUploader handleChange={handleChange} name="file" types={fileTypes}>
      <div className="flex flex-col rounded-md overflow-hidden border-black/10 bg-foreground-primary/[0.02] border-2 border-dashed p-6 mt-8">
        <div className="relative flex-1 flex-shrink-0 flex items-center justify-center">
          {values.profileUrl ? (
            <div
              className="w-44 h-44 overflow-hidden rounded-full"
              style={{
                backgroundImage: `url('${values.profileUrl}')`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></div>
          ) : (
            <div className="w-44 h-44 overflow-hidden rounded-full bg-black/10 items-center flex justify-center text-center font-xl text-black/40 font-mono uppercase px-6">
              No Image Selected
            </div>
          )}
        </div>
        <div className="flex-1 p-4">
          <div className="text-black/40 text-sm pt-4 text-center">
            Click here to choose a new image. You can also drag and drop images
            here.
          </div>
          {profilePicMutation.isLoading && <div>Uploading new file...</div>}
        </div>
      </div>
    </FileUploader>
  );
};

import PrimaryButton from "components/Buttons/PrimaryButton";
import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import PropertyDetailsBottomBar from "../../components/PropertyDetailsBottomBar";
import PropertyInvestBreadcrumbsBar from "../../components/PropertyInvestBreadcrumbsBar";
import Stepper from "../../components/Stepper";
import PropertyDetailsCard from "../../components/PropertyDetailsCard";
import useDealInvestFlowQuery from "hooks/queries/useDealInvestFlowQuery";
import useDealDetailsQuery from "hooks/queries/useDealDetailsQuery";
import useInvestSetDisclosuresTermsAgreementMutation from "hooks/mutations/invest/useInvestSetDisclosuresTermsAgreementMutation ";

interface IDisclosuresProps {}

const Disclosures: React.FunctionComponent<IDisclosuresProps> = (props) => {
  const { propertyId } = useParams();
  const investSetDisclosuresTermsAgreementMutation =
    useInvestSetDisclosuresTermsAgreementMutation(propertyId!);
  const dealInvestFlowQuery = useDealInvestFlowQuery(propertyId!);
  const dealDetailsQuery = useDealDetailsQuery(propertyId!);
  const navigate = useNavigate();

  const [termsCheckboxState, setTermsCheckboxState] = useState<boolean[]>([]);

  const isLocalTermsAccepted = useMemo(() => {
    return termsCheckboxState.every((term) => term);
  }, [termsCheckboxState]);

  useEffect(() => {
    if (
      dealDetailsQuery.data?.terms &&
      dealDetailsQuery.data?.terms.length > 0 &&
      dealInvestFlowQuery.data
    ) {
      setTermsCheckboxState(
        new Array(dealDetailsQuery.data.terms.length).fill(
          dealInvestFlowQuery.data?.disclosuresAndTerms.accepted || false
        )
      );
    }
  }, [dealDetailsQuery.data, dealInvestFlowQuery.data]);

  const onNextStep = async () => {
    // if not already accepted or state change then mutate server state
    if (
      !dealDetailsQuery.data?.disclosuresAndTerms.id ||
      !dealInvestFlowQuery.data?.quantity
    )
      return;

    if (
      !dealInvestFlowQuery.data?.disclosuresAndTerms?.accepted ||
      isLocalTermsAccepted !==
        dealInvestFlowQuery.data?.disclosuresAndTerms?.accepted
    ) {
      await investSetDisclosuresTermsAgreementMutation.mutateAsync({
        accepted: isLocalTermsAccepted,
        disclosureId: dealDetailsQuery.data?.disclosuresAndTerms.id,
        propertyId: propertyId!,
        step: 2,
        quantity: dealInvestFlowQuery.data?.quantity,
      });
    }
    navigate("../documents");
  };

  return (
    <>
      <PropertyInvestBreadcrumbsBar />
      <div className="flex flex-col flex-grow py-4 gap-8 pt-20 pb-20 lg:pb-4 sm:px-4 md:px-8">
        <Stepper
          steps={["Select Tokens", "Disclosures", "Documents", "Checkout"]}
          activeStep={1}
        />
        <div className="flex gap-8">
          <div className="p-8 bg-white rounded-md flex-grow flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <h1 className="text-xl font-semibold">Terms and Conditions</h1>
              <p className="text-sm text-black/80">
                Please read the documents carefully, You may need to sign the
                documents digitally to proceed futher.
              </p>
            </div>
            {dealDetailsQuery.data?.disclosure &&
              dealDetailsQuery.data?.disclosure.length > 0 &&
              dealDetailsQuery.data?.disclosure.map((disclosure) => (
                <>
                  <div className="max-h-80 overflow-x-auto p-8 rounded-md border-black/20 border">
                    <article className="prose">
                      <h4>{disclosure.title}</h4>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: disclosure.description || "",
                        }}
                      ></div>
                    </article>
                  </div>
                </>
              ))}
            <div className="flex flex-col gap-2 py-8">
              <div className="flex items-center gap-6 p-4">
                <input
                  type="checkbox"
                  checked={isLocalTermsAccepted}
                  onChange={() => {
                    setTermsCheckboxState((state) => {
                      const newState = new Array(state.length).fill(true);
                      return newState;
                    });
                  }}
                />
                <div className="font-semibold">Accept All</div>
              </div>
              {dealDetailsQuery.data?.terms &&
                dealDetailsQuery.data?.terms.length > 0 &&
                dealDetailsQuery.data?.terms.map((term, i) => (
                  <div
                    className="flex items-center gap-6 hover:bg-black/5 rounded-sm transition-colors p-4 cursor-pointer"
                    onClick={() => {
                      setTermsCheckboxState((state) => {
                        const newState = [...state];
                        newState[i] = !newState[i];

                        return newState;
                      });
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={termsCheckboxState[i]}
                      onChange={() => {
                        // setTermsCheckboxState((state) => {
                        //   const newState = [...state];
                        //   newState[i] = !newState[i];
                        //   return newState;
                        // });
                      }}
                    />
                    <div>{term}</div>
                  </div>
                ))}
            </div>
            <div className="flex gap-4">
              <Link to="../select-tokens">
                <PrimaryButton className="bg-black/10 text-black/40 px-8">
                  Back
                </PrimaryButton>
              </Link>
              {/* <Link to="../documents"> */}
              <PrimaryButton
                className="px-8"
                disabled={
                  !isLocalTermsAccepted ||
                  investSetDisclosuresTermsAgreementMutation.isLoading
                }
                onClick={onNextStep}
              >
                {investSetDisclosuresTermsAgreementMutation.isLoading ? (
                  "Loading..."
                ) : (
                  <>
                    {isLocalTermsAccepted
                      ? "Continue"
                      : "Agree to Terms to Continue"}
                  </>
                )}
              </PrimaryButton>
              {/* </Link> */}
            </div>
          </div>
          <PropertyDetailsCard className="hidden lg:flex" />
        </div>
      </div>
      <PropertyDetailsBottomBar moreDetails={<PropertyDetailsCard />} />
    </>
  );
};

export default Disclosures;

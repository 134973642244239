import FormTextInput from "components/Input/FormTextInput";
import ErrorText from "components/Typography/ErrorText";
import { NONAME } from "dns";
import { useState } from "react";
import {
  Control,
  Controller,
  UseFormRegister,
  UseFormSetValue,
  UseFormGetFieldState,
  UseFormGetValues,
} from "react-hook-form";
import { FaPencilAlt } from "react-icons/fa";
import PhoneInput from "react-phone-input-2";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { ProfileForm } from "..";
import { ChangeProfilePic } from "./ChangePorfilePic";

interface IAccountForm {
  register: UseFormRegister<ProfileForm>;
  errors: any;
  control: Control<ProfileForm>;
  setValue: UseFormSetValue<ProfileForm>;
  getValues: UseFormGetValues<ProfileForm>;
}

export const AccountForm: React.FunctionComponent<IAccountForm> = ({
  register,
  errors,
  setValue,
  getValues,
  control,
}) => {
  const [isEditable, setIsEditable] = useState<boolean>(true);

  return (
    <div className="block px-12 py-8 bg-white rounded-md" id="account">
      <div className="font-bold text-black/80 text-2xl w-full relative py-2 mb-4">
        <h1>Account Info</h1>
        {!isEditable && (
          <FaPencilAlt
            className="absolute right-8 top-2 hover:opacity-70 cursor-pointer"
            onClick={() => {
              setIsEditable(true);
            }}
          />
        )}
      </div>

      <div className="grid gap-6 grid-rows-2 md:grid-cols-12 md:grid-rows-none pt-2">
        <div className=" md:col-span-6 flex flex-col gap-5 flex-1">
          <div>
            <FormTextInput
              label="First Name"
              labelClassname="font-semibold text-lg text-black/80"
              disabled={!isEditable}
              register={() => register("firstname")}
              error={errors.firstname && "First name is required"}
              placeholder="Your FirstName"
              className=" disabled:bg-opacity-80 disabled:text-red text-sm"
            />
          </div>
          <div>
            <FormTextInput
              labelClassname="font-semibold text-lg text-black/80"
              label="Last Name"
              disabled={!isEditable}
              register={() => register("lastname")}
              error={errors.lastname && "Last name is required"}
              placeholder="Your LastName"
              className="text-sm"
            />
          </div>
          <div>
            <FormTextInput
              labelClassname="font-semibold text-lg text-black/80"
              label="Email"
              disabled
              register={() => register("email")}
              error={errors.email && errors.email.message}
              placeholder="Enter Your Email"
              className="text-sm"
            />
          </div>

          <div>
            <label className="block font-semibold text-lg text-black/80 pb-1">
              Mobile Number
            </label>
            <Controller
              render={({ field }) => (
                <PhoneInput
                  disabled={!isEditable}
                  country={"us"}
                  inputProps={{
                    name: "contact",
                    required: true,
                  }}
                  inputStyle={{
                    border: "none",
                    width: "100%",
                    backgroundColor: "#00000000",
                  }}
                  containerClass={twMerge(
                    "w-full mx-auto rounded-lg border-black/10 border-2 px-3 py-1.5 focus:border-blue-600/20 focus:outline-none",
                    !isEditable && "bg-black/[0.025]",
                    errors.codePrefixedContact && "border-red-300"
                  )}
                  buttonStyle={{
                    padding: 0,
                    border: "none",
                    backgroundColor: "transparent",
                  }}
                  //@ts-ignore
                  value={field.value}
                  onChange={(value, country, e) => {
                    if ("dialCode" in country) {
                      console.log(value, country);
                      field.onChange(value);
                      setValue("countryCode", `+${country.dialCode}`, {
                        shouldValidate: true,
                      });
                      setValue("codePrefixedContact", `+${value}`, {
                        shouldValidate: true,
                      });
                    }
                  }}
                />
              )}
              name={"codePrefixedContact"}
              control={control}
            />
            <ErrorText>
              {errors.codePrefixedContact &&
                "Mobile number must be 10 digits long"}
            </ErrorText>
          </div>
        </div>
        <div className="md:col-span-6 flex-1 flex flex-col items-center">
          <ChangeProfilePic
            register={register}
            picUrl={control?._defaultValues?.profileUrl || ""}
            setValue={setValue}
            getValues={getValues}
            errors={errors}
          />
          <Link to="/account/password">
            <button className="text-sm font-bold mt-4 text-foreground-primary">
              Manage Password
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

import { useSetChain } from "@web3-onboard/react";
import PrimaryButton from "components/Buttons/PrimaryButton";
import Web3Action from "components/Web3Action";
import Chains, { MATIC_TESTNET } from "config/Chains";
import React, { useEffect } from "react";
import { act } from "react-dom/test-utils";
import Step, { StepStatus } from "../components/Step";
import { IStepProps } from "./step.type";

const SwitchNetworkStep: React.FunctionComponent<IStepProps> = ({
  activeStepNumber,
  stepNumber,
  onNextStep,
}) => {
  const [
    {
      chains, // the list of chains that web3-onboard was initialized with
      connectedChain, // the current chain the user's wallet is connected to
      settingChain, // boolean indicating if the chain is in the process of being set
    },
    setChain, // function to call to initiate user to switch chains in their wallet
  ] = useSetChain();

  console.log(chains, connectedChain, settingChain);

  useEffect(() => {
    if (
      connectedChain &&
      connectedChain.id === MATIC_TESTNET.id &&
      stepNumber === activeStepNumber
    ) {
      onNextStep();
    }
  }, [stepNumber, connectedChain, activeStepNumber, onNextStep]);

  return (
    <Step
      title="Switch Network"
      stepNumber={stepNumber}
      activeStepNumber={activeStepNumber}
    >
      <p>Please switch to Polygon network</p>
      <p>
        In order to proceed in buying tokens we will need to switch your wallet
        to the polygon network. The polygon network is cheaper and faster than
        the ethereum network.
      </p>
      <p>
        Click the button below to automatically switch to the polygon network.
      </p>
      <div className="pt-4">
        <Web3Action chainId={MATIC_TESTNET.id} />
      </div>
    </Step>
  );
};

export default SwitchNetworkStep;

import React from "react";
import { LightningIcon } from "../../LightningIcon";

export const WhatIsNew: React.FunctionComponent = () => {
  return (
    <div className="flex flex-col md:flex-row items-center py-6 px-5 bg-background-whiteglass backdrop-blur-3xl drop-shadow-xl rounded-2xl h-max">
      <LightningIcon />
      <div className="ml-1">
        <h1 className="text-sm font-bold">What's new?</h1>
        <h6 className="text-sm">
          Hey! Have a look at our latest deals in Canada, you might find them
          interesting.
        </h6>
      </div>
    </div>
  );
};

import useCurrentTenantQuery from "hooks/queries/useCurrentTenantQuery";
import { Link } from "react-router-dom";

const CopyrightBanner = () => {
  const currentTenantQuery = useCurrentTenantQuery();
  return (
    <div className="flex flex-col gap-2 sm:flex-row items-center justify-center text-sm p-7 border-t border-white/10 text-white bg-foreground-primary">
      All Rights Reserved{" "}
      <span className="w-max flex ">
        <Link to="/home">
          <img
            src={currentTenantQuery.data?.logo.dark}
            className="max-w-[150px] h-auto"
            alt="logo"
          />
        </Link>
      </span>
      2022
    </div>
  );
};

export default CopyrightBanner;

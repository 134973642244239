import { useMutation, useQueryClient } from "react-query";

const useLogoutMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(async () => {
    queryClient.setQueryData("user", undefined);
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
  });
};

export default useLogoutMutation;

import * as React from "react";
import TenantLogo from "assets/images/logos/tenant-logo.svg";
import { AiOutlineHome, AiOutlineSearch } from "react-icons/ai";
import { FiBell } from "react-icons/fi";
import { IoChevronDownCircleOutline, IoWalletOutline } from "react-icons/io5";
import NavbarButton from "./NavbarButton";
import { useConnectWallet, useSetChain } from "@web3-onboard/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useWallet } from "context/Wallet";
import { BiMenu } from "react-icons/bi";
import Dropdown from "components/Dropdown";
import useLogoutMutation from "hooks/mutations/auth/useLogoutMutation";
import { DivProps } from "react-html-props";
import { twMerge } from "tailwind-merge";
import WalletButton from "./WalletButton";
import NavbarLink from "./NavbarLink";
import { ReactComponent as Chevron } from "assets/images/logos/icon.svg";
import { NotificationDropDown } from "./NavNotification";
import useCurrentTenantQuery from "hooks/queries/useCurrentTenantQuery";
import useUserQuery from "hooks/queries/useUserQuery";
import Menu, { MenuLink } from "components/Menu";
import { divide } from "lodash";
import ProfileButton from "./ProfileButton";

interface INavbarProps extends DivProps {}

const LargeNavbar: React.FunctionComponent<INavbarProps> = (props) => {
  const [
    {
      wallet, // the wallet that has been connected or null if not yet connected
      connecting, // boolean indicating if connection is in progress
    },
    connect, // function to call to initiate user to connect wallet
    disconnect, // function to call to with wallet<DisconnectOptions> to disconnect wallet
  ] = useConnectWallet();

  const { onboard } = useWallet();
  const navigate = useNavigate();
  const userQuery = useUserQuery();

  const currentTenantQuery = useCurrentTenantQuery();

  const [{ chains, connectedChain, settingChain }, setChain] = useSetChain();
  const logoutMutation = useLogoutMutation();
  const location = useLocation();
  return (
    <div className="flex flex-shrink-0 h-14 bg-foreground-primary px-8 fixed inset-x-0 top-0 items-stretch z-50">
      <div className="w-64 flex items-center">
        <Link to="/home">
          <img
            src={currentTenantQuery.data?.logo.dark}
            className="max-w-[160px] h-auto"
          />
        </Link>
      </div>
      <div className="flex items-center gap-2 border-l-2 border-white/10 pl-4">
        {/* <Link to="/">
          <NavbarButton>
            <AiOutlineHome className="h-5 w-auto" />
          </NavbarButton>
        </Link> */}
        <NavbarLink to="/marketplace">Marketplace</NavbarLink>

        <Menu
          menuClassName="mt-2.5"
          button={
            <div className="flex items-center py-2 gap-2 px-3">
              <div className="text-white text-sm">Dashboard</div>
              <div>
                <Chevron width="12px" height="12px" />
              </div>
            </div>
          }
          items={[
            <>
              <MenuLink to="/dashboard/portfolio-overview">
                Portfolio Overview
              </MenuLink>
            </>,
            <>
              <MenuLink to="/dashboard/portfolio-detailed">
                Detailed Portfolio
              </MenuLink>
            </>,
            <>
              <MenuLink to="/dashboard/transactions">My Transactions</MenuLink>
            </>,
            <MenuLink to="/dashboard/my-documents">Doc Vault</MenuLink>,
          ]}
        />
      </div>
      <div className="flex items-center justify-end flex-grow gap-6">
        <div className="w-72 bg-white/20 rounded-md flex">
          <AiOutlineSearch className="h-auto flex-shrink-0 w-5 text-white ml-2" />
          <input
            type="text"
            placeholder="Search powered by Mia"
            className="bg-transparent py-1 outline-none px-6 text-white"
          />
        </div>

        <NotificationDropDown />

        <WalletButton />

        <ProfileButton />
      </div>
    </div>
  );
};

export default LargeNavbar;

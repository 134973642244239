import axios from "axios";
import { useQuery } from "react-query";

type location = {
  countryCode?: string;
  postCode?: string;
};

export default function useLocationDetailQuery({
  postCode,
  countryCode,
}: location) {
  return useQuery(
    ["locationDetails", countryCode, postCode],
    async () => {
      const locationDetails = await axios.get(
        `https://api.worldpostallocations.com/pincode/${postCode}/${countryCode}`
      );
      console.log(locationDetails.data);

      return locationDetails.data;
    },
    {
      staleTime: Infinity,
      enabled: !!postCode && !!countryCode,
    }
  );
}

import api from "api/axios";
import { useMutation, useQueryClient } from "react-query";
import _ from "lodash";

export interface IProfile {
  email: string;
  firstname: string;
  lastname: string;
  countryCode: string;
  contact: number;
  address: {
    addressLine: string;
    locality: string;
    city: string;
    state: string;
    country: string;
    postCode: number;
    type: string;
  };
  additionalAddress: [
    {
      addressLine: string;
      locality: string;
      city: string;
      state: string;
      country: string;
      postCode: number;
      type: string;
    }
  ];
  social: {
    linkedin: string;
    twitter: string;
    instagram: string;
    facebook: string;
    tiktok: string;
  };
  // role: ["buyer"];
  profileUrl: string;
}

export const useProfileMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (profileData: IProfile) => {
      //@ts-ignore
      const slimProfileData = _.pick(profileData, [
        "firstname",
        "lastname",
        "additionalAddress",
        "address",
        "contact",
        "countryCode",
        "email",
        "profileUrl",
        "social",
        "role",
      ]);
      const profileResponse = await api.put("/users", slimProfileData);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("user");
      },
    }
  );
};

export const useProfilePicMutation = () => {
  return useMutation(async (file: File) => {
    var formData = new FormData();
    formData.append("file", file);
    const fileUploadResponse = await api.post("/image/upload", formData);
    return fileUploadResponse.data.url as string;
  });
};

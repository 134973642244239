import * as React from "react";
import Navbar from "./Navbar";
import { Outlet } from "react-router-dom";
import CopyrightBanner from "./Footer/CopyrightBanner";

const Layout: React.FunctionComponent = () => {
  return (
    <div className="flex flex-col flex-grow max-h-full max-w-full min-h-0 bg-background-primary">
      <Navbar />
      <div className="flex flex-grow max-h-full min-h-0 relative pt-14 max-w-full overflow-x-hidden">
        <div className="min-h-0 max-h-full max-w-full overflow-y-auto flex-grow flex flex-col">
          <div>
            <Outlet />
          </div>
          <div className="mt-auto pt-8">
            <CopyrightBanner />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;

import { Transition } from "@headlessui/react";
import PrimaryButton from "components/Buttons/PrimaryButton";
import React, { useState } from "react";
import { DivProps } from "react-html-props";
import { twMerge } from "tailwind-merge";

interface IPropertyDetailsBottomBarProps extends DivProps {
  moreDetails: React.ReactNode;
}

const PropertyDetailsBottomBar: React.FunctionComponent<
  IPropertyDetailsBottomBarProps
> = ({ moreDetails }) => {
  const [isMoreDetailsVisible, setIsmoreDetailsVisible] = useState(false);

  return (
    <>
      <div className="lg:hidden px-4 fixed bottom-0 gap-3 inset-x-0 py-3 h-auto bg-foreground-primary z-10 flex flex-col text-white justify-evenly xs:flex-row xs:items-center xs:justify-between">
        <div className="flex justify-evenly xs:gap-4 sm:gap-8">
          <div className="flex flex-col text-center items-center">
            <div>12%</div>
            <div className="text-xs font-medium text-white/50">
              Targetted IRR
            </div>
          </div>
          <div className="flex flex-col text-center items-center">
            <div>9.8%</div>
            <div className="text-xs font-medium text-white/50">
              Average Cash Yield
            </div>
          </div>
        </div>
        <PrimaryButton
          className="bg-transparent border-white/20 border"
          onClick={() => setIsmoreDetailsVisible((visible) => !visible)}
        >
          {isMoreDetailsVisible ? "Hide Details" : "View Details"}
        </PrimaryButton>
      </div>

      <Transition show={isMoreDetailsVisible}>
        <Transition.Child
          enter="transition-opacity duration-150"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className="fixed inset-0 flex items-center justify-center bg-black/50 backdrop-blur-sm"
        />
        <Transition.Child
          enter="transition-opacity duration-150"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition transform duration-200"
          leaveFrom="translate-y-0"
          leaveTo="translate-y-full"
          className="fixed inset-0 flex items-center justify-center transform-gpu"
          unmount={false}
        >
          {moreDetails}
        </Transition.Child>
      </Transition>
    </>
  );
};

export default PropertyDetailsBottomBar;

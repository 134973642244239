import { yupResolver } from "@hookform/resolvers/yup";
import PrimaryButton from "components/Buttons/PrimaryButton";
import { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import * as Yup from "yup";
import { AccountForm } from "./components/AccountForm";
import { ContactForm } from "./components/ContactForm";
import { SocialForm } from "./components/SocialForm";
import {
  IProfile,
  useProfileMutation,
} from "../../../../hooks/mutations/account/useProfileSettings";
import useUserQuery from "../../../../hooks/queries/useUserQuery";
import BreadcrumbsBar from "components/BreadcrumbsBar";
import { request } from "http";
import _ from "lodash";
import { CountryData } from "country-region-data";
import { compactObject } from "utils/compactObject";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

export type ProfileForm = Omit<IProfile, "contact"> & {
  codePrefixedContact: string;
};

function formDataToProfileData(formData: ProfileForm): IProfile {
  const contact = formData.codePrefixedContact.slice(
    formData.countryCode.length
  );
  const output: Partial<ProfileForm> & IProfile = {
    ...formData,
    contact: Number(contact),
  };

  delete output.codePrefixedContact;
  return output;
}

function profileDataToFormData(profileData: IProfile): ProfileForm {
  return {
    ...profileData,
    codePrefixedContact:
      profileData?.countryCode && profileData?.contact
        ? `${profileData.countryCode}${profileData.contact}`
        : "",
  };
}

const validationSchema = Yup.object().shape({
  firstname: Yup.string().required(),
  lastname: Yup.string().required(),
  codePrefixedContact: Yup.string().min(12),
  // state: Yup.string().required(),
  // country: Yup.string().required(),
  // city: Yup.string().required(),
  // postCode: Yup.string().required(),
  // addressLine1: Yup.string().required(),
  // addressLine2: Yup.string().required(),
  // additionalAddress:Yup.object().shape({
  //     state: Yup.string().required(),
  //     country: Yup.string().required(),
  //     city: Yup.string().required(),
  //     postCode: Yup.string().required(),
  //     addressLine1: Yup.string().required(),
  //     addressLine2: Yup.string().required(),
  // })
  // social:Yup.object().shape({
  // })
});

const Profile: React.FunctionComponent = () => {
  const { data: userQueryData } = useUserQuery();

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState,
    setValue,
    getValues,
    reset,
  } = useForm<ProfileForm>({
    //@ts-ignore
    defaultValues: profileDataToFormData(userQueryData),
    resolver: yupResolver(validationSchema),
    mode: "all",
  });
  const { errors } = formState;
  const profileMutation = useProfileMutation();

  const location = useLocation();
  const card = location.hash;

  const onSubmitHandler = (data: ProfileForm) => {
    console.log(data);
    profileMutation
      .mutateAsync(formDataToProfileData(data))
      .then(() => {
        toast.success("Profile successfully updated");
      })
      .catch((e) => {
        toast.error("Error Saving Profile, fields cannot be empty");
        console.log(e);
      });
  };

  const values = watch();
  // const [card, setCard] = useState<string>("account");
  // const handleCard = (cardName: string): void => {
  //   setCard(cardName);
  // };

  useEffect(() => {
    userQueryData && reset(profileDataToFormData(userQueryData));
  }, [userQueryData, reset]);

  return (
    <div className="flex flex-col">
      <BreadcrumbsBar
        path={["Profile Settings", "My Profile"]}
        className="z-10"
        rightArea={
          !_.isEqual(
            compactObject(userQueryData),
            compactObject(formDataToProfileData(values))
          ) ? (
            <div className="flex gap-4 items-center">
              You have unsaved changes
              <PrimaryButton
                className="text-black/80 border bg-transparent border-foreground-primary/50"
                onClick={() => reset()}
              >
                Cancel
              </PrimaryButton>
              <PrimaryButton onClick={handleSubmit(onSubmitHandler)}>
                {profileMutation.isLoading
                  ? "Saving Changes..."
                  : "Save Changes"}
              </PrimaryButton>
            </div>
          ) : (
            <></>
          )
        }
      />
      {/* <div className="flex justify-evenly md:justify-start md:space-x-5 mb-5 mt-20 px-2 md:px-10 lg:px-0">
        <button
          onClick={() => handleCard("account")}
          className={`text-sm rounded-lg border  py-3 px-4 ${
            card === "account"
              ? "bg-foreground-primary hover:opacity-90 text-white"
              : " bg-white  hover:bg-foreground-primary/10 text-gray-700 border-gray-500  "
          }`}
        >
          Account Info
        </button>
        <button
          onClick={() => handleCard("contact")}
          className={`text-sm rounded-lg border  py-3 px-4 ${
            card === "contact"
              ? "bg-foreground-primary hover:opacity-90  text-white"
              : " bg-white text-gray-700 hover:bg-foreground-primary/10 border-gray-500  "
          }`}
        >
          Contact Info
        </button>
        <button
          onClick={() => handleCard("social")}
          className={`text-sm rounded-lg border py-3 px-4 ${
            card === "social"
              ? "bg-foreground-primary hover:opacity-90 text-white"
              : " bg-white text-gray-700  hover:bg-foreground-primary/10 border-gray-500  "
          }`}
        >
          Social Accounts
        </button>
      </div> */}
      <form onSubmit={handleSubmit(onSubmitHandler)} className="mt-20">
        <div
          className={`${
            card === "#account" || card === "" ? "block" : "hidden"
          }`}
        >
          <AccountForm
            register={register}
            errors={errors}
            setValue={setValue}
            control={control}
            getValues={getValues}
          />
        </div>
        <div className={`${card === "#contact" ? "block" : "hidden"}`}>
          <ContactForm
            register={register}
            errors={errors}
            setValue={setValue}
            getValues={getValues}
            control={control}
          />
        </div>
        <div className={`${card === "#social" ? "block" : "hidden"}`}>
          <SocialForm
            register={register}
            errors={errors}
            setValue={setValue}
            control={control}
          />
        </div>
      </form>
    </div>
  );
};

export default Profile;

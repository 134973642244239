import api from "api/axios";
import { useMutation, useQueryClient } from "react-query";

type LoginResponse = {
  accessToken: string;
};

interface IGoogleAuth {token:any}

const useGoogleAuthMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({token}: IGoogleAuth) => {
      const loginResponse = await api.post("/auth/method=google",  { token } );
      const { accessToken } = loginResponse.data;

      if (!accessToken) {
        throw new Error("Login failed");
      }
      return loginResponse.data as LoginResponse;
    },
    {
      onSuccess: (loginResponse) => {
        localStorage.setItem("access_token", loginResponse.accessToken);
        // localStorage.setItem("refresh_token", refresh_token);
        return queryClient.invalidateQueries("user");
      },
    }
  );
};

export default useGoogleAuthMutation;

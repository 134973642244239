import * as React from "react";
import { BsCheckCircle } from "react-icons/bs";
import { TbCircle } from "react-icons/tb";
import { twMerge } from "tailwind-merge";

interface IStepperProps {
  steps: string[];
  activeStep: number;
}

const Stepper: React.FunctionComponent<IStepperProps> = ({
  steps,
  activeStep,
}) => {
  return (
    <div className="bg-white py-4 px-8 rounded-md flex gap-2 text-sm text-black/80 items-center whitespace-nowrap overflow-x-auto">
      {steps.map((step, index) => (
        <>
          <div
            className={twMerge(
              "flex gap-2 items-center",
              index > activeStep && "opacity-50",
              index === activeStep && "text-foreground-primary font-medium"
            )}
          >
            {index < activeStep ? (
              <BsCheckCircle className="h-5 w-auto" />
            ) : (
              <TbCircle className="h-5 w-auto" />
            )}
            <div>{step}</div>
          </div>
          {index !== steps.length - 1 && (
            <div className="h-[2px] rounded-full bg-black/20 w-8 flex-shrink-0" />
          )}
        </>
      ))}
    </div>
  );
};

export default Stepper;

import { useQuery } from "react-query";
import { ethers } from "ethers";
import { Chain } from "@web3-onboard/common";
import erc20abi from "assets/abi/erc20.json";
import { useTokenDecimalsQuery } from "./useTokenDecimalsQuery";

export function useTokenAllowanceQuery(
  tokenAddress?: string,
  accountAddress?: string,
  spenderAddress?: string,
  chain?: Chain
) {
  const tokenDecimalsQuery = useTokenDecimalsQuery(tokenAddress, chain);

  return useQuery(
    [chain?.id, tokenAddress, "allowance", accountAddress, spenderAddress],
    async () => {
      if (
        !tokenDecimalsQuery.data ||
        !tokenAddress ||
        !accountAddress ||
        !chain?.id ||
        !spenderAddress
      )
        return undefined;

      const readOnlyProvider = new ethers.providers.JsonRpcProvider(
        chain?.rpcUrl
      );

      const currencyContract = new ethers.Contract(
        tokenAddress,
        erc20abi,
        readOnlyProvider
      );

      const decimals = tokenDecimalsQuery.data;
      const rawAllowance = (await currencyContract.allowance(
        accountAddress,
        spenderAddress
      )) as ethers.BigNumber;

      return {
        raw: rawAllowance,
        formatted: parseFloat(ethers.utils.formatUnits(rawAllowance, decimals)),
      };
    },
    {
      enabled:
        !!tokenDecimalsQuery.data &&
        !!chain?.id &&
        !!tokenAddress &&
        !!accountAddress &&
        !!spenderAddress,
    }
  );
}

import { yupResolver } from "@hookform/resolvers/yup";
import FormTextInput from "components/Input/FormTextInput";
import Select from "components/Input/Select";
import useSignupMutation, {
  ISignUp,
} from "hooks/mutations/auth/useSignupMutation";
import useLocationDetailQuery from "hooks/queries/useLocationDetailQuery";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import * as Yup from "yup";
import { ISignupDataStep2, useSignupFormDataStore } from "..";

import { countryNames, allCountries } from "country-region-data";
import ErrorText from "components/Typography/ErrorText";
import PrimaryButton from "components/Buttons/PrimaryButton";
import { Header } from "pages/Auth/components";

const validationSchema = Yup.object().shape({
  country: Yup.string().required("Country Is Required"),
  state: Yup.string().required("State Is Required"),
  city: Yup.string().required("City Is Required"),
  postCode: Yup.string().required("Zip Code Is Required"),
});

export interface ISignUpStep2 {}

export const SignUpStep2: React.FunctionComponent<ISignUpStep2> = ({}) => {
  const signupMutation = useSignupMutation();
  const navigate = useNavigate();
  const {
    data: formData,
    setStepTwoData,
    isStepOneCompleted,
  } = useSignupFormDataStore();

  useEffect(() => {
    if (!isStepOneCompleted) {
      navigate("..");
    }
  }, [isStepOneCompleted, navigate]);

  const { register, handleSubmit, watch, control, formState, setValue } =
    useForm<ISignupDataStep2>({
      defaultValues: {
        ...formData,
      },
      resolver: yupResolver(validationSchema),
      mode: "all",
    });

  const regionOptions =
    watch("country") &&
    allCountries
      .find((country) => country[0] === watch("country"))?.[2]
      .map((region) => region[0]);

  const locationDetailQuery = useLocationDetailQuery({
    postCode: watch("postCode"),
    countryCode:
      watch("country") &&
      allCountries.find((country) => country[0] === watch("country"))?.[1],
  });

  const { errors } = formState;

  if (signupMutation.error) {
    const error = signupMutation.error as any;
    toast.error(
      error?.response && error?.response.data.message
        ? error?.response.data.message
        : error?.message
    );
    signupMutation.reset();
  }

  useEffect(() => {
    if (locationDetailQuery.data) {
      if (locationDetailQuery.data.result.length > 0) {
        setValue("state", locationDetailQuery.data.result[0].state, {
          shouldValidate: true,
        });
        setValue("city", locationDetailQuery.data.result[0].district, {
          shouldValidate: true,
        });
      }
    }
  }, [locationDetailQuery.data, setValue]);

  const onSubmitHandler = (data: ISignupDataStep2) => {
    const formData = setStepTwoData(data);
    console.log(formData);

    signupMutation.mutateAsync(formData as ISignUp).then((signUpResponse) => {
      toast.success(
        signUpResponse && signUpResponse?.message
          ? signUpResponse?.message
          : "Registered Successfully, verify Your Account"
      );
      navigate("../verify");
    });
  };

  return (
    <>
      <Header
        title="Sign Up"
        subTitle="Fill in the basic details to get started"
      />
      <form
        className="flex flex-col gap-2"
        onSubmit={handleSubmit(onSubmitHandler)}
      >
        <div>
          <label className="block text-black/80 font-semibold">Country</label>
          <Controller
            control={control}
            name="country"
            render={({ field }) => (
              <Select
                onChange={field.onChange}
                value={field.value}
                options={countryNames}
              />
            )}
          />
          {errors.country && <ErrorText>{errors.country.message}</ErrorText>}
        </div>

        <FormTextInput
          label="Zip Code"
          register={() => register("postCode")}
          error={errors.postCode && errors.postCode.message}
          placeholder="Enter Zip Code"
        />

        <div>
          <label className="block text-black/80 font-semibold">State</label>
          <Controller
            control={control}
            name="state"
            render={({ field }) => (
              <Select
                onChange={field.onChange}
                value={field.value}
                options={regionOptions || []}
                disabled={!regionOptions}
              />
            )}
          />
          {errors.state && <ErrorText>{errors.state.message}</ErrorText>}
        </div>

        <FormTextInput
          label="City"
          register={() => register("city")}
          error={errors.city && errors.city.message}
          placeholder="Enter Your City"
        />
        <div className="flex gap-4 mt-4">
          <Link to="..">
            <button
              // onClick={() => setStep(1)}
              className="px-6 py-2 bg-gray-300  rounded-lg text-black"
            >
              Back
            </button>
          </Link>
          <PrimaryButton
            disabled={!formState.isValid || signupMutation.isLoading}
            type="submit"
            className="flex-grow"
          >
            Sign Up
          </PrimaryButton>
        </div>
      </form>
    </>
  );
};

import React from "react";

export const LightningIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6096 29.9199C11.4049 29.8335 11.2348 29.6814 11.1262 29.4877C11.0176 29.2939 10.9765 29.0695 11.0096 28.8499L12.8296 16.9999H7.99955C7.84643 17.004 7.6944 16.9729 7.55521 16.9089C7.41601 16.845 7.29337 16.7499 7.19674 16.6311C7.1001 16.5122 7.03207 16.3727 6.99788 16.2234C6.96369 16.0741 6.96426 15.9189 6.99955 15.7699L9.99955 2.76987C10.0523 2.54614 10.1806 2.34743 10.3627 2.20718C10.5449 2.06694 10.7698 1.99373 10.9996 1.99987H20.9996C21.1489 1.99936 21.2966 2.03233 21.4315 2.09636C21.5665 2.16039 21.6854 2.25385 21.7796 2.36987C21.875 2.4872 21.9426 2.62465 21.9772 2.77189C22.0119 2.91912 22.0127 3.07229 21.9796 3.21987L20.2496 10.9999H24.9996C25.187 10.9995 25.3707 11.0518 25.5299 11.1508C25.689 11.2498 25.8171 11.3916 25.8996 11.5599C25.9712 11.7214 25.9988 11.899 25.9795 12.0747C25.9601 12.2504 25.8946 12.4178 25.7896 12.5599L12.7896 29.5599C12.7017 29.6901 12.5844 29.7978 12.4471 29.8743C12.3099 29.9507 12.1565 29.9937 11.9996 29.9999C11.8657 29.9974 11.7335 29.9703 11.6096 29.9199ZM17.7496 12.9999L19.7496 3.99987H11.7996L9.25955 14.9999H15.1696L13.5796 25.2799L22.9996 12.9999H17.7496Z"
        fill="#0157FF"
      />
    </svg>
  );
};

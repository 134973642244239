import * as React from "react";
import { SwapWidget } from "@uniswap/widgets";
import "@uniswap/widgets/fonts.css";
import { ethers } from "ethers";
import { useWallet } from "context/Wallet";
import tokenList from "config/UniswapTokenList.json";
import { useConnectWallet } from "@web3-onboard/react";

interface IUniswapOverlayProps {
  jsonRpcEndpoint: string;
  onClose: () => void;
  outputAddress?: string;
  defaultOutputAmount?: number;
}

const UniswapOverlay: React.FunctionComponent<IUniswapOverlayProps> = ({
  jsonRpcEndpoint,
  onClose,
  outputAddress,
  defaultOutputAmount,
}) => {
  const [
    {
      wallet, // the wallet that has been connected or null if not yet connected
      connecting, // boolean indicating if connection is in progress
    },
    connect, // function to call to initiate user to connect wallet
    disconnect, // function to call to with wallet<DisconnectOptions> to disconnect wallet
  ] = useConnectWallet();

  return (
    <div
      className="fixed inset-0 flex items-center justify-center z-30 bg-black/40 backdrop-blur-sm"
      onClick={() => onClose()}
    >
      <div className="Uniswap" onClick={(e) => e.stopPropagation()}>
        <SwapWidget
          //@ts-ignore
          provider={wallet!.provider}
          jsonRpcEndpoint={jsonRpcEndpoint}
          //@ts-ignore
          tokenList={tokenList}
          defaultOutputTokenAddress={outputAddress}
          defaultOutputAmount={defaultOutputAmount}
        />
      </div>
    </div>
  );
};

export default UniswapOverlay;

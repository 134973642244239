import { Disclosure, Transition } from "@headlessui/react";
import * as React from "react";
import { FiChevronRight } from "react-icons/fi";
import { twMerge } from "tailwind-merge";

type FaqElement = {
  question: string;
  answer: string;
};

interface IPropertyFaqProps {
  faqs?: FaqElement[];
}

const PropertyFaq = React.forwardRef<any, IPropertyFaqProps>(
  ({ faqs }, ref) => {
    return faqs && faqs.length > 0 ? (
      <div ref={ref} id="property-faq">
        <h4 className="text-lg font-semibold pb-6">
          Frequently Asked Questions
        </h4>
        <div className="px-4 py-2 border-black/10 border rounded-lg flex flex-col divide-black/10 divide-y">
          {faqs &&
            faqs.map((faqElement) => (
              <div className="py-2">
                <Disclosure>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="py-2 font-medium text-left w-full flex gap-2 items-center">
                        <FiChevronRight
                          className={twMerge(
                            "w-4 h-auto transition-tranform transform",
                            open && "rotate-90"
                          )}
                        />
                        {faqElement.question}
                      </Disclosure.Button>
                      <Transition
                        show={open}
                        enter="transition duration-100 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                      >
                        <Disclosure.Panel className="bg-black/5 rounded-lg my-4 p-6">
                          {faqElement.answer}
                        </Disclosure.Panel>
                      </Transition>
                    </>
                  )}
                </Disclosure>
              </div>
            ))}
        </div>
      </div>
    ) : (
      <></>
    );
  }
);

export default PropertyFaq;

import * as React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import DetailedPortfolio from "./pages/DetailedPortfolio";
import DocVault from "./pages/DocVault";
import PortfolioOverview from "./pages/PortfolioOverview";
import Transactions from "./pages/Transactions";

interface IDashboardProps {}

const Dashboard: React.FunctionComponent<IDashboardProps> = (props) => {
  return (
    <Routes>
      <Route path="transactions" element={<Transactions />} />
      <Route path="my-documents" element={<DocVault />} />
      <Route path="portfolio-overview" element={<PortfolioOverview />} />
      <Route path="portfolio-detailed" element={<DetailedPortfolio />} />
      <Route path="*" element={<Navigate to="./transactions" replace />} />
    </Routes>
  );
};

export default Dashboard;

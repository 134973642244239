import api from "api/axios";
import { useMutation, useQueryClient } from "react-query";

type InvestViewDocumentResponse = {};

interface IInvestViewDocument {
  documentId: string;
  investmentId: string;
}

const useInvestViewDocumentMutation = (dealId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: IInvestViewDocument) => {
      const forgetPWResponse = await api.post(
        `/investment/document/view?_id=${payload.investmentId}&docId=${payload.documentId}`
      );
      return forgetPWResponse.data as InvestViewDocumentResponse;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["dealInvestFlow", dealId]);
      },
    }
  );
};

export default useInvestViewDocumentMutation;

import PrimaryButton from "components/Buttons/PrimaryButton"

interface ISelfAccForm {
  attest: boolean | undefined
  setAttest: React.Dispatch<React.SetStateAction<boolean | undefined>>
  onSubmit: (isNonUSA: boolean) => void
  isSubmitting: boolean
}
export const SelfAccForm: React.FunctionComponent<ISelfAccForm> = ({
  attest,
  setAttest,
  onSubmit,
  isSubmitting,
}) => {
  return (
    <>
      <div className="text-slate-600 my-4">
        If the Subscriber is not a United States person (as defined by Section
        7701(a)(30) of the Internal Revenue Code of 1986, as amended), the
        Subscriber hereby represents that it has satisfied itself as to the full
        observance of the laws of its jurisdiction in connection with any
        invitation to subscribe for the Tokens, including:
      </div>
      <div>
        <ol type="a" className="p-4 ml-2 list-[lower-alpha]">
          <li className="pt-2 px-2">
            the legal requirements within its jurisdiction for the purchase of
            Tokens;
          </li>
          <li className="pt-2 px-2">
            any foreign exchange restrictions applicable to such purchase;
          </li>
          <li className="pt-2 px-2">
            any governmental or other consents that may need to be obtained; and
          </li>
          <li className="pt-2 px-2">
            the income tax and other tax consequences, if any, that may be
            relevant to the purchase, holding, redemption, sale or transfer of
            the Tokens. The Subscriber’s subscription and payment for and
            continued beneficial ownership of the Tokens will not violate any
            applicable securities or other laws of the Subscriber’s
            jurisdiction.
          </li>
        </ol>
      </div>
      <div className="flex items-start mt-6">
        <input
          type="checkbox"
          className="mx-4 mt-1"
          checked={attest}
          onChange={(e) => setAttest(e.target.checked)}
        />
        <label className="font-medium">I attest the above is true</label>
      </div>
      <PrimaryButton
        className="mx-4 my-8 disabled:bg-opacity-70 px-6"
        disabled={isSubmitting || !attest}
        onClick={() => {
          onSubmit(true)
        }}
      >
        Submit
      </PrimaryButton>
    </>
  )
}

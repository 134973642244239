import api from "api/axios"
import { useMutation, useQueryClient } from "react-query"

type UserSelfAcc =
  | {
      _id: string
      documents: []
      selfAttested: boolean
      verified: boolean
      userId: string
      accountId: string
    }
  | undefined

type SelfAccPayload = {
  accountId: string|undefined
  attestation: boolean
  terms: string
  category: any
}

interface IPayload {
  acceptedTerm?: string
}

const useSelfAccMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async ({ accountId, attestation, terms, category }: SelfAccPayload) => {
      const payload = {
        acceptedTerm: terms,
        selfAttested: attestation,
        category,
      } as IPayload

      if (!terms) {
        delete payload.acceptedTerm
      }
      const selfAccResponse = await api[accountId ? "put" : "post"](
        accountId ? "accreditions" : "accredition",payload
      )

      return selfAccResponse.data
    },
    {
      onSuccess: (selfResponse) => {
        return queryClient.invalidateQueries("userSelfAcc")
      },
    }
  )
}

export default useSelfAccMutation

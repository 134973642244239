import { useEffect, useState } from "react";
import useUserQuery from "hooks/queries/useUserQuery";
import useSelfAccQuery from "hooks/queries/useSelfAccQuery";
import useSelfAccMutation from "hooks/mutations/account/useSelfAccMutation";
import { SelfAccForm } from "./components/SelfAccForm";
import { SelfAccFormUSA } from "./components/SelfAccFormUSA";
import BreadcrumbsBar from "components/BreadcrumbsBar";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

export const AccreditationPoints = {
  sectionA:
    "My annual income was at least $200,000 in the last two years or my joint income together with my spouse was $300,000 in each of those two years with expectation of earning the same or higher income in the current year",
  sectionB:
    "My current individual net worth or my joint net worth together with my spouse is at least $1,000,000, excluding my primary residence",
  sectionC:
    "I may not be an accredited investor but I and/or my advisory council have significant experience in investment management/ business/finance",
} as const;

type AccreditationKeys = keyof typeof AccreditationPoints;
export type AccreditationValues = typeof AccreditationPoints[AccreditationKeys];

const getCategory = (val: string) => {
  for (const key in AccreditationPoints) {
    if (val === AccreditationPoints[key as AccreditationKeys])
      return key.replace("section", "").toLowerCase();
  }
};

const SelfAcc: React.FC = () => {
  const location = useLocation();
  const redirectTo = (location.state as any)?.redirectTo;
  const navigate = useNavigate();

  const userQuery = useUserQuery();
  const selfAccQuery = useSelfAccQuery();
  const selfAccMutation = useSelfAccMutation();

  const [selectedOption, setSelectedOption] = useState<
    AccreditationValues | ""
  >(selfAccQuery.data?.acceptedTerm as AccreditationValues);
  const [attest, setAttest] = useState(selfAccQuery.data?.selfAttested);

  useEffect(() => {
    if (selfAccQuery.data?.acceptedTerm)
      setSelectedOption(selfAccQuery.data?.acceptedTerm as AccreditationValues);
    if (selfAccQuery.data?.selfAttested)
      setAttest(selfAccQuery.data?.selfAttested);
  }, [selfAccQuery.data]);

  const handleSubmit = (isNonUSA: boolean) => {
    const accountId = selfAccQuery.data?.accountId;
    let attestation;
    let category;
    let terms = "";
    if (isNonUSA) {
      attestation = true;
      selfAccMutation
        .mutateAsync({ accountId, attestation, terms, category })
        .then(() => toast.success("Successfully submitted accreditation data"))
        .then(() => redirectTo && navigate(redirectTo))
        .catch(() => toast.error("Failed to submit accreditation data"));
      return;
    }
    if (attest && selectedOption) {
      attestation = attest;
      terms = selectedOption;
      category = getCategory(selectedOption);
      selfAccMutation
        .mutateAsync({ accountId, attestation, terms, category })
        .then(() => toast.success("Successfully submitted accreditation data"))
        .then(() => redirectTo && navigate(redirectTo))
        .catch(() => toast.error("Failed to submit accreditation data"));
    }
  };

  return (
    <>
      <BreadcrumbsBar path={["Profile Settings", "KYC"]} className="z-10" />
      <div className="block mt-20 px-12 py-8 bg-white rounded-md w-full">
        <div className="font-semibold text-2xl w-full relative py-2 mb-4">
          Self Accreditation
        </div>
        {userQuery?.data?.address?.country === "United States" ? (
          <SelfAccFormUSA
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            attest={attest}
            setAttest={setAttest}
            onSubmit={handleSubmit}
            isSubmitting={selfAccMutation.isLoading}
          />
        ) : (
          <SelfAccForm
            attest={attest}
            setAttest={setAttest}
            onSubmit={handleSubmit}
            isSubmitting={selfAccMutation.isLoading}
          />
        )}
      </div>
    </>
  );
};

export default SelfAcc;

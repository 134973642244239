import { useQuery } from "react-query";
import api from "api/axios";

type DealInvestFlow = {
  status: "pending";
  _id: string;
  completed: boolean;
  userId: string;
  propertyName: string;
  dealId: string;
  roi: number;
  orgId: string;
  noOfTokens: number;
  quantity: number;
  currentValue: number;
  contractAddress: string;
  from: string;
  to: string;
  documents: Document[];
  //TODO: ENUM
  type: string;
  txHash?: string;
  disclosuresAndTerms: {
    _id: string;
    accepted: boolean;
  };
  createdAt: string;
  updatedAt: string;
};

export type Document = {
  _id: string;
  type: string;
  source: string;
  signautureMethod: string;
  status: "signed" | "pending" | "view";
  title: string;
  signRequired: boolean;
  investmentId: string;
  propertyId: string;
  signUrl: string;
  viewUrl: string;
};

export default function useDealInvestFlowQuery(dealId: string) {
  return useQuery(["dealInvestFlow", dealId], async () => {
    const dealInvestFlow = await api.get(`/invest/${dealId}`);
    return dealInvestFlow.data as DealInvestFlow;
  });
}

import PrimaryButton from "components/Buttons/PrimaryButton";
import TextInput from "components/Input/TextInput";
import { useState } from "react";
import { Header } from "../../components";
import logo from "assets/images/logos/logo.jpg";
import { useNavigate } from "react-router-dom";

export const ForgotForm: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const subTitle = isSuccess
    ? `The instructions on how to reset your password have been sent to ${email}`
    : "please enter the email you use for loggin in, we will send instructions on how to reset your password.";

  const isValid: () => boolean = () => {
    if (email === "" || !/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/.test(email)) {
      return false;
    }
    return true;
  };

  const submitHandler = () => {
    setIsSuccess(true);
  };

  return (
    <div className="relative col-span-12 sm:col-span-6 lg:col-span-5 overflow-y-auto">
      <div className="w-full flex flex-col p-8 md:px-12 lg:px-20 ">
        <Header title="Reset Your Password" subTitle={subTitle} />
        {!isSuccess && (
          <>
            <div className="my-2">
              <label className="block text-black/80 font-semibold">Email</label>
              <TextInput
                placeholder="jhon.doe@realto.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <PrimaryButton
              type="submit"
              disabled={!isValid()}
              className="disabled:bg-opacity-70"
              onClick={submitHandler}
            >
              Continue
            </PrimaryButton>
          </>
        )}
        {isSuccess && (
          <PrimaryButton
            onClick={() => {
              navigate(`/signin`);
            }}
          >
            Sign In
          </PrimaryButton>
        )}
      </div>
      <a
        className="flex justify-center w-full py-2 absolute bottom-0 "
        href="https://realtoapps.com/"
        target="_blank"
      >
        <span className="hover:underline">Technology Powered By</span>
        <img src={logo} alt="logo" className="shadow rounded-lg h-8" />
        <span className="font-bold">REALTO</span>
      </a>
    </div>
  );
};

import PrimaryButton from "components/Buttons/PrimaryButton";
import React, { useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { GoKebabVertical } from "react-icons/go";
import { Link, useNavigate, useParams } from "react-router-dom";
import PropertyDetailsBottomBar from "../../components/PropertyDetailsBottomBar";
import PropertyInvestBreadcrumbsBar from "../../components/PropertyInvestBreadcrumbsBar";
import Stepper from "../../components/Stepper";
import PropertyDetailsCard from "../../components/PropertyDetailsCard";
import { IoWalletOutline } from "react-icons/io5";
import CryptoPayment from "./CryptoPayment";
import { twMerge } from "tailwind-merge";
import useDealInvestFlowQuery from "hooks/queries/useDealInvestFlowQuery";
import FiatPayment from "./FiatPayment";

interface ICheckoutProps {}

const Checkout: React.FunctionComponent<ICheckoutProps> = (props) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
    "crypto" | "fiat"
  >("fiat");

  const { propertyId } = useParams();
  const dealInvestFlowQuery = useDealInvestFlowQuery(propertyId!);

  return (
    <>
      <PropertyInvestBreadcrumbsBar />
      <div className="flex flex-col flex-grow py-4 gap-8 pt-20 pb-20 lg:pb-4 sm:px-4 md:px-8">
        <Stepper
          steps={["Select Tokens", "Disclosures", "Documents", "Checkout"]}
          activeStep={3}
        />
        <div className="flex gap-8">
          <div className="p-8 bg-white rounded-md flex-grow flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <h1 className="text-xl font-semibold">Select Payment Method</h1>
            </div>
            <div className="flex gap-4">
              <button
                className={twMerge(
                  "flex flex-col p-4 rounded-md items-center gap-2",
                  selectedPaymentMethod !== "fiat"
                    ? "border-black/30 border text-black/60"
                    : "bg-foreground-primary text-white"
                )}
                onClick={() => setSelectedPaymentMethod("fiat")}
              >
                <IoWalletOutline className="h-6 w-auto" />
                <div className="flex flex-col gap-0.5 items-center">
                  <div className="font-medium">Pay with Fiat</div>
                  <div
                    className={twMerge(
                      "text-xs",
                      selectedPaymentMethod === "fiat"
                        ? "text-white/60"
                        : "text-black/40"
                    )}
                  >
                    Pay using Credit Card, Debit Card, Netbanking etc
                  </div>
                </div>
              </button>
              <button
                className={twMerge(
                  "flex flex-col p-4 rounded-md items-center gap-2",
                  selectedPaymentMethod !== "crypto"
                    ? "border-black/30 border text-black/60"
                    : "bg-foreground-primary text-white"
                )}
                onClick={() => setSelectedPaymentMethod("crypto")}
              >
                <IoWalletOutline className="h-6 w-auto" />
                <div className="flex flex-col gap-0.5 items-center">
                  <div className="font-medium">Pay with Crypto</div>
                  <div
                    className={twMerge(
                      "text-xs",
                      selectedPaymentMethod === "crypto"
                        ? "text-white/60"
                        : "text-black/40"
                    )}
                  >
                    Pay using Metamask, Wallet Connect etc
                  </div>
                </div>
              </button>
            </div>
            {selectedPaymentMethod === "crypto" && <CryptoPayment />}
            {selectedPaymentMethod === "fiat" && <FiatPayment />}
          </div>
          <PropertyDetailsCard className="hidden lg:flex" />
        </div>
      </div>
      <PropertyDetailsBottomBar moreDetails={<PropertyDetailsCard />} />
    </>
  );
};

export default Checkout;

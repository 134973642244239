import { useWallet } from "context/Wallet";
import React, { useEffect, useState } from "react";
import AddFundsStep from "./steps/AddFundsStep";
import ApproveContractStep from "./steps/ApproveContractStep";
import MintStep from "./steps/MintStep";
import SwitchNetworkStep from "./steps/SwitchNetworkStep";

interface ICryptoPaymentProps {}

const CryptoPayment: React.FunctionComponent<ICryptoPaymentProps> = (props) => {
  const [currentStep, setCurrentStep] = useState(1);

  const onNextStep = () => {
    setCurrentStep((currentStep) => currentStep + 1);
  };

  return (
    <div className="flex flex-col py-4 divide-y divide-black/10">
      <SwitchNetworkStep
        stepNumber={1}
        activeStepNumber={currentStep}
        onNextStep={() => setCurrentStep(2)}
      />
      <AddFundsStep
        stepNumber={2}
        activeStepNumber={currentStep}
        onNextStep={() => setCurrentStep(3)}
      />
      <ApproveContractStep
        stepNumber={3}
        activeStepNumber={currentStep}
        onNextStep={() => setCurrentStep(4)}
      />
      <MintStep
        stepNumber={4}
        activeStepNumber={currentStep}
        onNextStep={onNextStep}
      />
    </div>
  );
};

export default CryptoPayment;

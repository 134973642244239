import { VideoBg } from "../../components";
import { ResetForm } from "./ResetForm";

const ResetPassword: React.FunctionComponent = () => {
  return (
    <div className="grid grid-cols-12 w-full">
      {/*this is col1*/}
      <ResetForm />
      {/* this is col2 */}
      <VideoBg />
    </div>
  );
};

export default ResetPassword;

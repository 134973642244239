import React, { useEffect, useState } from "react";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

import { GrClose } from "react-icons/gr";
import { BsArrowLeftShort } from "react-icons/bs";
import BreadcrumbsBar from "components/BreadcrumbsBar";
import PrimaryButton from "components/Buttons/PrimaryButton";
import TextInput from "components/Input/TextInput";
import { Transition } from "@headlessui/react";
import { useDocumentSigning } from "./DocumentSigningProvider";
import useInvestSignDocumentMutation from "hooks/mutations/invest/useInvestSignDocumentMutation";
import FormTextInput from "components/Input/FormTextInput";
import { SubmitHandler, useForm } from "react-hook-form";
import useInvestViewDocumentMutation from "hooks/mutations/invest/useInvestViewDocumentMutation";
import { toast } from "react-toastify";

interface IDocumentSigningProps {}

type Inputs = {
  firstName: string;
  lastName: string;
};

const DocumentSigning: React.FunctionComponent<IDocumentSigningProps> = (
  props
) => {
  const { selectedDocument, closeDocumentSigner, isOpen } =
    useDocumentSigning();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Inputs>();

  const investSignDocumentMutation = useInvestSignDocumentMutation(
    selectedDocument?.propertyId?.[0] || selectedDocument?.propertyId|| ""
  );

  const investViewDocumentMutation = useInvestViewDocumentMutation(
    selectedDocument?.propertyId?.[0] || selectedDocument?.propertyId|| ""
  );

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    console.log(data, errors);
    console.log("Changed reflected");
    if (!selectedDocument) return;
    investSignDocumentMutation
      .mutateAsync({
        investmentId: selectedDocument.investmentId,
        documentId: selectedDocument._id,
        firstName: data.firstName,
        lastName: data.lastName,
      })
      .then(() => {
        toast.success("Document Successfully Signed");
        closeDocumentSigner();
      })
      .catch((error) => {
        console.log("Wat");
        console.log(error);
        toast.error("Unable to sign document:" + error.response.data.message);
      });
  };

  useEffect(() => {
    // if a document is viewed, then no need to view it again
    if (!investViewDocumentMutation.isIdle) return;
    // if document is not viewed, and it is view only, then trigger view api
    if (selectedDocument && selectedDocument.signRequired === false) {
      investViewDocumentMutation.mutateAsync({
        investmentId: selectedDocument.investmentId,
        documentId: selectedDocument._id,
      });
    }
  }, [investViewDocumentMutation, selectedDocument]);

  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.min.js">
      <Transition
        enter="transition ease-in-out duration-500 transform"
        enterFrom="translate-y-full"
        enterTo="translate-y-0"
        leave="transition ease-in-out duration-500 transform"
        leaveFrom="translate-y-0"
        leaveTo="translate-y-full"
        show={!!selectedDocument && !!isOpen}
        className="fixed inset-0 mt-14 bg-background-primary z-20 flex flex-col"
      >
        <BreadcrumbsBar
          className="static mt-0 flex-shrink-0 border-b border-black/10"
          path={["Document Viewer", selectedDocument?.title || ""]}
          onBack={() => closeDocumentSigner()}
          rightArea={
            <button
              className="ml-auto pl-6"
              onClick={() => closeDocumentSigner()}
            >
              <GrClose />
            </button>
          }
        />
        <div className="flex-grow relative overflow-y-auto">
          <Viewer
            fileUrl={
              selectedDocument?.viewUrl || selectedDocument?.signUrl || ""
              // selectedDocument?.baseSignUrl ||
            }
            plugins={[
              // Register plugins
              defaultLayoutPluginInstance,
            ]}
          />
        </div>
        {selectedDocument?.signRequired &&
          (selectedDocument?.status === "pending" ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div
                className={
                  "px-4 gap-3 sm:gap-12 inset-x-0 py-3 h-auto bg-foreground-primary z-10 flex flex-col text-white justify-center sm:flex-row sm:items-center"
                }
              >
                <div className="flex justify-evenly sm:gap-8 gap-2">
                  <FormTextInput
                    className="bg-white/10 text-sm py-2"
                    placeholder="First Name"
                    register={() => register("firstName", { required: true })}
                  />
                  <FormTextInput
                    register={() => register("lastName", { required: true })}
                    className="bg-white/10 text-sm py-2"
                    placeholder="Last Name"
                  />
                </div>
                <PrimaryButton
                  className="px-8"
                  type="submit"
                  disabled={investSignDocumentMutation.isLoading}
                >
                  {investSignDocumentMutation.isLoading ? "Signing..." : "Sign"}
                </PrimaryButton>
              </div>
            </form>
          ) : (
            <div className="px-4 gap-3 sm:gap-12 inset-x-0 py-3 h-auto bg-foreground-primary z-10 flex flex-col text-white justify-center sm:flex-row sm:items-center">
              Document Signed
            </div>
          ))}
      </Transition>
    </Worker>
  );
};

export default DocumentSigning;

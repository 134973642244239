import {
  QueryFunction,
  useInfiniteQuery,
  useQuery,
  UseQueryOptions,
} from "react-query";
import api from "api/axios";

type PropertiesPage = {
  pages: number;
  properties: Property[];
};

type Property = {
  name: string;
  _id: string;
  type: "realEstateFund" | "asset" | "debtFund" | "others";
  status: "upcoming" | "listed";
  token: {
    totalTokens: number;
    soldTokens: number;
    availableTokens: number;
    name: string;
  };
  roi: {
    IRR: number;
    averageCashYield: number;
  };
  owner: {
    type: string;
    id: string;
    name: string;
  };
  marketing: {
    coverImage: string;
  };
};

export default function usePropertiesQuery(
  options?: UseQueryOptions<PropertiesPage>
) {
  return useInfiniteQuery<PropertiesPage>(
    "properties",
    async ({ pageParam = 1 }) => {
      const propertiesPage = await api.get(`/deal?page=${pageParam}`);
      return propertiesPage.data as PropertiesPage;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.pages === pages.length) {
          return undefined;
        } else {
          return pages.length + 1;
        }
      },
      // ...options,
    }
  );
}

import { BsChevronRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import StatusButton from "../../StatusButton";

interface Props {
  isKycDone: boolean;
  isAccreditationDone: boolean;
  isUserDetailsDone: boolean;
}

export const CompleteProfile = ({
  isKycDone,
  isAccreditationDone,
  isUserDetailsDone,
}: Props) => {
  return (
    <div className="py-7 px-6 md:py-14 md:px-12 grid row-auto gap-y-5 gap-x-5 md:grid-cols-2  rounded-3xl  drop-shadow-xl">
      <div className="">
        <h2 className="text-2xl md:text-3xl font-bold text-black/80">
          Complete your profile to get started
        </h2>
        <h2 className="text-sm text-justify mt-5 w-5/6">
          Your profile is incomplete. Complete the required fields and start
          investing
        </h2>
      </div>
      <div className="flex flex-col bg-white rounded-2xl p-5 md:p-8 min-h-full drop-shadow-xl">
        <ul className="text-lg md:text-xl space-y-5">
          <li
            className={`flex ${
              isUserDetailsDone ? "font-bold text-black/80" : ""
            }`}
          >
            <StatusButton isDone={isUserDetailsDone} />
            User Details
          </li>
          <li
            className={`flex ${
              isAccreditationDone ? "font-bold text-black/80" : ""
            }`}
          >
            <StatusButton isDone={isAccreditationDone} />
            Accreditation
          </li>
          <li className={`flex ${isKycDone ? "font-bold text-black/80" : ""}`}>
            <StatusButton isDone={isKycDone} />
            KYC
          </li>
        </ul>
        <Link className="pt-3 sm:self-end" to="/account/profile">
          <button className="text-sm font-medium text-foreground-accent flex items-center mt-2 hover:opacity-80">
            Get Started{" "}
            <BsChevronRight size="1.2em" className="inline stroke-1 mx-2" />
          </button>
        </Link>
      </div>
    </div>
  );
};

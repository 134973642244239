import FormTextInput from "components/Input/FormTextInput";
import { useState } from "react";
import { CountryDropdown } from "react-country-region-selector";
import {
  Control,
  Controller,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";
import { FaPencilAlt } from "react-icons/fa";
import { ProfileForm } from "..";
import { ShippingAddressForm } from "./ShippingAddressForm";

interface IContactForm {
  register: UseFormRegister<ProfileForm>;
  errors: any;
  control: Control<ProfileForm>;
  setValue: UseFormSetValue<ProfileForm>;
  getValues: UseFormGetValues<ProfileForm>;
}

export const ContactForm: React.FunctionComponent<IContactForm> = ({
  register,
  errors,
  setValue,
  getValues,
  control,
}) => {
  const [isEditable, setIsEditable] = useState<boolean>(true);
  return (
    <div className="block bg-white rounded-md px-12 py-8" id="contact">
      <div className="font-bold text-black/80 text-2xl w-full relative py-2 mb-2">
        <h1>Contact Info</h1>
        {!isEditable && (
          <FaPencilAlt
            className="absolute right-8 top-2 hover:opacity-70 cursor-pointer"
            onClick={() => {
              setIsEditable(true);
            }}
          />
        )}
      </div>
      <div className="font-semibold text-lg text-black/80 py-4">
        Billing Address
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-6 gap-y-5">
        <FormTextInput
          label="Address Line 1"
          labelClassname="font-medium text-sm"
          register={() => register("address.addressLine")}
          error={errors.addressLine1 && errors.addressLine1.message}
          placeholder="Address Line 1"
          disabled={!isEditable}
          className="text-sm"
        />
        <FormTextInput
          label="Address Line 2"
          labelClassname="font-medium text-sm"
          register={() => register("address.addressLine")}
          error={errors.addressLine2 && errors.addressLine2.message}
          placeholder="Address Line 2"
          disabled={!isEditable}
          className="text-sm"
        />
        <FormTextInput
          label="City"
          register={() => register("address.city")}
          labelClassname="font-medium text-sm"
          error={errors.city && errors.city.message}
          placeholder="Enter Your City"
          disabled={!isEditable}
          className="text-sm"
        />
        <FormTextInput
          label="State"
          register={() => register("address.state")}
          labelClassname="font-medium text-sm"
          error={errors.state && errors.state.message}
          placeholder="Enter Your State"
          disabled={!isEditable}
          className="text-sm"
        />
        <div>
          <label className="block font-medium text-sm text-black/80 pb-1">
            Country
          </label>
          <Controller
            render={({ field }) => (
              <CountryDropdown
                disabled={!isEditable}
                value={field.value}
                priorityOptions={["US"]}
                onChange={(val, e) => {
                  setValue("address.country", val, { shouldValidate: true });
                  setValue("address.state", "");
                }}
                name="country"
                classes="rounded-lg block w-full  border-black/10 border-2 p-3 focus:border-blue-600/20 focus:outline-none bg-transparent text-sm"
              />
            )}
            name={"address.country"}
            control={control}
          />
          {errors.country && (
            <span className="text-red-400 font-medium text-sm">
              {errors.country.message}
            </span>
          )}
        </div>

        <FormTextInput
          label="Zip Code"
          labelClassname="font-medium text-sm"
          register={() => register("address.postCode")}
          error={errors.postCode && errors.postCode.message}
          placeholder="Enter Zip Code"
          disabled={!isEditable}
          className="text-sm"
        />
      </div>
      <ShippingAddressForm
        getValues={getValues}
        register={register}
        isEditable={isEditable}
        errors={errors}
        setValue={setValue}
        control={control}
      />
    </div>
  );
};

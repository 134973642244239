import { Document } from "hooks/queries/useDealDetailsQuery";
import * as React from "react";

import {
  Document as DocumentViewer,
  Page as PageViewer,
} from "react-pdf/dist/esm/entry.webpack";

interface IPropertyDocumentsProps {
  documents?: Document[];
}

const PropertyDocuments = React.forwardRef<any, IPropertyDocumentsProps>(
  ({ documents }, ref) => {
    return documents && documents.length > 0 ? (
      <div ref={ref}>
        <h4 className="text-lg font-semibold pb-6">Documents</h4>
        <div className="grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 gap-6">
          {documents.map((document) => (
            <DocumentPreview document={document} />
          ))}
        </div>
      </div>
    ) : (
      <></>
    );
  }
);

interface IDocumentPreview {
  document: Document;
}

const DocumentPreview: React.FC<IDocumentPreview> = ({ document }) => {
  const documentExtension = document.file.Location.split(".").pop();

  return (
    <a href={document.file.Location} target="_blank" rel="noreferrer">
      <div className="bg-black/10 rounded-md shadow-md p-4 aspect-[4/3] relative overflow-hidden flex items-center justify-center">
        <div className="text-center text-5xl font-mono text-black/10 font-bold pb-10">
          {documentExtension === "pdf" ? (
            <DocumentViewer
              file={document.file.Location}
              renderMode="svg"
              className="absolute top-0 inset-x-0"
            >
              <PageViewer pageNumber={1} height={300} />
            </DocumentViewer>
          ) : (
            <span className="uppercase">{documentExtension}</span>
          )}
        </div>
        <div className="absolute bottom-0 inset-x-0 p-4 bg-white border-t-2 border-black/5">
          {document.title}
        </div>
      </div>
    </a>
  );
};

export default PropertyDocuments;

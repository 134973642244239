import { useConnectWallet } from "@web3-onboard/react";
import { useTokenDecimalsQuery } from "hooks/queries/web3/useTokenDecimalsQuery";
import * as React from "react";
import { twMerge } from "tailwind-merge";
import Web3Action from "../Web3Action";
import { Chain } from "@web3-onboard/common";
import PrimaryButton from "./PrimaryButton";
import { useMutation } from "react-query";

interface ITrackTokenButtonProps {
  tokenAddress: string;
  chain: Chain;
  className?: string;
  symbol: string;
}

const TrackTokenButton: React.FunctionComponent<ITrackTokenButtonProps> = ({
  className,
  chain,
  tokenAddress,
  symbol,
  ...props
}) => {
  const [{ wallet }] = useConnectWallet();
  const tokenDecimalsQuery = useTokenDecimalsQuery(tokenAddress, chain);

  const addTokenMutation = useMutation(async () => {
    // wasAdded is a boolean. Like any RPC method, an error may be thrown.
    console.log({
      address: tokenAddress, // The address that the token is at.
      decimals: tokenDecimalsQuery.data, // The number of decimals in the token
      symbol: symbol,
    });

    const wasAdded = await wallet?.provider.request({
      method: "wallet_watchAsset",
      params: {
        // @ts-ignore
        type: "ERC20", // Initially only supports ERC20, but eventually more!
        options: {
          address: tokenAddress, // The address that the token is at.
          decimals: tokenDecimalsQuery.data, // The number of decimals in the token
          symbol: symbol,
        },
      },
    });

    if (wasAdded) {
      console.log("Thanks for your interest!");
    } else {
      console.log("Your loss!");
    }
    return wasAdded;
  });

  return (
    <Web3Action
      className={twMerge(
        "bg-white border border-foreground-primary text-foreground-primary",
        className
      )}
      switchChainText="Switch Chain To Track Token"
      connectWalletText="Connect Wallet To Track Token"
    >
      <PrimaryButton
        className={twMerge(
          "bg-white border border-foreground-primary text-foreground-primary",
          className
        )}
        disabled={tokenDecimalsQuery.isLoading}
        onClick={() => addTokenMutation.mutateAsync()}
      >
        Track Token in Wallet
      </PrimaryButton>
    </Web3Action>
  );
};

export default TrackTokenButton;

import PrimaryButton from "components/Buttons/PrimaryButton";
import Tooltip from "components/Tooltip";
import useSelfAccQuery from "hooks/queries/useSelfAccQuery";
import { AccreditationPoints } from "pages/Account/pages/SelfAcc";
import * as React from "react";
import { DivProps } from "react-html-props";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";
import { twMerge } from "tailwind-merge";

interface IPropertyCtaBarProps extends DivProps {}

const PropertyCtaBar: React.FunctionComponent<IPropertyCtaBarProps> = (
  props
) => {
  const selfAccQuery = useSelfAccQuery();
  const accredited =
    selfAccQuery.data?.acceptedTerm !== AccreditationPoints.sectionC;

  const location = useLocation();

  return (
    <div
      className={twMerge(
        "px-4 fixed bottom-0 gap-3 inset-x-0 py-3 h-auto bg-foreground-primary z-10 flex flex-col text-white justify-evenly sm:flex-row sm:items-center sm:justify-between",
        props.className
      )}
    >
      <div className="flex justify-evenly sm:gap-8">
        <div className="flex flex-col text-center items-center">
          <div>12%</div>
          <div className="text-xs font-medium text-white/50">Targetted IRR</div>
        </div>
        <div className="flex flex-col text-center items-center">
          <div>12%</div>
          <div className="text-xs font-medium text-white/50">Targetted IRR</div>
        </div>
        <div className="flex flex-col text-center items-center">
          <div>
            0% <span className="text-xs text-white/70">(213/1000)</span>
          </div>
          <div className="text-xs font-medium text-white/50">Tokens Sold</div>
        </div>
      </div>
      {!accredited && (
        <Tooltip id="buy-button-mobile">
          This deal is currently available only to accredited investors. Please
          change your accreditation status first in order to access this deal.
        </Tooltip>
      )}
      <Link
        to={accredited ? "./buy" : "/account/verify"}
        className="flex flex-col"
        data-tip
        data-for="buy-button-mobile"
        state={{ redirectTo: `${location.pathname}` }}
      >
        <PrimaryButton disabled={!accredited}>
          {accredited ? "Invest Now" : "Change Accreditation Status"}
        </PrimaryButton>
      </Link>
    </div>
  );
};

export default PropertyCtaBar;

import * as React from "react";
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
  DataGridProps,
} from "@mui/x-data-grid";
import "./styles.css";
import sorthighest from "assets/images/logos/sort-highest.jpeg";

const Table: React.FunctionComponent<DataGridProps> = (props) => {
  return (
    <DataGrid
      className="bg-white rounded-xl p-2"
      components={{
        ColumnSortedDescendingIcon: SortedDescendingIcon,
        ColumnSortedAscendingIcon: SortedAscendingIcon,
      }}
      disableSelectionOnClick
      {...props}
    />
  );
};

export function SortedDescendingIcon() {
  return <img src={sorthighest} alt="sample" className="w-4 h-4" />;
}

export function SortedAscendingIcon() {
  return <img src={sorthighest} alt="sample1" className="rotate-180 w-4 h-4" />;
}

export default Table;

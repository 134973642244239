import React, { useState } from "react";
import TenantLogo from "assets/images/logos/tenant-logo.svg";
import { AiOutlineHome, AiOutlineSearch, AiOutlineRight } from "react-icons/ai";
import { FiBell } from "react-icons/fi";
import { IoWalletOutline } from "react-icons/io5";
import NavbarButton from "./NavbarButton";
import { useConnectWallet, useSetChain } from "@web3-onboard/react";
import { Link } from "react-router-dom";
import { useWallet } from "context/Wallet";
import { BiMenu } from "react-icons/bi";
import Dropdown from "components/Dropdown";
import useLogoutMutation from "hooks/mutations/auth/useLogoutMutation";
import { Disclosure, Transition } from "@headlessui/react";

import { FaChevronRight } from "react-icons/fa";
import { twMerge } from "tailwind-merge";
import { DivProps } from "react-html-props";
import WalletButton from "./WalletButton";
import { NotificationDropDown } from "./NavNotification";
import useCurrentTenantQuery from "hooks/queries/useCurrentTenantQuery";
import ProfileButton from "./ProfileButton";

interface IMobileNavbarProps extends DivProps {}

const MobileNavbar: React.FunctionComponent<IMobileNavbarProps> = (props) => {
  const { onboard } = useWallet();

  const [{ chains, connectedChain, settingChain }, setChain] = useSetChain();
  const logoutMutation = useLogoutMutation();
  const currentTenantQuery = useCurrentTenantQuery();

  const [isNavbarDrawerOpen, setIsNavbarDrawerOpen] = useState(false);

  return (
    <div className={props.className}>
      <div className="flex flex-shrink-0 h-14 bg-foreground-primary px-4 fixed inset-x-0 top-0 items-center z-50 justify-evenly">
        <button onClick={() => setIsNavbarDrawerOpen((o) => !o)}>
          <BiMenu className="text-white flex-shrink w-6 h-auto" />
        </button>
        <div className="flex items-center justify-center flex-grow">
          <Link to="/home">
            <img
              src={currentTenantQuery.data?.logo.dark}
              className="max-w-[160px] h-auto"
            />
          </Link>
        </div>
        <div className="mr-4">
          <NotificationDropDown />
        </div>
        <ProfileButton />
      </div>
      <Transition
        enter="transition ease-in-out duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-full"
        leave="transition ease-in-out duration-200"
        leaveFrom="opacity-full"
        leaveTo="opacity-0"
        show={isNavbarDrawerOpen}
        className="fixed inset-0 bg-black/50 mt-14 z-20"
      />

      <Transition
        enter="transition ease-in-out duration-200 transform"
        enterFrom="-translate-x-full"
        enterTo="translate-x-0"
        leave="transition ease-in-out duration-200 transform"
        leaveFrom="translate-x-0"
        leaveTo="-translate-x-full"
        show={isNavbarDrawerOpen}
        className="fixed inset-y-0 mt-14 bg-foreground-secondary z-30 flex flex-col left-0 right-0 xs:right-1/4 sm:right-1/2 md:right-auto shadow-lg"
      >
        <div className="bg-white/20 rounded-md flex mt-4 mb-8 mx-4">
          <AiOutlineSearch className="h-auto flex-shrink-0 w-5 text-white ml-2" />
          <input
            type="text"
            placeholder="Search powered by Mia"
            className="bg-transparent py-1 outline-none px-6 text-white"
          />
        </div>
        <div className="flex flex-col text-white font-medium text-lg gap-y-4 pl-14 flex-grow pr-4">
          <Link to="/marketplace">Marketplace</Link>
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="text-left relative bg-foreground-secondary z-10">
                  <div className="w-6 absolute right-full inset-y-0 flex items-center justify-start">
                    <AiOutlineRight
                      className={twMerge(
                        "w-3.5 h-auto transition-transform",
                        open && "transform rotate-90"
                      )}
                    />
                  </div>
                  <div className="relative">Dashboard</div>
                </Disclosure.Button>
                <div className="overflow-hidden">
                  <Transition
                    enter="transition ease-in-out duration-200 transform"
                    enterFrom="-translate-y-full"
                    enterTo="translate-y-0"
                    leave="transition ease-in-out duration-200 transform"
                    leaveFrom="translate-y-0"
                    leaveTo="-translate-y-full"
                  >
                    <Disclosure.Panel className="bg-white/10 rounded-md py-5 pl-8 flex flex-col text-base font-normal gap-2">
                      <Link to="/dashboard/transactions">My Transactions</Link>
                      <Link to="/dashboard/portfolio-overview">
                        Portfolio Overview
                      </Link>
                      <Link to="/dashboard/portfolio-detailed">
                        Detailed Portfolio
                      </Link>
                      <Link to="/dashboard/my-documents">DocVault</Link>
                    </Disclosure.Panel>
                  </Transition>
                </div>
              </>
            )}
          </Disclosure>
        </div>
        <div className="h-14 bg-foreground-primary flex px-4 justify-between items-center">
          <button>
            <FiBell className="text-white h-5 w-auto" />
          </button>
          <WalletButton
            onClick={() => {
              setIsNavbarDrawerOpen(false);
            }}
          />
        </div>
      </Transition>
    </div>
  );
};

export default MobileNavbar;

import { Listbox, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { HiCheck, HiSelector } from "react-icons/hi";
import { twMerge } from "tailwind-merge";

interface ISelectProps {
  options: string[];
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
}

const Select: React.FunctionComponent<ISelectProps> = ({
  onChange,
  value,
  options,
  ...props
}) => {
  return (
    <Listbox value={value} onChange={onChange} {...props}>
      <div className="relative mt-1">
        <Listbox.Button
          className={twMerge(
            "w-full mx-auto rounded-lg border-black/10 border-2 p-3 focus:border-blue-600/20 focus:outline-none relative cursor-default bg-white pr-10 text-left",
            props.disabled && "bg-black/[0.05] cursor-not-allowed",
            value === undefined && "text-black/30"
          )}
        >
          <span className="block truncate">
            {value !== undefined ? value : "Select an option"}
          </span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <HiSelector className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="z-10 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {options.map((option, optionIdx) => (
              <Listbox.Option
                key={optionIdx}
                className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                    active ? "bg-amber-100 text-amber-900" : "text-gray-900"
                  }`
                }
                value={option}
              >
                {({ selected }) => (
                  <>
                    <span
                      className={`block truncate ${
                        selected ? "font-medium" : "font-normal"
                      }`}
                    >
                      {option}
                    </span>
                    {selected ? (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                        <HiCheck className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

export default Select;

import api from "api/axios";
import * as React from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import { DocumentSigningProvider } from "../../../../../../components/DocumentSigning/DocumentSigningProvider";
import InvestLayout from "./components/InvestLayout";
import Checkout from "./pages/Checkout";
import Completion from "./pages/Completion";
import Disclosures from "./pages/Disclosures";
import Documents from "./pages/Documents";
import SelectTokens from "./pages/SelectTokens";

interface IPropertyInvestProps {}

const PropertyInvest: React.FunctionComponent<IPropertyInvestProps> = (
  props
) => {
  return (
    <Routes>
        <Route element={<InvestLayout />}>
          <Route path="select-tokens" element={<SelectTokens />} />
          <Route path="disclosures" element={<Disclosures />} />
          <Route path="documents" element={<Documents />} />
          <Route path="checkout" element={<Checkout />} />
          <Route path="completion/:investmentId" element={<Completion />} />
          <Route index element={<Navigate to="./select-tokens" replace />} />
        </Route>
      </Routes>
  );
};

export default PropertyInvest;

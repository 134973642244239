import { useQuery } from "react-query";
import api from "api/axios";

export type CurrentTenant = {
  logo: {
    light: string;
    dark: string;
  };
};

export default function useCurrentTenantQuery() {
  return useQuery(["currentTenant"], async () => {
    const currentTenant = await api.get(`/tenants/current`);
    return currentTenant.data as CurrentTenant;
  });
}

import api from "api/axios";
import PrimaryButton from "components/Buttons/PrimaryButton";
import useDealDetailsQuery from "hooks/queries/useDealDetailsQuery";
import useInvestmentSummaryQuery from "hooks/queries/useInvestmentSummaryQuery";
import * as React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { BiLinkExternal } from "react-icons/bi";
import { Link, useParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import PropertyDetailsBottomBar from "../../components/PropertyDetailsBottomBar";
import PropertyDetailsCard from "../../components/PropertyDetailsCard";
import PropertyInvestBreadcrumbsBar from "../../components/PropertyInvestBreadcrumbsBar";
import Stepper from "../../components/Stepper";
import InvestmentDetailsCard from "./components/InvestmentDetailsCard";

interface ICompletionProps {}

const Completion: React.FunctionComponent<ICompletionProps> = (props) => {
  const { propertyId, investmentId } = useParams();

  const dealDetailsQuery = useDealDetailsQuery(propertyId!);
  const investmentSummaryQuery = useInvestmentSummaryQuery(investmentId!);

  return (
    <>
      <PropertyInvestBreadcrumbsBar />
      <div className="flex flex-col flex-grow py-4 gap-8 pt-20 pb-20 lg:pb-4 sm:px-4 md:px-8">
        <Stepper
          steps={["Select Tokens", "Disclosures", "Documents", "Checkout"]}
          activeStep={4}
        />
        <div className="flex gap-8">
          <div className="p-8 bg-white rounded-md flex-grow flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <h1 className="text-xl font-semibold">Success!</h1>
            </div>
            <div className="py-2 flex flex-col gap-4">
              <div
                className={twMerge(
                  "flex rounded-md border border-black/10 p-6 gap-4 mt-4 text-sm border-green-300 bg-green-100"
                )}
              >
                <div className="pt-0.5">
                  <AiOutlineInfoCircle className="w-4 h-auto" />
                </div>

                <div className="flex-col flex gap-4">
                  <p className="font-medium">
                    Congratulations! Your transaction was successful.
                  </p>
                  {investmentSummaryQuery?.data &&
                  investmentSummaryQuery?.data.txHash.startsWith("0x") ? (
                    <div className="flex flex-col gap-4">
                      <div>You have received your tokens.</div>
                      <a
                        href={`https://mumbai.polygonscan.com/tx/${investmentSummaryQuery.data?.txHash}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className="flex items-baseline gap-2">
                          <div>Transaction hash:</div>
                          <span className="font-semibold font-mono">
                            {investmentSummaryQuery.data?.txHash.slice(0, 8)}...
                            {investmentSummaryQuery.data?.txHash.slice(56)}
                          </span>
                          <BiLinkExternal className="h-3 w-auto" />
                        </div>
                      </a>
                    </div>
                  ) : (
                    <p>
                      Your token will be deposited to your wallet within 24
                      hours. We will notify you to let you know when this
                      happens.
                    </p>
                  )}
                  <p>
                    Your order ID is:{" "}
                    <span className="font-mono font-semibold">
                      {investmentId}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="flex gap-6 text-sm pt-6">
              <Link to="/">
                <PrimaryButton className="bg-white border-foreground-accent border text-foreground-accent px-6">
                  Back to Marketplace
                </PrimaryButton>
              </Link>
              <Link to="/dashboard">
                <PrimaryButton className="px-6">
                  View all Transactions
                </PrimaryButton>
              </Link>
            </div>
          </div>
          <InvestmentDetailsCard className="hidden lg:flex" />
        </div>
      </div>
      <PropertyDetailsBottomBar moreDetails={<InvestmentDetailsCard />} />
    </>
  );
};

export default Completion;

import BreadcrumbsBar from "components/BreadcrumbsBar";
import React, { useEffect } from "react";

import type VeriffType from "@veriff/js-sdk";
// @ts-ignore
import { Veriff } from "@veriff/js-sdk";
import { createVeriffFrame } from "@veriff/incontext-sdk";
import useUserQuery from "hooks/queries/useUserQuery";

interface IKycProps {}

const Kyc: React.FunctionComponent<IKycProps> = (props) => {
  useEffect(() => {
    const veriff = Veriff({
      apiKey: "768e6a5e-aa99-4ce6-a082-db2588b7876a",
      parentId: "veriff-root",
      onSession: function (err: any, response: any) {
        console.log(err, response);
        createVeriffFrame({ url: response.verification.url });
        // received the response, verification can be started / triggered now
      },
    });

    veriff.setParams({
      person: {
        givenName: userQuery.data?.firstname,
        lastName: userQuery.data?.lastname,
      },
      vendorData: userQuery.data?.email,
    });

    veriff.mount();
  }, []);

  const userQuery = useUserQuery();

  return (
    <>
      <BreadcrumbsBar path={["Profile Settings", "KYC"]} className="z-10" />
      <div className="block mt-20 px-12 py-8 bg-white rounded-md w-full min-h-[400px]">
        <div className="font-semibold text-2xl w-full relative py-2 mb-6">
          KYC Verification
        </div>
        <p className="py-4 text-black/75">
          Your account is not KYC verified yet.
          <br />
          Please continue to get verified.
        </p>
        <div id="veriff-root" className="w-full mt-8"></div>
      </div>
    </>
  );
};

export default Kyc;

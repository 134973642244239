import { useQuery, UseQueryOptions } from "react-query"
import api from "api/axios"

type UserSelfAcc =
  | {
      _id: string
      documents: []
      selfAttested: boolean
      verified: boolean
      userId: string
      accountId: string
      acceptedTerm:string
    }
  | undefined

export default function useSelfAccQuery(
  options?: UseQueryOptions<UserSelfAcc>
) {
  return useQuery<UserSelfAcc>(
    "userSelfAcc",
    async () => {
      const selfAccData = await api.get(`/accreditions`)
      return selfAccData.data as UserSelfAcc
    },
    {
      staleTime: Infinity,
      retry: 3,
      retryOnMount: false,
      // ...options,
    }
  )
}

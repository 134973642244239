import React, { useState } from "react";
import Metrics from "./components/Metrics";
import DistributionChart from "./components/DistributionChart";
import { useDashboardOverviewQuery } from "../../../../hooks/queries/useDashboardOverviewQuery";
import _ from "lodash";
import moment from "moment";
import BreadcrumbsBar from "components/BreadcrumbsBar";
interface IMyOverviewProps {}

const PortfolioOverview: React.FunctionComponent<IMyOverviewProps> = () => {
  let chartData: { distribution: any; name: string }[] = [];
  const { data } = useDashboardOverviewQuery() || [];
  const filterDataByDealName = (dealName: string) => {
    const filteredData =
      data.length > 0
        ? _.filter(data, (e) => e.propertyName[0] === dealName)
        : [];
    return filteredData;
  };

  const dealNames = _.map(data, (d) => d.propertyName[0]);
  const [dealName, setDealName] = useState(dealNames[0]);
  const years = ["All", 2021, 2022];
  const [year, setYear] = useState(years[0]);

  const getChartData = () => {
    const filteredData = filterDataByDealName(dealName || dealNames[0]);
    if (chartData.length === 0) {
      chartData = [];
      //@ts-ignore
      filteredData[0]?.income.forEach((data: any) => {
        const desiredYear = year.toString() + "-01-01";
        if (
          year === "All" ||
          moment(data.time.fromDate).isSameOrAfter(desiredYear, "year")
        ) {
          chartData.push({
            distribution: data.distribution,
            name: moment(data.time.fromDate).format("MMMM YYYY"),
          });
        }
      });
    }
  };

  const noOfProperties = data?.length || 0;
  let totalInvested = 0,
    currentValue = 0,
    totalDistributions = 0;
  if (data) {
    data.forEach((d: any) => {
      totalInvested = totalInvested + d.investments;
      currentValue = currentValue + d.currentValue;
      totalDistributions = totalDistributions + d.distributions;
    });
    getChartData();
  }

  const metrics = [
    { label: "Properties", value: noOfProperties, type: "number" },
    { label: "Total Invested", value: totalInvested },
    { label: "Current Value", value: currentValue },
    { label: "Total Distributions", value: totalDistributions },
  ];
  return (
    <div className="lg:px-12 lg:py-12 px-3 py-3 mt-20 max-w-screen-2xl mx-auto">
      <BreadcrumbsBar path={["Dashboard", "Portfolio Overview"]} />
      <Metrics metrics={metrics} />
      <DistributionChart
        chartData={chartData}
        dealNames={dealNames}
        dealName={dealName}
        setDealName={setDealName}
        years={years}
        year={year}
        setYear={setYear}
      />
    </div>
  );
};

export default PortfolioOverview;

import PrimaryButton from "components/Buttons/PrimaryButton";
import useLoginMutation from "hooks/mutations/auth/useLoginMutation";
import useUserVerifyMutation from "hooks/mutations/auth/useUserVerifyMutation";
import useCurrentTenantQuery from "hooks/queries/useCurrentTenantQuery";
import { Header } from "pages/Auth/components";
import React, { useState } from "react";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSignupFormDataStore } from "..";

interface IUserVerifyStepProps {}

const UserVerifyStep: React.FunctionComponent<IUserVerifyStepProps> = (
  props
) => {
  const verifyMutation = useUserVerifyMutation();
  const { setStepOneData, data: formData } = useSignupFormDataStore();
  const loginMutation = useLoginMutation();

  const [otp, setOtp] = useState("");
  const navigate = useNavigate();

  const verifyUser = () => {
    if (formData.email === undefined || formData.password === undefined) return;

    verifyMutation
      .mutateAsync({ otp: parseInt(otp), email: formData.email })
      .then((data) => {
        toast.success("Email verified successfully, logging you in");
        loginMutation.mutate({
          email: formData.email!,
          password: formData.password!,
        });
      })
      .catch((mutationError) => {
        const error = mutationError.error as any;
        console.log("HALAISHL");
        toast.error(
          error?.response
            ? error?.response.data.message
              ? error?.response.data.message
              : error?.message
            : "Something went wrong, please try again"
        );
        verifyMutation.reset();
      });
  };

  return (
    <>
      <Header
        title="Almost There"
        subTitle="Enter the OTP sent to your registered email address in order to verify
        your email address."
      />

      <OtpInput
        value={otp}
        onChange={(value: string) => setOtp(value)}
        numInputs={6}
        isInputNum={true}
        inputStyle={{
          width: "2.5rem",
          height: "2.5rem",
          margin: "0.5rem 0.1rem",
          fontSize: "1rem",
          borderRadius: 6,
          border: "2px solid rgba(0,0,0,0.3)",
        }}
      />
      <div className="my-4">
        <PrimaryButton
          className="px-8"
          onClick={verifyUser}
          disabled={otp.length !== 6 || verifyMutation.isLoading}
        >
          Verify
        </PrimaryButton>
      </div>
    </>
  );
};

export default UserVerifyStep;

import PrimaryButton from "components/Buttons/PrimaryButton";
import Tooltip from "components/Tooltip";
import useSelfAccQuery from "hooks/queries/useSelfAccQuery";
import { AccreditationPoints } from "pages/Account/pages/SelfAcc";
import * as React from "react";
import { DivProps } from "react-html-props";
import { AiOutlineInfo, AiOutlineInfoCircle } from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";
import { twMerge } from "tailwind-merge";

interface IPropertyCtaCardProps {
  token: any;
  roi: any;
  className: string;
  status?: "upcoming" | "listed";
}

const PropertyCtaCard: React.FunctionComponent<IPropertyCtaCardProps> = ({
  token,
  roi,
  className,
  status,
}) => {
  const { soldTokens, supplyTokens } = token || {};
  const selfAccQuery = useSelfAccQuery();
  const accredited =
    selfAccQuery.data?.acceptedTerm !== AccreditationPoints.sectionC;

  const location = useLocation();

  console.log(location);

  return (
    <div
      className={twMerge(
        "p-6 shadow-lg border-t border-black/5 rounded-md z-10 sticky top-20 flex flex-col gap-8",
        className
      )}
    >
      <div className="grid grid-cols-2 divide-x-2 divide-black/5">
        <div className="flex flex-col text-center items-center gap-4">
          <h3 className="text-foreground-accent font-semibold text-6xl">
            {roi?.IRR}%
          </h3>
          <label
            htmlFor=""
            className="font-medium flex items-center gap-2"
            data-tip
            data-for="irr-cta-card"
          >
            <AiOutlineInfoCircle className="text-black" />
            Targetted IRR
          </label>
          <Tooltip id="irr-cta-card" className="max-w-sm">
            Internal Rate of Return (IRR) is the average annual return over the
            lifetime of an investment.
          </Tooltip>
        </div>
        <div className="flex flex-col text-center items-center gap-4">
          <h3 className="text-foreground-accent font-semibold text-6xl">
            {roi?.averageCashYield}%
          </h3>
          <label
            htmlFor=""
            className="font-medium flex items-center gap-2"
            data-tip
            data-for="acy-cta-card"
          >
            <AiOutlineInfoCircle className="text-black" />
            Avg Cash Yield
          </label>
          <Tooltip id="acy-cta-card" className="max-w-sm">
            Cash yield (or cash-on-cash yield) is a calculation that estimates
            the annual net payout throughout the duration of an investment,
            divided by the initial amount invested.
          </Tooltip>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <div className="h-2 rounded-full bg-background-highlight/30 relative">
          <div
            className="absolute inset-y-0 left-0 bg-green-700/75 rounded-full"
            style={{
              width: `${(soldTokens * 100) / supplyTokens}%`,
            }}
          ></div>
        </div>
        <p className="text-black/80 text-sm">
          Tokens Sold: <span className="font-bold">{soldTokens ?? 0}</span>/
          {supplyTokens} ({(soldTokens ?? 0 * 100) / supplyTokens}
          %)
        </p>
      </div>
      {!accredited && (
        <Tooltip id="buy-button">
          <div className="max-w-sm">
            This deal is currently available only to accredited investors.
            Please change your accreditation status first in order to access
            this deal.
          </div>
        </Tooltip>
      )}
      <Link
        to={
          status === "upcoming" ? "#" : accredited ? "./buy" : "/account/verify"
        }
        data-tip
        data-for="buy-button"
        state={{ redirectTo: `${location.pathname}` }}
      >
        <PrimaryButton
          disabled={status === "upcoming"}
          className={twMerge("text-xl py-3 w-full", !accredited && "text-base")}
        >
          {status === "upcoming" ? (
            "Deal Upcoming"
          ) : (
            <>{accredited ? "Invest Now" : "Change Accreditation Status"}</>
          )}
        </PrimaryButton>
      </Link>
    </div>
  );
};

export default PropertyCtaCard;

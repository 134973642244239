import * as React from "react";

interface IPropertyHeaderProps {
  title: string;
  address?: string;
}

const PropertyHeader: React.FunctionComponent<IPropertyHeaderProps> = ({
  title,
  address,
}) => {
  return (
    <div className="flex justify-between">
      <div className="flex-col flex gap-2">
        <h1 className="text-2xl font-semibold text-black/80 sm:text-4xl">
          {title}
        </h1>
        {address && <h3 className="text-black/50 text-lg">{address}</h3>}
      </div>
    </div>
  );
};

export default PropertyHeader;

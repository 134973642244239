import api from "api/axios"
import { useMutation, useQueryClient } from "react-query"

type VerifyResponse = {}

interface IVerify {
  otp: number
  email: string  
}

const useUserVerifyMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async ({ otp, email }: IVerify) => {
      const verifyResponse = await api.post("/users/register/verify", {
        otp,
        email,
      })
      return verifyResponse.data as VerifyResponse
    },    
  )
}

export default useUserVerifyMutation

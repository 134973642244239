import useDealDetailsQuery from "hooks/queries/useDealDetailsQuery";
import _ from "lodash";
import React, { useState } from "react";
import { DivProps } from "react-html-props";
import { GoKebabVertical } from "react-icons/go";
import { useParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";

interface IPropertyDetailsCardProps extends DivProps {}

const PropertyDetailsCard: React.FunctionComponent<
  IPropertyDetailsCardProps
> = (props) => {
  const { propertyId } = useParams();

  const dealDetailsQuery = useDealDetailsQuery(propertyId!);

  return (
    <div
      className={twMerge(
        "bg-white rounded-md flex flex-col p-4 max-w-sm gap-5",
        props.className
      )}
    >
      <img
        src={dealDetailsQuery.data?.marketing.coverImage}
        className="w-full h-auto rounded-md shadow"
        alt=""
      />
      <div className="px-3 flex flex-col gap-2">
        <div className="flex justify-between border-b pb-4 mb-2 border-black/10 border-dashed">
          <div className="flex flex-col gap-0.5">
            <p className="font-medium">{dealDetailsQuery.data?.name}</p>
            <p className="text-sm text-black/60">
              {_.startCase(dealDetailsQuery.data?.type)}
            </p>
            <div className="text-sm text-black/60">
              {dealDetailsQuery.data?.token.name}(
              {dealDetailsQuery.data?.token.symbol})
            </div>
          </div>
          <div>
            <GoKebabVertical className="mt-2 w-5 h-auto" />
          </div>
        </div>
        <div className="grid grid-cols-[1fr_auto] gap-y-2 pb-2 text-sm  whitespace-nowrap gap-x-6">
          <div className="font-medium">Targetted Investor IRR</div>
          <div className="font-semibold text-right">
            ${dealDetailsQuery.data?.roi.IRR}
          </div>
          <div className="font-medium">Target Average Cash Yield</div>
          <div className="font-semibold text-right">
            ${dealDetailsQuery.data?.roi.averageCashYield}
          </div>
          <div className="font-medium">Minimum Hold Period</div>
          <div className="font-semibold text-right">
            {dealDetailsQuery.data?.token.holdPeriodDays} days
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyDetailsCard;

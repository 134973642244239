import { Document as InvestDocument } from "hooks/queries/useDealInvestFlowQuery";
import { createContext, ReactNode, useContext, useState } from "react";

type Document = InvestDocument;
interface IDocumentSigningContext {
  openDocumentSigner: (document: Document) => void;
  closeDocumentSigner: () => void;
  selectedDocument?: Document;
  isOpen: boolean;
}

const DocumentSigningContext = createContext<IDocumentSigningContext | null>(
  null
);

const DocumentSigningProvider: React.FC<{ children: ReactNode }> = (props) => {
  const [selectedDocument, setSelectedDocument] = useState<
    Document | undefined
  >(undefined);

  const [isOpen, setIsOpen] = useState(false);

  const openDocumentSigner = (document: Document) => {
    console.log(document);
    if (!isOpen) {
      setSelectedDocument(document);
      setIsOpen(true);
    }
  };

  const closeDocumentSigner = () => {
    setIsOpen(false);
  };

  return (
    <DocumentSigningContext.Provider
      value={{
        openDocumentSigner,
        closeDocumentSigner,
        selectedDocument,
        isOpen,
      }}
      {...props}
    />
  );
};

const useDocumentSigning = () => {
  let result = useContext(DocumentSigningContext);
  if (!result) {
    throw new Error("Document Signer Hook used without Provider");
  }
  return result;
};
export { DocumentSigningProvider, useDocumentSigning };

import { yupResolver } from "@hookform/resolvers/yup";
import BreadcrumbsBar from "components/BreadcrumbsBar";
import PrimaryButton from "components/Buttons/PrimaryButton";
import FormTextInput from "components/Input/FormTextInput";
import { OTPSend } from "components/OTP/OTPSend";
import useUpdatePasswordMutation from "hooks/mutations/account/useUpdatePasswordMutation";
import useLogoutMutation from "hooks/mutations/auth/useLogoutMutation";
import useSendOtpMutation, {
  OTPTYPE,
} from "hooks/mutations/auth/useSendOtpMutation";
import { useEffect, useState } from "react";
import { useForm, UseFormRegister } from "react-hook-form";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { BiArrowBack } from "react-icons/bi";
import OtpInput from "react-otp-input";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import * as Yup from "yup";

interface PasswordValues {
  password: string;
  newPassword: string;
  confirmPassword: string;
}

const validationSchema = Yup.object().shape({
  password: Yup.string().required("Password is required"),
  newPassword: Yup.string()
    .max(255)
    .required("Password is required")
    .min(8, "Password is too short - should be 8 chars minimum.")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least one uppercase letter, one lowercase letter, one digit and one special character"
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    .required("Confirm Password is required"),
});
const UpdatePassword = () => {
  const updatePWMutation = useUpdatePasswordMutation();
  const logoutMutation = useLogoutMutation();
  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<PasswordValues>({
    defaultValues: {
      password: "",
      newPassword: "",
      confirmPassword: "",
    },
    resolver: yupResolver(validationSchema),
  });
  const [step, setStep] = useState<number>(1);
  const [otp, setOtp] = useState<string>("");

  const onSubmit = (data: PasswordValues) => {
    console.log(data);
    setStep(2);
  };

  const getLastSentOtpMethod = () => {
    return queryClient.getQueryData(OTPTYPE);
  };

  const submitOTP = () => {
    const otpType = getLastSentOtpMethod();
    if (!otpType) {
      toast.error("OTP expired Please Resend It.");
    } else {
      updatePWMutation.mutate({
        oldPassword: watch("password"),
        newPassword: watch("newPassword"),
        otp: parseInt(otp),
        otpMethod: otpType as "email" | "sms",
      });
    }
  };

  if (updatePWMutation.data) {
    const data = updatePWMutation.data as any;
    toast.success(
      "success",
      data && data?.message ? data?.message : "Password Updated SuccessFully"
    );
    //logout after showing toaster for 2 sec
    setTimeout(function () {
      logoutMutation.mutate();
    }, 2000);
    updatePWMutation.reset();
  } else if (updatePWMutation.error) {
    const error = updatePWMutation.error as any;
    toast.error(
      error?.response && error?.response.data.message
        ? error?.response.data.message
        : error?.message
    );
    updatePWMutation.reset();
  }

  return (
    <>
      <BreadcrumbsBar
        path={["Profile Settings", "Update Password"]}
        className="z-10"
      ></BreadcrumbsBar>
      <form
        className="flex flex-col mt-20 px-12 py-8 bg-white rounded-md w-full"
        onSubmit={handleSubmit(onSubmit)}
      >
        <span className="font-semibold text-2xl w-full relative py-2 mb-4">
          Manage Password
        </span>

        <div className="alert bg-info/20 border-info/50 border p-5 mb-5 max-w-md">
          <div className="flex items-start">
            <AiOutlineInfoCircle className="mt-2 text-foreground-primary/70 scale-150" />
            <div className="mx-2 flex flex-col gap-2 text-black/80">
              <div className="font-medium">
                Heads up before you change your password
              </div>
              <div className="text-sm">
                Changing password may require you to Sign-In again.
              </div>
            </div>
          </div>
        </div>
        {step === 1 && <FormStep1 register={register} errors={errors} />}
        {step === 2 && (
          <FormStep2
            otp={otp}
            setOtp={setOtp}
            submitOTP={submitOTP}
            disableSubmit={updatePWMutation.isLoading}
            onBack={() => {
              setStep(1);
            }}
          />
        )}
      </form>
    </>
  );
};

export default UpdatePassword;

interface IFORMStep1Props {
  register: UseFormRegister<PasswordValues>;
  errors: any;
}
const FormStep1: React.FunctionComponent<IFORMStep1Props> = ({
  register,
  errors,
}) => {
  return (
    <div className="my-2 mb-6 w-full flex flex-col max-w-md gap-6">
      <FormTextInput
        labelClassname="font-medium text-sm"
        type="password"
        label="Enter Current Password"
        register={() => register("password", { required: true })}
        error={errors.password && errors.password.message}
      />
      <FormTextInput
        label="Enter New Password"
        labelClassname="font-medium text-sm"
        type="password"
        register={() => register("newPassword", { required: true })}
        error={errors.newPassword && errors.newPassword.message}
      />
      <FormTextInput
        label="Confirm New Password"
        labelClassname="font-medium text-sm"
        type="password"
        register={() => register("confirmPassword", { required: true })}
        error={errors.confirmPassword && errors.confirmPassword.message}
      />
      <PrimaryButton
        type="submit"
        className="hover:bg-opacity-80 mt-2 py-3 text-sm lg:text-base"
      >
        Continue to Update Password
      </PrimaryButton>
    </div>
  );
};

interface IFORMStep2Props {
  otp: string;
  setOtp: React.Dispatch<React.SetStateAction<string>>;
  submitOTP: () => void;
  disableSubmit: boolean;
  onBack: () => void;
}
const FormStep2: React.FunctionComponent<IFORMStep2Props> = ({
  otp,
  setOtp,
  submitOTP,
  disableSubmit,
  onBack,
}) => {
  const OTPMutation = useSendOtpMutation();
  const [otpType, setOtpType] = useState<"email" | "sms">("email");

  useEffect(() => {
    const data = OTPMutation.data;
    if (data && data?.message)
      toast.success(
        data && data?.message ? data?.message : "OTP Sent SuccessFully"
      );
  }, [OTPMutation.data]);

  if (OTPMutation.error) {
    const error = OTPMutation.error as any;
    toast.error(
      error?.response && error?.response.data.message
        ? error?.response.data.message
        : error?.message
    );
    OTPMutation.reset();
  }

  return (
    <div className="my-2 p-4">
      <button
        onClick={onBack}
        className="hover:bg-opacity-80 rounded-full bg-black/10 mb-4"
      >
        <BiArrowBack className="" />
      </button>{" "}
      <br />
      <span className="text-2xl">Enter Verification Code</span>
      <div className="w-full sm:w-3/4 md:max-w-md my-2">
        <div className="text-orange-200">
          {OTPMutation.isLoading && "Sending OTP..."}
        </div>
        <div className="text-black/80">{OTPMutation.data?.message}</div>
      </div>
      <OtpInput
        value={otp}
        onChange={(otp: string) => {
          setOtp(otp);
        }}
        numInputs={6}
        isInputNum={true}
        inputStyle={{
          width: "2.5rem",
          height: "2.5rem",
          margin: "0.5rem 0.1rem",
          fontSize: "1rem",
          borderRadius: 3,
          color: "black",
          border: "1px solid rgba(0,0,0,0.3)",
        }}
        containerStyle="overflow-x-auto"
      />
      <PrimaryButton
        onClick={submitOTP}
        disabled={otp.length !== 6 || disableSubmit}
        className="bg-primary hover:bg-opacity-80 disabled:bg-opacity-70 mt-2 text-sm lg:text-base"
      >
        Validate
      </PrimaryButton>
      <div className="my-4">
        <OTPSend
          onSendOtp={() => {
            OTPMutation.mutate({ type: otpType });
          }}
          otpType={otpType}
          setOtpType={setOtpType}
        />
      </div>
    </div>
  );
};

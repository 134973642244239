import * as React from "react";
import LargeNavbar from "./LargeNavbar";
import MobileNavbar from "./MobileNavbar";

interface INavbarProps {}

const Navbar: React.FunctionComponent<INavbarProps> = (props) => {
  return (
    <>
      <LargeNavbar className="invisible xl:visible" />
      <MobileNavbar className="xl:invisible" />
    </>
  );
};

export default Navbar;

import * as React from "react";
import { ButtonProps } from "react-html-props";
import { twMerge } from "tailwind-merge";

interface IPrimaryButtonProps extends ButtonProps {}

const PrimaryButton: React.FunctionComponent<IPrimaryButtonProps> = (props) => {
  return (
    <button
      {...props}
      className={twMerge(
        "py-2 px-3 bg-foreground-accent rounded-md text-white font-medium relative overflow-hidden disabled:cursor-not-allowed",
        props.className
      )}
    >
      <div className="absolute inset-0 hover:bg-black/5 transition-colors"></div>
      {props.disabled && (
        <div className="absolute inset-0 transition-colors bg-white/50" />
      )}
      {props.children}
    </button>
  );
};

export default PrimaryButton;

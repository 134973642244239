import React from "react";
import CurrencyText from "components/CurrencyText";

const MetricsCard: React.FunctionComponent<any> = ({ metrics }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
      {metrics.map((metric: any) => (
        <div className="flex flex-col text-center items-center gap-4 bg-white px-5 py-6 rounded-md shadow-sm">
          <label htmlFor="" className="font-medium text-black-70">
            {metric.label}
          </label>
          <h3 className="text-foreground-accent font-semibold text-4xl">
            {metric.type === "number" ? (
              <span>{metric.value}</span>
            ) : (
              <CurrencyText
                value={metric.value}
                currency="USD"
                minimumFractionDigits={0}
                hideCurrency={true}
              />
            )}
          </h3>
        </div>
      ))}
    </div>
  );
};

export default MetricsCard;

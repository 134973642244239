import { VideoBg } from "../../components";
import { ForgotForm } from "./ForgotForm";

const ForgotPassword: React.FunctionComponent = () => {
  return (
    <div className="grid grid-cols-12 w-full">
      {/*this is col1*/}
      <ForgotForm />
      {/* this is col2 */}
      <VideoBg />
    </div>
  );
};

export default ForgotPassword;

import { OTPMethod } from "components/OTP";
import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import OtpInput from "react-otp-input";
import TextInput from "components/Input/TextInput";
import PasswordInput from "components/Input/PasswordInput";
import useForgetSendOTP from "hooks/mutations/auth/useForgetSendOTP";
import { useQueryClient } from "react-query";
import useForgetPWMutation from "hooks/mutations/auth/useForgetPWMutation";
import { toast } from "react-toastify";

interface IForgotPasswordDialog {
  onClose: () => void;
}

const ForgotPasswordDialog: React.FunctionComponent<IForgotPasswordDialog> = ({
  onClose,
}) => {
  return (
    <div
      className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none backdrop-blur-lg bg-white/40"
      onClick={onClose}
    >
      <div
        className="w-full sm:w-3/5 max-w-md my-6 mx-auto"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {/*content*/}
        <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          {/*header*/}
          <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
            <h3 className="text-xl font-semibold">Reset Password</h3>
            <button className="hover:bg-slate-100" onClick={onClose}>
              <AiOutlineClose className="scale-150" />
            </button>
          </div>
          {/*body*/}
          <div className="relative flex-auto">
            <ForgotPasswordDialogBody onClose={onClose} />
          </div>
        </div>
      </div>
    </div>
  );
};

interface IError {
  email: string;
  password: string;
  confirmPassword: string;
}
interface IValue {
  email: string;
  password: string;
  confirmPassword: string;
}
interface IForgotPasswordDialogBody {
  onClose: () => void;
}
const ForgotPasswordDialogBody: React.FunctionComponent<
  IForgotPasswordDialogBody
> = ({ onClose }) => {
  const OTPMutaion = useForgetSendOTP();
  const ForgetPWMutation = useForgetPWMutation();
  const queryClient = useQueryClient();
  const [value, setValue] = useState<IValue>({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [error, setError] = useState<IError>({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [step, setStep] = useState<number>(0);
  const [otpMethod, setOtpMethod] = useState<"email" | "sms">("email");
  const [otp, setOtp] = useState<string>("");

  const handleValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 0) {
      setError({ ...error, [e.target.name]: "" });
    }
    setValue({ ...value, [e.target.name]: e.target.value });
  };
  const handleNext = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (value.email.length <= 0) {
      setError({ ...error, email: "Email is required" });
      return;
    }
    //check email validation
    else if (
      !value.email.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
      )
    ) {
      setError({ ...error, email: "Invalid email" });
      return;
    }
    setStep(1);
  };

  const goBack = (e: React.MouseEvent<HTMLButtonElement>) => {
    setStep(0);
  };

  const handleSubmit = () => {
    if (value.password.length <= 0) {
      setError({ ...error, password: "Password is required" });
      return;
    } else if (value.password !== value.confirmPassword) {
      setError({ ...error, confirmPassword: "Password does not match" });
      return;
    } else if (queryClient.getQueryData("OTPTYPE")) {
      const OTPTYPE = queryClient.getQueryData("OTPTYPE") as "email" | "sms";
      const payload = {
        email: value.email,
        newPassword: value.password,
        otpMethod: OTPTYPE,
        emailOtp: OTPTYPE === "email" ? parseInt(otp) : 0,
        phoneOtp: OTPTYPE === "sms" ? parseInt(otp) : 0,
      };
      ForgetPWMutation.mutate(payload);
    } else {
      toast.error("OTP expired,Please Resend OTP");
    }
  };

  const handleOTPChange = (otp: string) => {
    setOtp(otp);
  };

  const hasError = () => {
    if (
      value.email.length <= 0 ||
      value.password.length <= 0 ||
      value.confirmPassword.length <= 0 ||
      otp.length != 6
    ) {
      return true;
    } else if (value.password !== value.confirmPassword) {
      return true;
    }

    return false;
  };

  if (OTPMutaion.data) {
    toast.success(
      OTPMutaion.data.message
        ? OTPMutaion.data.message
        : "OTP Sent Successfully"
    );
    OTPMutaion.reset();
  } else if (OTPMutaion.error) {
    const error = OTPMutaion.error as any;
    toast.error(
      error?.response && error?.response.data.message
        ? error?.response.data.message
        : error?.message
    );
    OTPMutaion.reset();
  } else if (ForgetPWMutation.data) {
    toast.success("Successfully changed the password");
    ForgetPWMutation.reset();
    onClose();
  } else if (ForgetPWMutation.error) {
    const error = ForgetPWMutation.error as any;
    toast.error(
      error?.response && error?.response.data.message
        ? error?.response.data.message
        : error?.message
    );
    ForgetPWMutation.reset();
  }

  return (
    <>
      <div className="flex flex-grow justify-center">
        <ul className="steps">
          <li className="step step-primary">Verify Email</li>
          <li className={step === 1 ? "step step-primary" : "step"}>
            Change Password
          </li>
        </ul>
      </div>
      <div className="p-6">
        {step === 0 && (
          <>
            <div className="m-4">
              <TextInput
                type="text"
                // error={error.email}
                onChange={handleValue}
                name="email"
                value={value.email}
                placeholder="Enter Your Email"
              />
              {error.email && (
                <div className="text-sm text-red-500">{error.email}</div>
              )}
            </div>
            <div className="flex justify-end m-4">
              <button
                className="text-white py-2 px-4 rounded bg-foreground-primary hover:bg-foreground-secondary"
                onClick={handleNext}
              >
                Next
              </button>
            </div>
          </>
        )}

        {step === 1 && (
          <>
            <div className="mx-4 my-1">
              <PasswordInput
                onChange={handleValue}
                name="password"
                value={value.password}
                placeholder="Enter Your Password"
              />
              {error.password && (
                <div className="text-sm text-red-500">{error.password}</div>
              )}
            </div>

            <div className="m-4">
              <PasswordInput
                onChange={handleValue}
                name="confirmPassword"
                value={value.confirmPassword}
                placeholder="Confirm Password"
              />
              {error.confirmPassword && (
                <div className="text-sm text-red-500">
                  {error.confirmPassword}
                </div>
              )}
            </div>

            <div className="m-4">
              <label className="ml-2">Enter OTP</label>
              <OtpInput
                value={otp}
                inputStyle={{
                  width: "2.5rem",
                  height: "2rem",
                  margin: "0.5rem 0.1rem",
                  fontSize: "1rem",
                  borderRadius: 6,
                  border: "2px solid rgba(0,0,0,0.3)",
                }}
                containerStyle="overflow-x-auto"
                numInputs={6}
                isInputNum={true}
                onChange={handleOTPChange}
              />
            </div>

            <div className="m-4 mb-8">
              <OTPMethod
                value={otpMethod}
                onChange={setOtpMethod}
                onSendOtp={() => {
                  OTPMutaion.mutate({
                    email: value.email,
                    otpMethod: otpMethod,
                  });
                }}
              />
            </div>

            <div className="m-4 flex justify-between">
              <button
                className="w-3/12 min-w-fit  text-white p-2  rounded bg-foreground-primary hover:bg-foreground-secondary"
                onClick={goBack}
              >
                Back
              </button>
              <button
                className="w-3/12 min-w-fit  text-white p-2  rounded bg-foreground-primary hover:bg-foreground-secondary disabled:bg-slate-400"
                disabled={hasError() || ForgetPWMutation.isLoading}
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ForgotPasswordDialog;

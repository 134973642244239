import { useQuery } from "react-query";
import api from "api/axios";
import { Document } from "./useDealInvestFlowQuery";

export type Investment = {
  _id: string;
  propertyId: string;
  updatedAt: string;
  propertyName: string;
  noOfTokens: number;
  payment: { total: number };
  status: "success" | "pending" | "pendingApproval";
  txHash: string;
};

type Overview = {
  _id: string;
  propertyName: string;
  currentValue: number;
  investment: number;
  updatedAt: string;
  status: string;
  stage: string;
  contractAddress: string;
  createdAt: string;
  investmentId: string;
  noOfTokens: number;
  roi: number;
};

type Investments = {
  data: [Investment];
  overview: [Overview];
  pages: number;
};

type DocVaultDetails = {
  contracts: Document[];
};

export function useInvestmentsQuery(page: number) {
  return useQuery(
    ["investmentDetails", page],
    async () => {
      const investmentDetails = await api.get(`/investment?page=${page}`);
      return investmentDetails.data as Investments;
    },
    {
      keepPreviousData: true,
    }
  );
}

export function useDocVaultQuery(
  docType: string,
  page: number,
  per_page: number
) {
  return useQuery("docVaultDetails", async () => {
    const docVaultDetails = await api.get(
      `/contract/type=${docType}?page=${page}&per_page=${per_page}`
    );
    return docVaultDetails.data as DocVaultDetails;
  });
}

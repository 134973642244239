import useCurrentTenantQuery from "hooks/queries/useCurrentTenantQuery";
import * as React from "react";

interface ITenantLogoHeaderProps {}

const TenantLogoHeader: React.FunctionComponent<ITenantLogoHeaderProps> = (
  props
) => {
  const currentTenantQuery = useCurrentTenantQuery();

  return <img src={currentTenantQuery.data?.logo.light} alt="logo" className="max-w-[180px] sm:max-w-[240px] h-auto"/>;
};

export default TenantLogoHeader;

import PrimaryButton from "components/Buttons/PrimaryButton";
import FormTextInput from "components/Input/FormTextInput";
import React from "react";
import {
  Control,
  Controller,
  useForm,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";

import PhoneInput, { CountryData } from "react-phone-input-2";
import { ISignupDataStep1, useSignupFormDataStore } from "..";

import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";
import { GoogleSignIn, Header } from "pages/Auth/components";

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required("Email is required"),
  combinedContactData: Yup.object()
    .shape({
      value: Yup.string().required("Mobile Number is Required"),
      country: Yup.object().required(),
    })
    .required("Mobile Number is Required"),
  password: Yup.string()
    .max(255)
    .required("Password is required")
    .min(8, "Password is too short - should be 8 chars minimum.")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain one uppercase letter, one lowercase letter, one digit and one special character"
    ),
  // confirmPassword: Yup.string()
  //   .oneOf([Yup.ref("password"), null], "Passwords must match")
  //   .required("Confirm Password is required"),
  acceptTerms: Yup.bool().oneOf([true], "Please accept Terms & Conditions"),
});

type IFormData = Omit<ISignupDataStep1, "contact" | "countryCode"> & {
  combinedContactData: {
    value: string;
    country: CountryData;
  };
};

interface ISignUpStep1 {}

export const SignUpStep1: React.FunctionComponent<ISignUpStep1> = () => {
  const { setStepOneData, data: formData } = useSignupFormDataStore();

  const { register, handleSubmit, control, formState } = useForm<IFormData>({
    defaultValues: {
      ...formData,
    },
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  const { errors } = formState;

  const navigate = useNavigate();

  const onSubmitHandler = (data: IFormData) => {
    let formattedData: ISignupDataStep1;

    formattedData = {
      ...data,
      countryCode: `+${data.combinedContactData.country.dialCode}`,
      contact: data.combinedContactData.value.slice(
        data.combinedContactData.country.dialCode.length
      ),
    };

    setStepOneData(formattedData);
    navigate("step-2");
  };

  return (
    <>
      <Header
        title="Sign Up"
        subTitle="Fill in the basic details to get started"
      />
      <form
        onSubmit={handleSubmit(onSubmitHandler)}
        className="flex flex-col gap-2"
      >
        <FormTextInput
          label="Email"
          register={() => register("email")}
          error={errors.email && errors.email.message}
          placeholder="Enter Your Email"
        />

        <label className="block text-black/80 font-semibold">
          Mobile Number
        </label>

        <Controller
          name="combinedContactData"
          control={control}
          render={({ field }) => (
            <PhoneInput
              country={"us"}
              inputProps={{
                name: "contact",
                required: true,
              }}
              inputStyle={{
                border: "none",
                width: "100%",
                backgroundColor: "#00000000",
              }}
              containerClass="w-full mx-auto rounded-lg border-black/10 border-2 px-3 py-1.5 focus:border-blue-600/20 focus:outline-none"
              buttonStyle={{
                padding: 0,
                border: "none",
                backgroundColor: "transparent",
              }}
              value={field.value?.value || ""}
              onChange={(value, country, e) =>
                field.onChange({ value, country })
              }
            />
          )}
        />
        {errors.combinedContactData && (
          <span className="text-red-400 font-medium text-sm">
            {errors.combinedContactData?.value?.message}
          </span>
        )}

        <FormTextInput
          label="Password"
          type="password"
          register={() => register("password")}
          error={errors.password && errors.password.message}
          placeholder="Enter Your Password"
        />

        {
          // Confirm Password is unnecessary where password visisibility can be toggled
          /* <FormTextInput
        label="Confirm Password"
        type="password"
        register={() => register("confirmPassword")}
        error={errors.confirmPassword && errors.confirmPassword.message}
        placeholder="Confirm Password"
      /> */
        }

        <div className="my-2">
          <div className="flex items-center gap-2">
            <input type="checkbox" {...register("acceptTerms")} />
            <label className="ml-2">
              I have reviewed and agree to the&nbsp;
              <a
                href="https://realtopublic.s3.amazonaws.com/2022/1/17/1645120940919/MartelInvest_Privacy_Policy.pdf"
                target="_blank"
                className="text-primary hover:underline"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
            </label>
          </div>
          {errors.acceptTerms && (
            <span className="text-red-400 font-medium text-sm">
              {errors.acceptTerms.message}
            </span>
          )}
        </div>
        <PrimaryButton disabled={!formState.isValid} type="submit">
          Continue
        </PrimaryButton>
      </form>
      <div className="w-full text-center mt-2">Or</div>

      <GoogleSignIn />

      <div className="flex justify-between my-2 text-sm">
        <span className="text-slate-800"> Already have an account? </span>
        <Link
          to="/signin"
          className="underline text-orange-600 hover:text-orange-400"
        >
          Sign In
        </Link>
      </div>
    </>
  );
};

import { Transition } from "@headlessui/react";
import React, { ReactNode } from "react";
import { DivProps } from "react-html-props";
import { twMerge } from "tailwind-merge";
import AnimateHeight from "react-animate-height";
import { BsCheck } from "react-icons/bs";

// interface IStepChildRenderProps {
//   stepNumber: number;
//   title: string;
//   status: StepStatus;
// }

// interface IStepProps
//   extends Omit<DivProps, "title" | "children">,
//     IStepChildRenderProps {
//   children: (renderProps: IStepChildRenderProps) => ReactNode;
// }

interface IStepProps extends DivProps {
  stepNumber: number;
  title: string;
  activeStepNumber: number;
}

export enum StepStatus {
  ACTIVE,
  PENDING,
  COMPLETED,
}

const Step: React.FunctionComponent<IStepProps> = ({
  stepNumber,
  title,
  activeStepNumber,
  ...props
}) => {
  const status = (() => {
    if (activeStepNumber === stepNumber) return StepStatus.ACTIVE;
    else if (activeStepNumber > stepNumber) return StepStatus.COMPLETED;
    else return StepStatus.PENDING;
  })();

  return (
    <div
      className={twMerge(
        "flex flex-col py-4",
        status === StepStatus.PENDING && "opacity-50"
      )}
    >
      <div className="flex gap-4">
        <div className="rounded-full h-7 w-7 relative bg-foreground-primary text-white font-medium">
          <div className="absolute inset-0 flex items-center justify-center">
            {status === StepStatus.COMPLETED ? <BsCheck /> : stepNumber}
          </div>
        </div>
        <div className="font-medium text-lg">{title}</div>
      </div>

      <AnimateHeight
        duration={300}
        height={status === StepStatus.ACTIVE ? "auto" : 0} // see props documentation below
      >
        <div className="pl-11 text-sm flex flex-col gap-2">
          {props.children}
        </div>
      </AnimateHeight>
    </div>
  );
};

export default Step;

import React, { useEffect, useRef } from "react";
import PropertyCard from "../components/PropertyCard";
import usePropertiesQuery from "hooks/queries/usePropertiesQuery";
import { useInView } from "react-intersection-observer";

import HeroBackground from "assets/images/hero-cover.jpg";

import { Oval } from "react-loader-spinner";
import LargePropertyCard from "../components/LargePropertyCard";

interface IPropertiesProps {}

const Properties: React.FunctionComponent<IPropertiesProps> = () => {
  const propertiesQuery = usePropertiesQuery();
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView && !propertiesQuery.isFetchingNextPage) {
      propertiesQuery.fetchNextPage();
    }
  }, [inView, propertiesQuery]);

  return (
    <>
      <div className="flex flex-col justify-center h-96 relative">
        <div className="absolute inset-0 bg-black/30 z-10  flex flex-col justify-center">
          <div className="text-center text-lg md:text-3xl font-bold text-white pb-4 drop-shadow-2xl shadow-black">
            Real Estate Investing - Simplified
          </div>
          <div className="text-center text-white text-base md:text-2xl font-semibold drop-shadow-2xl shadow-black">
            Start investing with as little as 50$
          </div>
        </div>
        <div
          className="absolute inset-0"
          style={{
            backgroundImage: `url(${HeroBackground})`,
            backgroundPosition: `top`,
            backgroundSize: `cover`,
          }}
        ></div>
        {/* <div
          className="absolute inset-0 bg-blend-color-burn opacity-20"
          style={{
            backgroundImage: `url(${TransparentTopography})`,
            backgroundSize: "cover",
          }}
        ></div> */}

        {/* <img
          src={CityBgSVG}
          style={{
            transform: "matrix(-1, 0, 0, 1, 0, 0)",
          }}
          className="mix-blend-difference absolute left-0 bottom-0"
        />
        <img
          src={CityBgSVG}
          className="mix-blend-difference absolute right-0 bottom-0 scale-x-[-1]"
          style={{
            transform: "matrix(1, 0, 0, 1, 0, 0)",
          }}
        /> */}
      </div>

      {propertiesQuery.data?.pages?.[0]?.properties?.length &&
      propertiesQuery.data?.pages?.[0]?.properties?.length < 3 ? (
        <div className="px-12 pb-12 flex-grow flex flex-col items-center max-w-full">
          <div className="grid gap-x-24 mt-12 gap-y-8 md:grid-cols-2 max-w-full">
            {propertiesQuery.data?.pages.map((page, index: number) =>
              page.properties.map((property) => (
                <LargePropertyCard
                  propertyName={property.name}
                  propertyId={property._id}
                  propertyLocation={property.owner.name}
                  targettedIrr={property.roi.IRR}
                  avgCashYield={property.roi.averageCashYield}
                  tokensSold={property.token.soldTokens}
                  tokensTotalSupply={property.token.totalTokens}
                />
              ))
            )}
          </div>
        </div>
      ) : (
        <div className="px-8 py-12 flex-grow flex flex-col items-center max-w-full">
          <div
            className="flex flex-wrap justify-center mx-auto gap-x-8 gap-y-8"
            // style={{
            //   gridTemplateColumns: "repeat(auto-fill, minmax(320px, 1fr))",
            // }}
          >
            {propertiesQuery.data?.pages.map((page) =>
              page.properties.map((property) => (
                <PropertyCard
                  imgUrl={property.marketing.coverImage}
                  propertyName={property.name}
                  propertyId={property._id}
                  propertyLocation={property.owner.name}
                  targettedIrr={property.roi.IRR}
                  avgCashYield={property.roi.averageCashYield}
                  tokensSold={property.token.soldTokens ?? 0}
                  tokensTotalSupply={property.token.totalTokens}
                  status={property.status}
                />
              ))
            )}
          </div>
        </div>
      )}

      {propertiesQuery.hasNextPage && (
        <div className="flex justify-center items-center pt-20">
          <div
            className="bg-foreground-secondary py-2 px-4 rounded-full text-white flex gap-2 items-center"
            ref={ref}
          >
            <Oval
              ariaLabel="loading-indicator"
              height={20}
              width={20}
              strokeWidth={10}
              strokeWidthSecondary={0}
              color="#ffffff"
              secondaryColor="#ffffff80"
            />
            <span className="text-sm text-white/90">Loading...</span>
          </div>
        </div>
      )}
    </>
  );
};

export default Properties;

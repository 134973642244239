import api from "api/axios";
import { useMutation, useQueryClient } from "react-query";

type InvestAddTransactionHashResponse = {};

interface IInvestFiat {
  total: number;
  currency: string;
  method: "direct_transfer";
  gateway: "wyre" | "stripe" | "ramp";
  transactionId: string;
  charges?: Charge[];
  investmentId: string;
}

type Charge = {
  type: string;
  amount: number;
};

const useInvestFiatMutation = (dealId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: IInvestFiat) => {
      const response = await api.put(`/invest/direct/${payload.investmentId}`, {
        total: payload.total,
        value: payload.total,
        currency: payload.currency,
        method: payload.method,
        gateway: payload.gateway,
        transactionId: payload.transactionId,
        charges: payload.charges,
      });
      return response.data as InvestAddTransactionHashResponse;
    },
    {
      onSuccess: () => {
        // queryClient.invalidateQueries(["dealInvestFlow", dealId]);
      },
    }
  );
};

export default useInvestFiatMutation;

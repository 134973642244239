import PrimaryButton from "components/Buttons/PrimaryButton";
import * as React from "react";
import { DivProps } from "react-html-props";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import propertyImg from "assets/images/tenant-logo.png";
import { BiInfoCircle } from "react-icons/bi";

interface ILargestPropCardProps extends DivProps {
  propertyName: string;
  propertyId: string;
  propertyLocation: string;
  tokensSold: number;
  tokensTotalSupply: number;
  targettedIrr: number;
  avgCashYield: number;
}

const LargestPropertyCard: React.FunctionComponent<ILargestPropCardProps> = ({
  propertyName,
  propertyId,
  propertyLocation,
  tokensSold,
  tokensTotalSupply,
  targettedIrr,
  avgCashYield,
  className,
}) => {
  return (
    <div className="grid row-auto md:grid-cols-2 w-full shadow-lg rounded-2xl overflow-auto">
      <img
        src={propertyImg}
        alt="property"
        className="w-full xl:h-[400px] lg:h-[380px] h-[340px] rounded-t-2xl lg:rounded-tr-none lg:rounded-l-2xl"
      />
      <div className="">
        <div
          className={twMerge(
            "p-5 sm:px-8 border-t xl:h-[400px] lg:h-[380px] h-[340px] border-black/5 z-10 sticky top-20 flex flex-col justify-between lg:rounded-r-2xl",
            className
          )}
        >
          <div>
            <h3 className="text-black/80 font-semibold md:text-2xl lg:text-3xl break-words">
              {propertyName}
            </h3>
            <p className="text-black/80 text-sm mt-1">{propertyLocation}</p>
          </div>
          <div className="grid grid-rows-2 divide-y-2 divide-black/5">
            <div className="grid grid-cols-6 items-center">
              <label htmlFor="" className="font-medium text-md col-span-2 ">
                Targetted IRR <BiInfoCircle className="inline" />
              </label>
              <h3 className="text-foreground-accent font-semibold text-2xl lg:text-3xl col-span-4">
                {targettedIrr}%
              </h3>
            </div>
            <div className="grid grid-cols-6 items-center">
              <label htmlFor="" className="font-medium text-md col-span-2">
                Avg Cash Yield <BiInfoCircle className="inline" />
              </label>
              <h3 className="text-foreground-accent font-semibold text-2xl lg:text-3xl col-span-4">
                {avgCashYield}%
              </h3>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="h-2 rounded-full bg-background-highlight/30 relative">
              <div
                className="absolute inset-y-0 left-0 bg-green-700/75 rounded-full"
                style={{
                  width: `${(tokensSold * 100) / tokensTotalSupply}%`,
                }}
              ></div>
            </div>
            <p className="text-black/80 text-lg flex justify-between">
              <span className="font-bold text-black/80">Tokens</span>
              <div className="">
                <span className="font-bold text-black/80">{tokensSold}</span>/
                {tokensTotalSupply}
              </div>
            </p>
            <p className="text-sm text-black ">
              Sold(
              {`${((tokensSold * 100) / tokensTotalSupply).toFixed(2)}`}
              %)
            </p>
          </div>

          <div className="grid grid-cols-2">
            <Link to="./buy">
              <PrimaryButton className="text-md sm:text-md whitespace-nowrap xl:py-3 lg:py-2 w-full self-end">
                Invest Now
              </PrimaryButton>
            </Link>
            <button className="font-bold text-black/80 hover:opacity-80">
              View Details
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LargestPropertyCard;

import { Menu as TwMenu, Transition } from "@headlessui/react";
import { ListItemSecondaryAction } from "@mui/material";
import React, { ReactNode, useState } from "react";
import { ButtonProps } from "react-html-props";
import { useLocation, useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";

interface IMenuProps {
  button: ReactNode;
  items: ReactNode[];
  menuClassName?: string;
}

const Menu: React.FunctionComponent<IMenuProps> = ({
  button,
  items,
  menuClassName,
}) => {
  return (
    <TwMenu as="div" className="relative inline-block text-left">
      <div>
        <TwMenu.Button className="bg-black rounded-md bg-opacity-0 hover:bg-opacity-10 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
          {button}
        </TwMenu.Button>
      </div>
      <Transition
        as={React.Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <TwMenu.Items
          className={twMerge(
            "flex flex-col absolute right-0 mt-2 w-44 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none whitespace-nowrap overflow-hidden",
            menuClassName
          )}
        >
          {items.map((item) => (
            <TwMenu.Item>
              {({ active }) => (
                <div
                  className={`${
                    active
                      ? "bg-foreground-secondary text-white"
                      : "text-gray-900"
                  }`}
                >
                  {item}
                </div>
              )}
            </TwMenu.Item>
          ))}
        </TwMenu.Items>
      </Transition>
    </TwMenu>
  );
};

interface IMenuLinkProps extends ButtonProps {
  disableActive?: boolean;
  to?: string;
  children: React.ReactNode;
}

export const MenuLink: React.FunctionComponent<IMenuLinkProps> = ({
  disableActive,
  to,
  children,
  onClick,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <button
      onClick={(e) => {
        to && navigate(to);
        onClick?.(e);
      }}
      className={twMerge(
        "group flex gap-4 w-full items-center text-left rounded-md px-4 py-3 text-sm",
        isActive && !disableActive && "font-semibold"
      )}
    >
      {children}
    </button>
  );
};

export default Menu;

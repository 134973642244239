import NewsCards from "./NewsCards";

const NewsSection: React.FunctionComponent = () => {
  return (
    <div className="py-4 px-5 md:py-5 md:px-10 lg:py-20 lg:px-40 bg-background-primary grid row-auto gap-y-6">
      <h1 className="text-2xl md:text-3xl font-bold">News</h1>
      <h6 className="text-sm text-justify">
        Here is the top headlines to make sure that you are on the right & best
        investing platform.
      </h6>
      <NewsCards />
    </div>
  );
};

export default NewsSection;

import Menu, { MenuLink } from "components/Menu";
import useLogoutMutation from "hooks/mutations/auth/useLogoutMutation";
import useUserQuery from "hooks/queries/useUserQuery";
import * as React from "react";
import {
  AiOutlineFileDone,
  AiOutlineKey,
  AiOutlineLogout,
  AiOutlineSetting,
} from "react-icons/ai";
import { IoKeyOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

interface IProfileButtonProps {}

const ProfileButton: React.FunctionComponent<IProfileButtonProps> = (props) => {
  const userQuery = useUserQuery();
  const logoutMutation = useLogoutMutation();
  const navigate = useNavigate();

  return (
    <Menu
      menuClassName="mt-2.5 w-54"
      button={
        <button className="p-4 relative rounded-full bg-white/25 text-white font-bold text-sm border border-white/60 overflow-hidden">
          {userQuery.data?.profileUrl ? (
            <img
              src={userQuery.data.profileUrl}
              className="absolute inset-0"
              alt="user profile"
            />
          ) : (
            <div className="absolute inset-0 grid place-items-center overflow-hidden">
              {userQuery.data?.firstname?.[0]}
            </div>
          )}
        </button>
      }
      items={[
        <MenuLink to="/account">
          <AiOutlineSetting className="w-4 h-auto" />
          <div>Profile Settings</div>
        </MenuLink>,
        <MenuLink to="/account/kyc">
          <AiOutlineFileDone className="w-4 h-auto" />
          <div>KYC Verification</div>
        </MenuLink>,
        <MenuLink to="/account/verify">
          <AiOutlineFileDone className="w-4 h-auto" />
          <div>Self Accreditation</div>
        </MenuLink>,
        <MenuLink to="/account/password">
          <IoKeyOutline className="w-4 h-auto" />
          <div>Manage Password</div>
        </MenuLink>,
        <MenuLink onClick={() => logoutMutation.mutate()}>
          <AiOutlineLogout className="w-4 h-auto" />
          Logout
        </MenuLink>,
      ]}
    />
  );
};

export default ProfileButton;

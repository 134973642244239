import PrimaryButton from "components/Buttons/PrimaryButton";
import FormTextInput from "components/Input/FormTextInput";
import useLoginMutation from "hooks/mutations/auth/useLoginMutation";
import React, { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import ForgotPasswordDialog from "./components/ForgotPasswordDialog";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { GoogleSignIn, Header } from "pages/Auth/components";

type Inputs = {
  email: string;
  password: string;
};

interface ISignInForm {}

export const SignIn: React.FunctionComponent<ISignInForm> = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Inputs>();

  const loginMutation = useLoginMutation();
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    console.log(data, errors);
    loginMutation.mutate(data);
  };

  if (loginMutation.error) {
    const error = loginMutation.error as any;
    toast.error(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
    loginMutation.reset();
  }

  return (
    <>
      <Header
        title="Login"
        subTitle="Welcome Back! Please enter your details."
      />
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-2">
        {/* {loginMutation?.error instanceof Error && <p>{loginMutation?.error?.response?.data?.message!}</p>} */}
        <FormTextInput
          label="Email"
          register={() => register("email", { required: true })}
          error={errors.email && "Email Required"}
        />
        <FormTextInput
          label="Password"
          type="password"
          register={() => register("password", { required: true })}
          error={errors.password && "Password Required"}
        />

        <div className="flex justify-between my-4 flex-wrap text-sm sm:text-base">
          <div className="flex items-center">
            <input type="checkbox" />
            <label className="mx-1">Remember Me</label>
          </div>
          <div
            className="underline hover:text-slate-700"
            onClick={() => {
              setShowDialog(true);
            }}
          >
            Forgot Password?
          </div>
        </div>

        <PrimaryButton
          type="submit"
          disabled={loginMutation.isLoading}
          className="disabled:bg-opacity-70"
        >
          {loginMutation.isLoading ? "Signing In" : "Sign In"}
        </PrimaryButton>
      </form>
      {showDialog && (
        <ForgotPasswordDialog onClose={() => setShowDialog(false)} />
      )}
      <div className="w-full text-center mt-2">Or</div>

      <GoogleSignIn />

      <div className="flex justify-between my-2 text-sm">
        <span className="text-slate-800">Don't have an account yet?</span>
        <span>
          <Link
            to="/signup"
            className="underline text-orange-600 hover:text-orange-400"
          >
            Signup for free
          </Link>
        </span>
      </div>
    </>
  );
};

export default SignIn;

import FormTextInput from "components/Input/FormTextInput";
import { useEffect, useState } from "react";
import { CountryDropdown } from "react-country-region-selector";
import {
  Control,
  Controller,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";
import { twMerge } from "tailwind-merge";
import { ProfileForm } from "..";

interface IShippingAddressForm {
  register: UseFormRegister<ProfileForm>;
  errors: any;
  control: Control<ProfileForm>;
  setValue: UseFormSetValue<ProfileForm>;
  isEditable: boolean;
  getValues: UseFormGetValues<ProfileForm>;
}

export const ShippingAddressForm: React.FunctionComponent<
  IShippingAddressForm
> = ({ register, errors, setValue, control, isEditable, getValues }) => {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (isChecked) {
      const address = getValues("address");
      setValue("additionalAddress.0", address);
    }
  });

  return (
    <>
      <div className="w-full mt-8 mb-4">
        <div className="font-semibold text-lg text-black/80">
          Shipping Address
        </div>

        <div className="py-2 flex items-center">
          <input
            type="checkbox"
            checked={isChecked}
            onChange={(e) => setIsChecked(e.target.checked)}
          />
          <span className="mx-2 text-sm">Same As Billing Address</span>
        </div>
      </div>

      <div
        className={twMerge(
          "grid grid-cols-1 sm:grid-cols-2 gap-x-6 gap-y-5",
          isChecked && "opacity-50"
        )}
      >
        <FormTextInput
          disabled={isChecked || !isEditable}
          labelClassname="font-medium text-sm"
          label="Address Line 1"
          register={() => register("additionalAddress.0.addressLine")}
          error={
            errors.additionalAddress?.addressLine1 &&
            errors.additionalAddress.addressLine1.message
          }
          placeholder="Address Line 1"
          className="text-sm"
        />
        <FormTextInput
          disabled={isChecked || !isEditable}
          labelClassname="font-medium text-sm"
          label="Address Line 2"
          register={() => register("additionalAddress.0.addressLine")}
          error={
            errors.additionalAddress?.addressLine2 &&
            errors.additionalAddress.addressLine2.message
          }
          placeholder="Address Line 2"
          className="text-sm"
        />
        <FormTextInput
          labelClassname="font-medium text-sm"
          label="City"
          register={() => register("additionalAddress.0.city")}
          error={
            errors.additionalAddress?.city &&
            errors.additionalAddress?.city.message
          }
          placeholder="Enter Your City"
          className="text-sm"
          disabled={isChecked || !isEditable}
        />
        <FormTextInput
          labelClassname="font-medium text-sm"
          label="State"
          register={() => register("additionalAddress.0.state")}
          error={
            errors.additionalAddress?.state &&
            errors.additionalAddress?.state.message
          }
          placeholder="Enter Your State"
          className="text-sm"
          disabled={isChecked || !isEditable}
        />
        <div>
          <label className="block font-medium text-sm text-black/80 pb-1">
            Country
          </label>
          <Controller
            render={({ field }) => (
              <CountryDropdown
                disabled={isChecked || !isEditable}
                value={field.value}
                priorityOptions={["US"]}
                onChange={(val, e) => {
                  setValue("additionalAddress.0.country", val, {
                    shouldValidate: true,
                  });
                  setValue("additionalAddress.0.state", "");
                }}
                name="additionalAddress.0.country"
                classes="rounded-lg block w-full text-sm border-black/10 border-2 p-3 focus:border-blue-600/20 focus:outline-none bg-transparent"
              />
            )}
            name={"additionalAddress.0.country"}
            control={control}
          />
          {errors.additionalAddress?.country && (
            <span className="text-red-400 font-medium text-sm">
              {errors.additionalAddress.country.message}
            </span>
          )}
        </div>

        <FormTextInput
          disabled={isChecked || !isEditable}
          labelClassname="font-medium text-sm"
          label="Zip Code"
          register={() => register("additionalAddress.0.postCode")}
          error={
            errors.additionalAddress?.postCode &&
            errors.additionalAddress.postCode.message
          }
          placeholder="Enter Zip Code"
          className="text-sm"
        />
      </div>
    </>
  );
};

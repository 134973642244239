import {
  useQuery,
} from "react-query";
import api from "api/axios";

export function useDashboardOverviewQuery() {
  return useQuery(
    "summaryDetails",
    async () => {
      const summaryDetails = await api.get(`/investment/summary`);
      return summaryDetails.data as any;
    }
  );
}

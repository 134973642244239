import PrimaryButton from "components/Buttons/PrimaryButton";
import * as React from "react";
import { DivProps } from "react-html-props";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import propertyImg from "assets/images/tenant-logo.png";
import { BiInfoCircle } from "react-icons/bi";

interface ILargePropCardProps extends DivProps {
  propertyName: string;
  propertyId: string;
  propertyLocation: string;
  tokensSold: number;
  tokensTotalSupply: number;
  targettedIrr: number;
  avgCashYield: number;
}

const LargePropertyCard: React.FunctionComponent<ILargePropCardProps> = ({
  propertyName,
  propertyId,
  propertyLocation,
  tokensSold,
  tokensTotalSupply,
  targettedIrr,
  avgCashYield,
  className,
}) => {
  return (
    <div className=" lg:flex items-center rounded">
      <div>
        <img
          src={propertyImg}
          alt="property"
          className="lg:w-[280px] xl:h-[400px] lg:h-[380px]  h-[300px] w-[280px]"
        />
      </div>
      <div>
        <div className="lg:max-w-[400px] max-w-[280px] relative xl:block">
          <div
            className={twMerge(
              "p-4 shadow-lg border-t xl:h-[400px] lg:h-[380px] h-[340px] border-black/5 rounded-md z-10 sticky top-20 flex flex-col xl:gap-8 lg:gap-6 gap-2",
              className
            )}
          >
            <div className="grid grid-cols-2 divide-x-2 divide-black/5">
              <div className="flex flex-col text-center items-center gap-3">
                <h3 className="text-foreground-accent font-semibold xl:text-6xl text-3xl">
                  {targettedIrr}%
                </h3>
                <label htmlFor="" className="font-medium ">
                  Targetted IRR <BiInfoCircle className="inline" />
                </label>
              </div>
              <div className="flex flex-col text-center items-center gap-3 pl-2">
                <h3 className="text-foreground-accent font-semibold xl:text-6xl text-3xl">
                  {avgCashYield}%
                </h3>
                <label htmlFor="" className="font-medium">
                  Avg Cash Yield <BiInfoCircle className="inline" />
                </label>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="h-2 rounded-full bg-background-highlight/30 relative">
                <div
                  className="absolute inset-y-0 left-0 bg-green-700/75 rounded-full"
                  style={{
                    width: `${(tokensSold * 100) / tokensTotalSupply}%`,
                  }}
                ></div>
              </div>
              <p className="text-black/80 text-lg flex justify-between">
                <span className="font-bold">Tokens</span>
                <div className="">
                  <span className="font-bold">{tokensSold}</span>/
                  {tokensTotalSupply}
                </div>
              </p>
              <p className="text-sm text-black font-medium">
                Sold(
                {`${((tokensSold * 100) / tokensTotalSupply).toFixed(2)}`}
                %)
              </p>
            </div>
            <div>
              <h3 className="text-black font-semibold text-lg">
                {propertyName}
              </h3>
              <p className="text-black/80 text-sm">{propertyLocation}</p>
            </div>
            <Link to="./buy">
              <PrimaryButton className="text-xl xl:py-3 lg:py-2 w-full self-end">
                Invest Now
              </PrimaryButton>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LargePropertyCard;

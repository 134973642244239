import { useQuery } from "react-query";
import { ethers } from "ethers";
import { Chain } from "@web3-onboard/common";
import erc20abi from "assets/abi/erc20.json";
import { useTokenDecimalsQuery } from "./useTokenDecimalsQuery";

export function useTokenBalanceQuery(
  tokenAddress?: string,
  accountAddress?: string,
  chain?: Chain
) {
  const tokenDecimalsQuery = useTokenDecimalsQuery(tokenAddress, chain);

  return useQuery(
    [chain?.id, tokenAddress, "balanceOf", accountAddress],
    async () => {
      if (
        !tokenDecimalsQuery.data ||
        !tokenAddress ||
        !accountAddress ||
        !chain?.id
      )
        return undefined;

      const readOnlyProvider = new ethers.providers.JsonRpcProvider(
        chain?.rpcUrl
      );

      const currencyContract = new ethers.Contract(
        tokenAddress,
        erc20abi,
        readOnlyProvider
      );

      const decimals = tokenDecimalsQuery.data;
      const rawBalance = (await currencyContract.balanceOf(
        accountAddress
      )) as ethers.BigNumber;

      return {
        raw: rawBalance,
        formatted: parseFloat(ethers.utils.formatUnits(rawBalance, decimals)),
      };
    },
    {
      enabled:
        !!tokenDecimalsQuery.data &&
        !!chain?.id &&
        !!tokenAddress &&
        !!accountAddress,
    }
  );
}

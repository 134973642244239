import { ReactNode } from "react";
import { AiOutlineFileDone } from "react-icons/ai";
import { BiUserCircle } from "react-icons/bi";
import { IoKeyOutline } from "react-icons/io5";
import { MdOutlineVerifiedUser } from "react-icons/md";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";

export const SideNav = () => {
  return (
    <ul className="p-4 text-sm flex flex-wrap top-20 sm:sticky gap-2 sm:block text-white sm:text-black/80 bg-white rounded-md min-w-[300px]">
      <div className="relative">
        <NavlinkItem
          url="./profile"
          urlText="My Profile"
          icon={<BiUserCircle className="w-5 h-auto" />}
        />
        <ul className="absolute p-2 hidden bg-white shadow sm:shadow-none sm:bg-inherit sm:static sm:flex flex-col gap-3 ml-12 text-black/80 pb-4 text-sm">
          <SubLinkNavItem to="account" parent="/account/profile">
            Account Info
          </SubLinkNavItem>
          <SubLinkNavItem to="contact" parent="/account/profile">
            Contact Info
          </SubLinkNavItem>
          <SubLinkNavItem to="social" parent="/account/profile">
            Social Account
          </SubLinkNavItem>
          {/* <a href="#contact">Contact Info</a>
          <a href="#social">Social Accounts</a> */}
        </ul>
      </div>
      <NavlinkItem
        url="./kyc"
        urlText="KYC Verification" //"KYC Verification"
        icon={<MdOutlineVerifiedUser className="w-5 h-auto" />}
      />
      <NavlinkItem
        url="./verify"
        urlText="Self Accreditation" //"KYC Verification"
        icon={<AiOutlineFileDone className="w-5 h-auto" />}
      />
      <NavlinkItem
        url="./password"
        urlText="Manage Password"
        icon={<IoKeyOutline className="w-5 h-auto" />}
      />
    </ul>
  );
};

interface ISubLinkNavItemProps {
  children: ReactNode;
  parent: string;
  to: string;
}

const SubLinkNavItem: React.FunctionComponent<ISubLinkNavItemProps> = ({
  children,
  parent,
  to,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const card = location.hash;
  // if (location.pathname === parent) return <a href={`#${to}`}>{children}</a>;

  return (
    <button
      className={`inline w-max hover:opacity-80 ${
        card === "#" + to ? " font-medium text-foreground-accent" : ""
      }`}
      onClick={() => navigate(`${parent}#${to}`)}
    >
      {children}
    </button>
  );
};

export default SubLinkNavItem;

interface INavLinkItem {
  url: string;
  urlText: string;
  removeActiveStyle?: boolean;
  icon?: any;
}
const NavlinkItem: React.FunctionComponent<INavLinkItem> = ({
  url,
  urlText,
  removeActiveStyle = false,
  icon,
}) => {
  return (
    <li>
      {removeActiveStyle ? (
        <NavLink
          className="flex items-center gap-4 hover:opacity-70 whitespace-nowrap sm:whitespace-normal px-5 rounded-md py-2"
          to={url}
        >
          {icon && <span>{icon}</span>}
          <span>{urlText}</span>
        </NavLink>
      ) : (
        <NavLink
          className={({ isActive }) =>
            twMerge(
              "flex items-center gap-4 hover:opacity-70 whitespace-nowrap sm:whitespace-normal px-5 rounded-md py-2",
              isActive &&
                "bg-foreground-accent/10 font-medium text-foreground-accent"
            )
          }
          to={url}
        >
          {icon && <span>{icon}</span>}
          <span>{urlText}</span>
        </NavLink>
      )}
    </li>
  );
};

import useDealDetailsQuery from "hooks/queries/useDealDetailsQuery";
import * as React from "react";
import { BsArrowLeftShort, BsChevronRight } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";

interface IPropertyInvestBreadcrumbsBarProps {}

const PropertyInvestBreadcrumbsBar: React.FunctionComponent<
  IPropertyInvestBreadcrumbsBarProps
> = (props) => {
  const { propertyId } = useParams();
  const navigate = useNavigate();
  const dealDetailsQuery = useDealDetailsQuery(propertyId!);

  return (
    <div className="fixed z-10 inset-x-0 h-14 mt-14 top-0 bg-white shadow-sm flex justify-between px-4 items-center text-black/80 text-sm whitespace-nowrap overflow-x-auto">
      <div className="flex items-center gap-6">
        <button className="flex gap-1 items-center" onClick={() => navigate(-1)}>
          <BsArrowLeftShort className="w-5 h-auto" /> Back
        </button>
        <div className="pl-6 border-l border-black/10 flex gap-2 items-center">
          <div>Buy Tokens</div>
          <BsChevronRight className="text-black/50" />
          <div className="text-foreground-primary">
            {dealDetailsQuery.data?.name}
          </div>
        </div>
      </div>
      <div className="divide-x divide-black/10 items-baseline hidden lg:flex">
        <div className="flex gap-3 px-4 items-baseline">
          <div className="font-medium">Targetted IRR</div>
          <div className="text-foreground-accent font-semibold text-base">
            {dealDetailsQuery.data?.roi.IRR}%
          </div>
        </div>
        <div className="flex gap-2 pl-4 items-baseline">
          <div className="font-medium">Average Cash Yield</div>
          <div className="text-foreground-accent font-semibold text-base">
            {dealDetailsQuery.data?.roi.averageCashYield}%
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyInvestBreadcrumbsBar;

import { useQuery } from "react-query";
import api from "api/axios";

export type IInvestmentSummary = {
  _id: string;
  investments: number;
  noOfTokens: number;
  investmentDate: string;
  currentValue: number;
  propertyName: [string];
  seller: [{ type: string; name: string; id: string }];
  contractAddress: string;
  txHash: string;
};

export default function useInvestmentSummaryQuery(investmentId: string) {
  return useQuery(["investmentSummary", investmentId], async () => {
    const investmentOverview = await api.get(
      `/investment/summary/${investmentId}`
    );
    return investmentOverview.data[0] as IInvestmentSummary;
  });
}

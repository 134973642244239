import React, { useState } from "react";
import LB from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import "./yarl-custom.css";

interface ILightboxProps {
  images: string[];
  isOpen: boolean;
  onClose: () => void;
}

const Lightbox: React.FunctionComponent<ILightboxProps> = ({
  images,
  isOpen,
  onClose,
}) => {
  const imgs = images.map((img) => ({ src: img }));

  return (
    <LB
      open={isOpen}
      close={() => onClose()}
      slides={imgs}
      plugins={[Thumbnails]}
    />
  );
};

export default Lightbox;

import usePropertiesQuery from "hooks/queries/usePropertiesQuery";
import LargePropertyCard from "pages/Marketplace/components/LargePropertyCard";
import PropertyCard from "pages/Marketplace/components/PropertyCard";

const HotDealsSection: React.FunctionComponent = () => {
  const propertiesQuery = usePropertiesQuery();
  return (
    <div className="py-4 px-5 md:py-5 md:px-10 lg:py-20 lg:px-40 bg-white">
      <h1 className="text-2xl md:text-3xl font-bold text-black/80">
        Hot Deals
      </h1>
      <h6 className="text-sm my-5">
        Here are the best deals out there, have a look and start your investment
        journey today with us!
      </h6>
      <div className="">
        {propertiesQuery.data?.pages?.[0]?.properties?.length &&
        propertiesQuery.data?.pages?.[0]?.properties?.length < 3 ? (
          <div className="flex-grow flex flex-col items-center max-w-full">
            <div className="grid gap-x-24 gap-y-8 md:grid-cols-2 max-w-full">
              {propertiesQuery.data?.pages.map((page, index: number) =>
                page.properties.map((property) => (
                  <LargePropertyCard
                    propertyName={property.name}
                    propertyId={property._id}
                    propertyLocation={property.owner.name}
                    targettedIrr={property.roi.IRR}
                    avgCashYield={property.roi.averageCashYield}
                    tokensSold={property.token.soldTokens}
                    tokensTotalSupply={property.token.totalTokens}
                  />
                ))
              )}
            </div>
          </div>
        ) : (
          <div className="py-4 flex-grow flex flex-col items-center max-w-full">
            <div
              className="flex flex-wrap justify-start mx-auto gap-x-8 gap-y-8"
              // style={{
              //   gridTemplateColumns: "repeat(auto-fill, minmax(320px, 1fr))",
              // }}
            >
              {propertiesQuery.data?.pages.map((page) =>
                page.properties
                  .slice(0, 3)
                  .map((property) => (
                    <PropertyCard
                      status={property.status}
                      imgUrl={property.marketing.coverImage}
                      propertyName={property.name}
                      propertyId={property._id}
                      propertyLocation={property.owner.name}
                      targettedIrr={property.roi.IRR}
                      avgCashYield={property.roi.averageCashYield}
                      tokensSold={property.token.soldTokens ?? 0}
                      tokensTotalSupply={property.token.totalTokens}
                    />
                  ))
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HotDealsSection;

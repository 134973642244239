import api from "api/axios";
import { useMutation, useQueryClient } from "react-query";

type OTPResponse = {
  hash: string;
  message:string;
};

interface IOtp {
 type:"email"|"sms" 
}
export const OTPTYPE="OTP_TYPE"

const useSendOtpMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({type}: IOtp) => {                
      const otpResponse = await api.get("/send/otp/"+type); 
      queryClient.setQueryData(OTPTYPE,type);     
      return otpResponse.data as OTPResponse;
    }
  );
};

export default useSendOtpMutation;

import api from "api/axios";
import { useMutation, useQueryClient } from "react-query";

type LoginResponse = {
  accessToken: string;
};

interface ILogin {
  email: string;
  password: string;
}

const useLoginMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ email, password }: ILogin) => {
      const loginResponse = await api.post("/users/login", { email, password });
      const { accessToken } = loginResponse.data;

      if (!accessToken) {
        throw new Error("Login failed");
      }
      return loginResponse.data as LoginResponse;
    },
    {
      onSuccess: (loginResponse) => {
        localStorage.setItem("access_token", loginResponse.accessToken);
        // localStorage.setItem("refresh_token", refresh_token);
        return queryClient.invalidateQueries("user");
      },
    }
  );
};

export default useLoginMutation;
